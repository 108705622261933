import * as React from 'react';
import { useMemo } from 'react';

import { DropdownOption, formatNumber, Icon, Tooltip } from '@column/column-ui-kit';

import { useBankAccounts } from '~/hooks/useBankAccounts';
import { useSessionStore } from '~/stores/Session';
import { CustomRolePlatformLevelPermissions } from '~/typings/API';

export const useBankAccountsDropdown = ({
  permission,
  unauthorizedText,
}: {
  permission?: keyof CustomRolePlatformLevelPermissions;
  unauthorizedText: string;
}) => {
  const { isAuthorized } = useSessionStore();

  const { response: bankAccounts, setQueryParams: searchBankAccounts, isLoading } = useBankAccounts();

  const dropdownBankAccounts: DropdownOption[] = useMemo(
    () =>
      (bankAccounts?.bankAccounts ?? []).map((bankAccount) => {
        const isBankAccountAuthorized = !permission
          ? true
          : isAuthorized({
              permission,
              permissionLevel: 'write',
              bankAccount,
            });

        return {
          label: `${
            bankAccount?.displayName && bankAccount?.description
              ? `${bankAccount.displayName} - ${bankAccount.description}`
              : bankAccount?.description || 'Unnamed'
          } (${formatNumber(bankAccount?.balances?.availableAmount)})`,
          small: bankAccount.id,
          value: bankAccount.id,
          suffix: !isBankAccountAuthorized && (
            <Tooltip content={unauthorizedText} placement="right" zIndex={15}>
              <Icon.AnimationLock />
            </Tooltip>
          ),
          isDisabled: !isBankAccountAuthorized,
        };
      }),
    [bankAccounts, isAuthorized, unauthorizedText]
  );

  return { dropdownBankAccounts, isLoading, searchBankAccounts };
};
