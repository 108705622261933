import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

type TableColumnWidth = {
  min?: number;
  fixed?: number;
  fr?: number;
};

export type TableColumn<T extends object & { id: string }> = {
  accessor: keyof T | string;
  Header: React.ReactNode;
  Cell: (props: { row: T; index: number; value: any }) => ReactNode;
  isSticky?: boolean;
  isStickyRight?: boolean;
  isDefault?: boolean;
  isHidden?: boolean;
  customWidth?: TableColumnWidth;
};

export interface TableProps<T extends object & { id: string }> {
  columns: TableColumn<T>[];
  columnMinWidth?: number;
  data: T[];
  headerAction?: ReactNode;
  rowAction?: (row: T, index: number) => ReactNode;
}

const Wrapper = styled.div<{
  $stickyLeftWidth: number;
  $stickyRightWidth: number;
  $isLeft: boolean;
  $isRight: boolean;
}>`
  @property --sticky-left-width {
    syntax: '<length>';
    inherits: false;
    initial-value: 0px;
  }

  @property --sticky-right-width {
    syntax: '<length>';
    inherits: false;
    initial-value: 0px;
  }

  --sticky-left-offset: ${({ $stickyLeftWidth }) => $stickyLeftWidth + 1}px;
  --sticky-right-offset: ${({ $stickyRightWidth }) => $stickyRightWidth - 1}px;
  --sticky-left-show: ${({ $isLeft }) => ($isLeft ? 0 : 1)};
  --sticky-right-show: ${({ $isRight }) => ($isRight ? 0 : 1)};

  display: grid;
  position: relative;
  max-width: 100%;

  &:before,
  &:after {
    --sticky-left-width: ${({ $isLeft }) => ($isLeft ? 0 : 20)}px;
    --sticky-right-width: ${({ $isRight }) => ($isRight ? 0 : 20)}px;

    content: '';
    position: absolute;
    left: var(--sticky-left-offset);
    right: var(--sticky-right-offset);
    z-index: 2;
    pointer-events: none;
    transition:
      --sticky-left-width 0.3s,
      --sticky-right-width 0.3s;
  }

  &:before {
    top: 1px;
    height: 40px;
    border-radius: 0 8px 0 0;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.secondary.blendToBackground(25)},
      transparent var(--sticky-left-width),
      transparent calc(100% - var(--sticky-right-width)),
      ${({ theme }) => theme.secondary.blendToBackground(25)} 100%
    );
  }

  &:after {
    top: 42px;
    bottom: 1px;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.background},
      transparent var(--sticky-left-width),
      transparent calc(100% - var(--sticky-right-width)),
      ${({ theme }) => theme.background} 100%
    );
    border-radius: 0 0 8px 0;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overscroll-behavior: contain;
  border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  border-radius: 8px;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TableWrapper = styled.div<{ $columnsStyle: string }>`
  --columns: ${({ $columnsStyle }) => $columnsStyle};

  position: relative;
  display: grid;
  width: 100%;
  min-width: fit-content;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: var(--columns);
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

const HeaderContent = styled.div``;

const HeaderCell = styled.div<{
  $isSticky?: boolean;
  $stickyLeft?: number;
  $isStickyRight?: boolean;
  $stickyRight?: number;
  $hasAction?: boolean;
}>`
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};

  ${({ $hasAction }) =>
    $hasAction &&
    css`
      &:not(:nth-last-child(-n + 2)) {
        border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
      }
    `}

  ${({ $hasAction }) =>
    !$hasAction &&
    css`
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
      }
    `}

  ${({ $isSticky, $stickyLeft }) =>
    $isSticky &&
    css`
      position: sticky;
      left: ${$stickyLeft}px;
      z-index: 2;
      background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
    `}

  ${({ $isStickyRight, $stickyRight }) =>
    $isStickyRight &&
    css`
      position: sticky;
      right: ${$stickyRight}px;
      z-index: 2;
      border-left: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
      border-right: 0;
      background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};

      &:not(:nth-last-child(-n + 2)) {
        border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
      }
    `}
`;

const BodyCell = styled.div<{
  $isSticky?: boolean;
  $stickyLeft?: number;
  $isStickyRight?: boolean;
  $stickyRight?: number;
  $hasAction?: boolean;
}>`
  ${({ $hasAction }) =>
    $hasAction &&
    css`
      &:not(:nth-last-child(-n + 2)) {
        border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
      }
    `}

  ${({ $hasAction }) =>
    !$hasAction &&
    css`
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
      }
    `}

  ${({ $isSticky, $stickyLeft }) =>
    $isSticky &&
    css`
      position: sticky;
      left: ${$stickyLeft}px;
      z-index: 2;
      background-color: ${({ theme }) => theme.background};
    `}

  ${({ $isStickyRight, $stickyRight }) =>
    $isStickyRight &&
    css`
      position: sticky;
      right: ${$stickyRight}px;
      border-left: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
      border-right: 0;
      z-index: 2;
      background-color: ${({ theme }) => theme.background};
    `}
`;

const BodyRow = styled.div`
  display: grid;
  grid-template-columns: var(--columns);
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  }

  &:last-child {
    ${BodyCell} {
      --table-cell-border-radius: 0px;

      &:last-child {
        --table-cell-border-radius: 0 0 8px 0;
      }

      &:first-child {
        --table-cell-border-radius: 0 0 0 8px;
      }
    }
  }
`;

const ActionCell = styled.div<{ $isHeader?: boolean }>`
  --button-padding-x-sm: 8px;

  position: sticky;
  right: 0;
  background-color: ${({ theme }) => theme.background};
  z-index: 2;
  border-left: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  padding: ${({ $isHeader }) => ($isHeader ? '2px 4px' : '4px')};
  box-sizing: border-box;
`;

const BodyContent = styled.div``;

const Empty = styled.div`
  padding: 12px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
`;

const ArrowLeft = styled(Icon.ChevronLeft)`
  --icon-size: 14px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};

  padding: 4px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
  position: absolute;
  left: var(--sticky-left-offset);
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: var(--sticky-left-show);
  transition: opacity 0.3s;
  z-index: 4;
  margin-top: 19px;
  pointer-events: none;
`;

const ArrowRight = styled(Icon.ChevronRight)`
  --icon-size: 14px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};

  padding: 4px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
  position: absolute;
  right: var(--sticky-right-offset);
  top: 50%;
  transform: translateX(50%) translateY(-50%);
  opacity: var(--sticky-right-show);
  transition: opacity 0.3s;
  z-index: 4;
  margin-top: 19px;
  pointer-events: none;
`;

export const Table = <T extends object & { id: string }>({
  columns,
  columnMinWidth,
  data,
  headerAction,
  rowAction,
}: TableProps<T>) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [isAtLeft, setIsAtLeft] = useState<boolean>(true);
  const [isAtRight, setIsAtRight] = useState<boolean>(false);

  const columnsStyle = useMemo(() => {
    const baseColumns = columns
      .filter((col) => !col.isHidden)
      .map((col) => {
        if (col.customWidth?.fixed) {
          return `${col.customWidth.fixed}px`;
        } else {
          return `minmax(${col.customWidth?.min ?? columnMinWidth ?? 100}px, ${col.customWidth?.fr ?? 1}fr)`;
        }
      });

    if (headerAction || rowAction) {
      baseColumns.push('40px');
    }

    return baseColumns.join(' ');
  }, [columns, columnMinWidth, headerAction, rowAction]);

  const stickyLeftPositions = useMemo(() => {
    let currentLeft = 0;
    return columns
      .filter((col) => !col.isHidden)
      .reduce<Record<number, number>>((acc, col, index) => {
        if (col.isSticky) {
          acc[index] = currentLeft;
          currentLeft += col.customWidth?.fixed || columnMinWidth || 100;
        }
        return acc;
      }, {});
  }, [columns, columnMinWidth]);

  const stickyLeftWidth = useMemo(() => {
    return columns
      .filter((col) => !col.isHidden && col.isSticky)
      .reduce((acc, col) => acc + (col.customWidth?.fixed || columnMinWidth || 100), 0);
  }, [columns, columnMinWidth]);

  const stickyRightPositions = useMemo(() => {
    let currentRight = 0;
    return columns
      .slice()
      .reverse()
      .filter((col) => !col.isHidden)
      .reduce<Record<number, number>>((acc, col, index) => {
        if (col.isStickyRight) {
          acc[columns.length - 1 - index] = currentRight;
          currentRight += col.customWidth?.fixed || columnMinWidth || 100;
        }
        return acc;
      }, {});
  }, [columns, columnMinWidth]);

  const stickyRightWidth = useMemo(() => {
    const stickyColumnsWidth = columns
      .filter((col) => !col.isHidden && col.isStickyRight)
      .reduce((acc, col) => acc + (col.customWidth?.fixed || columnMinWidth || 100), 0);

    // Add 40px for rowAction if it exists
    return stickyColumnsWidth + (rowAction ? 40 : 0);
  }, [columns, columnMinWidth, rowAction]);

  const onScroll = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const threshold = 8;

    const container = containerRef.current;
    const isLeft = container.scrollLeft <= threshold;
    const isRight = Math.abs(container.scrollWidth - container.clientWidth - container.scrollLeft) <= threshold;

    if (isLeft !== isAtLeft) {
      setIsAtLeft(isLeft);
    }

    if (isRight !== isAtRight) {
      setIsAtRight(isRight);
    }
  }, [containerRef, isAtLeft, isAtRight]);

  useEffect(() => {
    onScroll();
  }, [columnsStyle]);

  return (
    <Wrapper
      $stickyLeftWidth={stickyLeftWidth}
      $stickyRightWidth={stickyRightWidth}
      $isLeft={isAtLeft}
      $isRight={isAtRight}
    >
      <ArrowLeft />
      <ArrowRight />
      <TableContainer onScroll={onScroll} ref={containerRef}>
        <TableWrapper $columnsStyle={columnsStyle}>
          <HeaderRow>
            {columns.map(
              (column, columnIndex) =>
                !column.isHidden && (
                  <HeaderCell
                    $isSticky={column.isSticky}
                    $stickyLeft={stickyLeftPositions[columnIndex]}
                    $isStickyRight={column.isStickyRight}
                    $stickyRight={stickyRightPositions[columnIndex]}
                    $hasAction={!!headerAction}
                    key={columnIndex}
                  >
                    <HeaderContent>{column.Header}</HeaderContent>
                  </HeaderCell>
                )
            )}
            {headerAction && <ActionCell $isHeader>{headerAction}</ActionCell>}
          </HeaderRow>

          <div>
            {data.map((row, rowIndex) => (
              <BodyRow key={row.id}>
                {columns.map((column, cellIndex) => {
                  if (column.isHidden) {
                    return null;
                  }

                  const value = typeof column.accessor === 'string' ? row[column.accessor as keyof T] : null;

                  return (
                    <BodyCell
                      $isSticky={column.isSticky}
                      $stickyLeft={stickyLeftPositions[cellIndex]}
                      $isStickyRight={column.isStickyRight}
                      $stickyRight={stickyRightPositions[cellIndex]}
                      $hasAction={!!rowAction}
                      key={`${row.id}-${cellIndex}`}
                    >
                      <BodyContent>{column.Cell({ row, index: rowIndex, value })}</BodyContent>
                    </BodyCell>
                  );
                })}
                {rowAction && <ActionCell>{rowAction(row, rowIndex)}</ActionCell>}
              </BodyRow>
            ))}
          </div>
          {data.length === 0 && <Empty>No entries</Empty>}
        </TableWrapper>
      </TableContainer>
    </Wrapper>
  );
};
