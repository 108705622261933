import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import styled from 'styled-components';

import { Toggle } from '@column/column-ui-kit';

import { Permissions, TransfersAndAccountsFields } from '../types';
import { FormElement, FormLabel } from '~/styles';

const ToggleWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  svg {
    --icon-size: 18px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const PermissionTogglesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const PermissionTogglesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 200px;
`;

interface PermissionTogglesProps {
  control: Control;
  parentPermissions?: Permissions;
  permissionsToLabelsMap: { [permission: string]: string };
  fieldPathPrefix?: FieldPath<TransfersAndAccountsFields>;
}
export const PermissionToggles: React.FC<PermissionTogglesProps> = ({
  control,
  parentPermissions,
  fieldPathPrefix,
  permissionsToLabelsMap,
}) => {
  const maxColumns = 3;
  const itemsPerColumn = Math.ceil(Object.entries(permissionsToLabelsMap).length / maxColumns);

  return (
    <PermissionTogglesContainer>
      {[...Array(maxColumns)].map((_, columnIndex) => (
        <PermissionTogglesColumn key={columnIndex}>
          {Object.entries(permissionsToLabelsMap)
            .slice(columnIndex * itemsPerColumn, (columnIndex + 1) * itemsPerColumn)
            .map(([permission, label]) => (
              <FormElement key={permission}>
                <ToggleWrapper>
                  <Controller
                    name={`${fieldPathPrefix ? `${fieldPathPrefix}.` : ''}${permission}`}
                    control={control}
                    render={({ field }) => {
                      const effectivePermission =
                        field.value !== 'default' ? field.value : parentPermissions?.[permission];
                      const isChecked =
                        effectivePermission && effectivePermission !== 'default'
                          ? effectivePermission === 'write'
                          : false;
                      return (
                        <>
                          <Toggle
                            isChecked={isChecked}
                            onCheckedChange={(newIsChecked) => {
                              field.onChange(newIsChecked ? 'write' : 'read');
                            }}
                          />
                          <FormLabel variant={parentPermissions && field.value !== 'default' ? 'primary' : undefined}>
                            {label}
                          </FormLabel>
                        </>
                      );
                    }}
                  />
                </ToggleWrapper>
              </FormElement>
            ))}
        </PermissionTogglesColumn>
      ))}
    </PermissionTogglesContainer>
  );
};
