export const testDateFormat = (value: string | undefined) => {
  return value && /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i.test(value);
};

export const getDateFormatFromValues = (year: string | number, month: string | number, day: string | number) => {
  return `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const getDateTimeString = (date: Date): string => {
  const pad = (num: number): string => (num < 10 ? `0${num}` : `${num}`);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const timezoneOffset = date.getTimezoneOffset();
  const offsetSign = timezoneOffset > 0 ? '-' : '+';
  const absOffset = Math.abs(timezoneOffset);
  const offsetHours = pad(Math.floor(absOffset / 60));
  const offsetMinutes = pad(absOffset % 60);
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
};

export const getDateFormat = (date: Date) => {
  return `${date.getFullYear().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
};

export const checkDateParts = (date: any) => {
  if (typeof date !== 'string') {
    return false;
  }

  const parts = date.split('-');

  if (!testDateFormat(date)) {
    return false;
  }

  if (
    Number(parts[1]) < 13 &&
    Number(parts[1]) > 0 &&
    Number(parts[2]) < 32 &&
    Number(parts[2]) > 0 &&
    Number(parts[0]) > 0
  ) {
    return true;
  }

  return false;
};

export const getDateParts = (date: string) => {
  const parts = date.split('-');

  return { year: parts[0], month: parts[1], day: parts[2] };
};

// Day of the week, Month Day, Year - 00:00 PM

export const getDateText = (object: Date): string => {
  return `${object.toLocaleString('en-US', { weekday: 'long' })}, ${object.toLocaleString('en-US', {
    month: 'long',
  })} ${object.getDate()}, ${object.getFullYear()} - ${object.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })}`;
};

// Month Day, Year - 00:00 PM

export const getDateTextShort = (object: Date): string => {
  return `${object.toLocaleString('en-US', {
    month: 'long',
  })} ${object.getDate()}, ${object.getFullYear()} - ${object.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })}`;
};

export const getDateUTC = (timestamp: Date): string => {
  const year = timestamp.getUTCFullYear();
  const month = String(timestamp.getUTCMonth() + 1).padStart(2, '0');
  const day = String(timestamp.getUTCDate()).padStart(2, '0');
  const hours = String(timestamp.getUTCHours()).padStart(2, '0');
  const minutes = String(timestamp.getUTCMinutes()).padStart(2, '0');
  const timeZoneOffset = timestamp.getTimezoneOffset();
  const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60))
    .toString()
    .padStart(2, '0');
  const timeZoneOffsetMinutes = (Math.abs(timeZoneOffset) % 60).toString().padStart(2, '0');
  const timeZoneSign = timeZoneOffset >= 0 ? '-' : '+';
  const utcString = `${year}-${month}-${day} T${hours}:${minutes} ${timeZoneSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes}`;
  return utcString;
};

export const getDateLongUTC = (time: string | Date): string => {
  const timestamp = new Date(time);
  const year = timestamp.getUTCFullYear();
  const month = timestamp.toLocaleString('en-US', { month: 'long' });
  const day = String(timestamp.getUTCDate()).padStart(2, '0');
  const hours = String(timestamp.getUTCHours()).padStart(2, '0');
  const minutes = String(timestamp.getUTCMinutes()).padStart(2, '0');
  const timeZoneOffset = timestamp.getTimezoneOffset();
  const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60))
    .toString()
    .padStart(2, '0');
  const timeZoneOffsetMinutes = (Math.abs(timeZoneOffset) % 60).toString().padStart(2, '0');
  const timeZoneSign = timeZoneOffset >= 0 ? '-' : '+';
  const utcString = `${month} ${day}, ${year} — ${hours}:${minutes} ${timeZoneSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes} UTC`;
  return utcString;
};

export const getTimePassed = (time: string | Date): string => {
  const currentTime = new Date();
  const eventTime = new Date(time);
  const timeDiff = currentTime.getTime() - eventTime.getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 365) {
    const years = Math.floor(days / 365);
    return `${years}y ago`;
  }
  if (days > 7) {
    const weeks = Math.floor(days / 7);
    return `${weeks}w ago`;
  }
  if (days > 0) {
    return `${days}d ago`;
  }
  if (hours > 0) {
    return `${hours}h ago`;
  }
  if (minutes > 0) {
    return `${minutes}m ago`;
  }
  return `${seconds}s ago`;
};

export type FormattedDateFormat =
  | 'time-passed'
  | 'mm/dd/yyyy'
  | 'month year'
  | 'month day'
  | 'month day hh:mm'
  | 'month day hh:mm AM/PM'
  | 'day month year'
  | 'mm/dd/yyy hh:mm'
  | 'mm/dd/yyy hh:mm AM/PM'
  | 'mm dd, yyyy hh:mm'
  | 'mm dd, yyyy hh:mm AM/PM';

export const getFormattedDate = (timestamp: Date | string, format: FormattedDateFormat) => {
  const date = new Date(timestamp);

  switch (format) {
    case 'time-passed':
      return getTimePassed(timestamp);
    case 'mm/dd/yyyy':
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
    case 'month year':
      return date.toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      });
    case 'month day':
      return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      });
    case 'month day hh:mm':
      return `${date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      })} ${date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })}`;
    case 'month day hh:mm AM/PM':
      return `${date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      })} ${date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })}`;
    case 'day month year':
      return date.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    case 'mm/dd/yyy hh:mm':
      return `${date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })} ${date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })}`;
    case 'mm/dd/yyy hh:mm AM/PM':
      return `${date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })} ${date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })}`;
    case 'mm dd, yyyy hh:mm':
      return `${date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })} ${date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })}`;
    case 'mm dd, yyyy hh:mm AM/PM':
      return `${date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })} ${date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })}`;
    default:
      return getTimePassed(timestamp);
  }
};
