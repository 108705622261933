import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  AmountInput,
  Button,
  CurrenciesUSD,
  Dropdown,
  DropdownOption,
  Icon,
  Input,
  Toggle,
} from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { CopyInput, DateInput, PageHeader, RenderFields, RestrictedBanner } from '~/components';
import { EntityRepository, LoanRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EditToolbar, FormParagraphLight, Inner } from '~/styles';
import { CreateLoanRequest } from '~/typings/API';

type CreateLoanRequestForm = Omit<CreateLoanRequest, 'maxPrincipalBalance'> & {
  maxPrincipalBalance: {
    amount: string;
    currency: string;
  };
};

const AddEntity = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.background};
`;

export const PageLoansCreate: React.FC = () => {
  const { currentUser, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [entityList, setEntityList] = useState<DropdownOption[]>([]);

  const formMethods = useForm<CreateLoanRequestForm>({
    defaultValues: {
      isRevolving: false,
      maxPrincipalBalance: {},
    },
  });

  const { handleSubmit } = formMethods;

  const handleCreate = (data: CreateLoanRequestForm) => {
    if (!data.maxPrincipalBalance.currency) {
      data.maxPrincipalBalance.currency = 'USD';
    }

    LoanRepository.create({
      description: data.description,
      isRevolving: data.isRevolving,
      maturityDate: data.maturityDate,
      entityId: data.entityId,
      maxPrincipalBalance: data.maxPrincipalBalance.amount,
      currency: data.maxPrincipalBalance.currency,
    })
      .then((response) => {
        navigate(ROUTE.LOANS);
        addSuccessNotification({
          content: `${response.description} added`,
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchEntities = (startingAfter?: string, name?: string) => {
    EntityRepository.getAll({ startingAfter, name })
      .then((response) => {
        if (response.entities.length) {
          const entries: DropdownOption[] = startingAfter ? entityList.filter((e) => !e.isLoading) : [];
          response.entities.map((entry) => {
            entries.push({
              label: entry.name,
              value: entry.id,
            });
          });
          if (response.hasMore) {
            entries.push({
              label: 'Loading...',
              isLoading: true,
              value: false,
            });
          }
          setEntityList(entries);
        }
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const handleScrollEnd = () => {
    const gotLoading = entityList.find((e) => e.isLoading);

    if (gotLoading) {
      const filteredList = entityList.filter((e) => !e.isLoading);
      fetchEntities(filteredList[filteredList.length - 1]?.value);
    }
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          fetchEntities();
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  return (
    <>
      <PageHeader text="Create Loan">
        <Button onClick={() => navigate(ROUTE.LOANS)} variant="secondary" size="small">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(handleCreate)}
          isDisabled={currentPermission?.loans !== 'write'}
          size="small"
          icon={<Icon.Plus />}
        >
          Create Loan
        </Button>
      </PageHeader>

      {currentPermission?.loans !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <FormProvider {...formMethods}>
        <form autoComplete="off" onSubmit={handleSubmit(handleCreate)}>
          {RenderFields<CreateLoanRequestForm>({
            sections: [
              {
                headline: 'Information',
                fields: [
                  {
                    id: 'description',
                    label: 'Description',
                    rules: { required: true },
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <Input
                          value={value !== undefined ? String(value) : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="Description"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'isRevolving',
                    label: 'Is Revolving?',
                    description:
                      'Indicates whether or not the loan is revolving. If checked the loan can have multiple disbursements.',
                    children: ({ value, onChange }) => {
                      const isChecked = typeof value === 'boolean' ? value : false;
                      return <Toggle isChecked={isChecked} onCheckedChange={onChange} />;
                    },
                  },
                  {
                    id: 'entityId',
                    label: 'Entity',
                    rules: { required: true },
                    children: ({ value, onChange }) => {
                      return (
                        <>
                          <Dropdown
                            fullWidth
                            active={value}
                            onChange={onChange}
                            search
                            searchLabel="Business or person name..."
                            onSearchChange={fetchEntities}
                            onScrollEnd={handleScrollEnd}
                            options={entityList}
                          />

                          {entityList.length < 1 && (
                            <FormParagraphLight>
                              No entities found -{' '}
                              <AddEntity to={`${ROUTE.ENTITIES}/edit/business`}>add one first</AddEntity>.
                            </FormParagraphLight>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    id: 'entityId',
                    label: 'Entity ID',
                    children: ({ value }) => {
                      return <CopyInput value={value ? String(value) : ''} placeholder="Select an entity first" />;
                    },
                  },
                  {
                    id: 'maturityDate',
                    label: 'Maturity Date',
                    rules: { required: true },
                    children: ({ value, onChange }) => {
                      return <DateInput value={value ? String(value) : undefined} onChange={onChange} />;
                    },
                  },
                  {
                    id: 'maxPrincipalBalance',
                    label: 'Max Principal Balance',
                    rules: {
                      required: true,
                      validate: (value) => (value as CreateLoanRequestForm['maxPrincipalBalance']).amount !== undefined,
                    },
                    children: ({ value, onChange }) => {
                      const { amount, currency } = value as CreateLoanRequestForm['maxPrincipalBalance'];
                      return (
                        <AmountInput
                          value={amount ? parseInt(String(amount), 10) : undefined}
                          onChange={(newAmount) => onChange({ amount: newAmount, currency })}
                          onCurrencyCodeChange={(newCurrency) => onChange({ currency: newCurrency, amount: amount })}
                          currencyCode={currency}
                          currencyList={CurrenciesUSD}
                        />
                      );
                    },
                  },
                ],
              },
            ],
          })}
        </form>
      </FormProvider>

      <Inner>
        <EditToolbar>
          <Button onClick={() => navigate(ROUTE.LOANS)} variant="secondary" size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleCreate)}
            isDisabled={currentPermission?.loans !== 'write'}
            size="small"
            icon={<Icon.Plus />}
          >
            Create Loan
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
