import { createApiHook } from '~/hooks/utils/createApiHook';
import {
  CreateTransferTemplateResponse,
  CreateTransferTemplateRequest,
  DeleteTransferTemplateResponse,
  DeleteTransferTemplateRequest,
  GetTransferTemplateRequest,
  GetTransferTemplateResponse,
  ListTransferTemplateRequest,
  ListTransferTemplateResponse,
  TransferTemplateRepository,
  UpdateTransferTemplateResponse,
  UpdateTransferTemplateRequest,
} from '~/repositories/Transfer/TransferTemplateRepository';

export const useCreateTransferTemplate = createApiHook<CreateTransferTemplateResponse, CreateTransferTemplateRequest>(
  TransferTemplateRepository.create,
  {
    triggerAutomatically: false,
  }
);

export const useDeleteTransferTemplate = createApiHook<DeleteTransferTemplateResponse, DeleteTransferTemplateRequest>(
  TransferTemplateRepository.delete,
  {
    triggerAutomatically: false,
  }
);

export const useGetTransferTemplate = createApiHook<GetTransferTemplateResponse, GetTransferTemplateRequest>(
  TransferTemplateRepository.get,
  {
    triggerAutomatically: false,
  }
);

export const useListTransferTemplates = createApiHook<ListTransferTemplateResponse, ListTransferTemplateRequest>(
  TransferTemplateRepository.list,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
  }
);

export const useUpdateTransferTemplate = createApiHook<UpdateTransferTemplateResponse, UpdateTransferTemplateRequest>(
  TransferTemplateRepository.update,
  {
    triggerAutomatically: false,
  }
);
