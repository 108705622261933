import React, {
  CSSProperties,
  PropsWithChildren,
  createRef,
  forwardRef,
  useEffect,
  useState,
  ChangeEvent,
  KeyboardEvent,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';

import { Icon, InputContainer, InputElement, InputWrapper, Portal, PortalPlacement } from '@column/column-ui-kit';

import { checkDateParts, getDateParts, getDateFormatFromValues } from '~/util';

import { PopoverDatepicker } from './Popover';

export interface DateInputStyleProps {
  placement?: PortalPlacement;
  isFocussed: boolean;
  isHovered: boolean;
  isDisabled: boolean;
  hasError: boolean;
  size: 'default' | 'small';
  variant: 'default' | 'light';
}

export interface DateInputProps extends Partial<DateInputStyleProps> {
  onChange?: (value: string | undefined) => void;
  onClick?: () => void;
  onHoverChange?: (value: boolean) => void;
  className?: string;
  value?: string | undefined;
  style?: CSSProperties;
  disableBefore?: Date;
  disableAfter?: Date;
}

const DateInputWrapper = styled.div`
  position: relative;
`;

const Fields = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Divider = styled.div`
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(400)};
`;

const DayField = styled(InputElement)`
  width: 24px;
`;

const MonthField = styled(InputElement)`
  width: 30px;
`;

const YearField = styled(InputElement)`
  width: 44px;
`;

const Calendar = styled(Icon.Calendar)<DateInputStyleProps>`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  --icon-size: 16px;
  margin-left: auto;
  cursor: pointer;

  path {
    transition:
      stroke 0.2s,
      fill 0.2s;
  }

  &:hover {
    --icon-color: ${({ theme }) => theme.secondary.background};
  }

  ${({ isFocussed }) =>
    isFocussed &&
    css`
      --icon-color: ${({ theme }) => theme.primary.background};
    `}
`;

const StyledPopoverDatepicker = styled(PopoverDatepicker)`
  --popover-width: 280px;
  --popover-padding: 16px;
  --popover-top: 48px;
  --popover-left: 0;
  --popover-right: auto;
`;

export const DateInput = forwardRef<HTMLLabelElement, PropsWithChildren<DateInputProps>>((props, ref) => {
  const [isHoveredState, setIsHoveredState] = useState<boolean>(props.isHovered ?? false);
  const [hasErrorState, setHasErrorState] = useState<boolean>(props.hasError ?? false);
  const [isMonthFocussedState, setIsMonthFocussedState] = useState<boolean>(false);
  const [isDayFocussedState, setIsDayFocussedState] = useState<boolean>(false);
  const [isYearFocussedState, setIsYearFocussedState] = useState<boolean>(false);
  const [monthValue, setMonthValue] = useState<string>('');
  const [dayValue, setDayValue] = useState<string>('');
  const [yearValue, setYearValue] = useState<string>('');
  const [showDatepicker, setShowDatepicker] = useState<boolean>(false);

  const monthInputRef = createRef<HTMLInputElement>();
  const dayInputRef = createRef<HTMLInputElement>();
  const yearInputRef = createRef<HTMLInputElement>();

  const styleProps: DateInputStyleProps = {
    isHovered: props.isHovered ?? isHoveredState,
    isFocussed: (isMonthFocussedState || isDayFocussedState || isYearFocussedState) ?? false,
    isDisabled: props.isDisabled ?? false,
    hasError: props.hasError ?? hasErrorState,
    size: props.size ?? 'default',
    variant: props.variant ?? 'default',
  };

  const handleClick = () => {
    if (props.isDisabled) {
      return;
    }
    if (props.onClick) {
      props.onClick();
    }
    setIsMonthFocussedState(true);
  };

  const handleCalendarClick = (event: React.MouseEvent) => {
    if (props.isDisabled) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    setShowDatepicker(true);
  };

  const setNewDateValues = (value: string | undefined) => {
    if (!value || !checkDateParts(value)) {
      return;
    }

    const { year, month, day } = getDateParts(value);

    setYearValue(year);
    setMonthValue(month);
    setDayValue(day);
  };

  const handleDatePicker = (value: string | string[] | undefined) => {
    if (Array.isArray(value) || !value) {
      return;
    }

    setNewDateValues(value);

    setShowDatepicker(false);
  };

  const handleCalendarClose = () => {
    setShowDatepicker(false);
  };

  const handleHoverChange = (value: boolean) => {
    if (props.isDisabled) {
      return;
    }
    if (props.onHoverChange) {
      props.onHoverChange(value);
    }
    setIsHoveredState(value);
  };

  const handleMonthChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setMonthValue(target.value);
  };

  const handleMonthKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const length = target.value.length;

    if (length === 2 && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'Backspace') {
      setIsMonthFocussedState(false);
      setIsDayFocussedState(true);
    }
    if (target.selectionStart === length && event.key === 'ArrowRight') {
      setIsMonthFocussedState(false);
      setIsDayFocussedState(true);
    }
  };

  const handleDayChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setDayValue(target.value);
  };

  const handleDayKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const length = target.value.length;

    if (length === 2 && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'Backspace') {
      setIsDayFocussedState(false);
      setIsYearFocussedState(true);
    }
    if (target.selectionStart === 0 && (event.key === 'ArrowLeft' || event.key === 'Backspace')) {
      setIsDayFocussedState(false);
      setIsMonthFocussedState(true);
    }
    if (target.selectionStart === length && event.key === 'ArrowRight') {
      setIsDayFocussedState(false);
      setIsYearFocussedState(true);
    }
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setYearValue(target.value);
  };

  const handleYearKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const length = target.value.length;
    if (target.selectionStart === 0 && (event.key === 'ArrowLeft' || event.key === 'Backspace')) {
      setIsYearFocussedState(false);
      setIsDayFocussedState(true);
    }
    if (target.selectionStart === length && event.key === 'ArrowRight') {
      setIsYearFocussedState(false);
      setIsMonthFocussedState(true);
    }
  };

  useEffect(() => {
    if (isMonthFocussedState) {
      monthInputRef.current?.focus();
    } else {
      monthInputRef.current?.blur();
    }
  }, [isMonthFocussedState, monthInputRef]);

  useEffect(() => {
    if (isDayFocussedState) {
      dayInputRef.current?.focus();
    } else {
      dayInputRef.current?.blur();
    }
  }, [isDayFocussedState, dayInputRef]);

  useEffect(() => {
    if (isYearFocussedState) {
      yearInputRef.current?.focus();
    } else {
      yearInputRef.current?.blur();
    }
  }, [isYearFocussedState, yearInputRef]);

  useEffect(() => {
    const dateFormat = getDateFormatFromValues(yearValue, monthValue, dayValue);

    const validDate = checkDateParts(dateFormat);

    setHasErrorState(
      !!(
        (!validDate && !!monthValue && !!dayValue && !!yearValue) ||
        (props.disableBefore && validDate && props.disableBefore > new Date(dateFormat)) ||
        (props.disableAfter && validDate && props.disableAfter < new Date(dateFormat))
      )
    );

    if (!validDate) {
      return;
    }

    if (props.onChange && dateFormat !== props.value) {
      props.onChange(dateFormat);
    }
  }, [monthValue, dayValue, yearValue]);

  useEffect(() => {
    if (props.value) {
      setNewDateValues(props.value);
    }
  }, [props.value]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <DateInputWrapper ref={wrapperRef}>
      <InputWrapper
        ref={ref}
        style={props.style}
        className={props.className}
        onMouseEnter={() => handleHoverChange(true)}
        onMouseMove={() => handleHoverChange(true)}
        onMouseLeave={() => handleHoverChange(false)}
        onClick={handleClick}
        {...styleProps}
      >
        <InputContainer {...styleProps}>
          <Fields>
            <MonthField
              onChange={handleMonthChange}
              onFocus={() => setIsMonthFocussedState(true)}
              onBlur={() => setIsMonthFocussedState(false)}
              onKeyUp={handleMonthKeyUp}
              ref={monthInputRef}
              disabled={props.isDisabled ?? false}
              value={monthValue}
              type="tel"
              pattern="^[0-9]*$"
              maxLength={2}
              placeholder="mm"
            />
            <Divider>/</Divider>
            <DayField
              onChange={handleDayChange}
              onFocus={() => setIsDayFocussedState(true)}
              onBlur={() => setIsDayFocussedState(false)}
              onKeyUp={handleDayKeyUp}
              ref={dayInputRef}
              disabled={props.isDisabled ?? false}
              value={dayValue}
              type="tel"
              pattern="^[0-9]*$"
              maxLength={2}
              placeholder="dd"
            />
            <Divider>/</Divider>
            <YearField
              onChange={handleYearChange}
              onFocus={() => setIsYearFocussedState(true)}
              onBlur={() => setIsYearFocussedState(false)}
              onKeyUp={handleYearKeyUp}
              ref={yearInputRef}
              disabled={props.isDisabled ?? false}
              value={yearValue}
              type="tel"
              pattern="^[0-9]*$"
              maxLength={4}
              placeholder="yyyy"
            />
          </Fields>
          <Calendar onClick={handleCalendarClick} {...styleProps} />
        </InputContainer>
        {props.children}
      </InputWrapper>
      <Portal
        reference={wrapperRef}
        placement={props.placement || 'top-start'}
        restrictFlipCross
        restrictFlipMain
        shouldCalculate={{ is: showDatepicker, timeout: 200 }}
      >
        <StyledPopoverDatepicker
          show={showDatepicker}
          date={props.value}
          onDateSubmit={handleDatePicker}
          onClose={handleCalendarClose}
          disableBefore={props.disableBefore}
          disableAfter={props.disableAfter}
        />
      </Portal>
    </DateInputWrapper>
  );
});
