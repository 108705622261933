import styled from 'styled-components';

import { Fade } from '@column/column-ui-kit';

export const TableBottomFade = styled(Fade)`
  padding: 16px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`;
