import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AmountInput,
  Button,
  CurrenciesUSD,
  Dropdown,
  DropdownOption,
  Icon,
  Input,
  Toggle,
  formatNumber,
} from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader, RenderFields, RestrictedBanner } from '~/components';
import { Loan, LoanRepository } from '~/repositories';
import { BankAccountRepository } from '~/repositories/BankAccountRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EditToolbar, Inner } from '~/styles';
import { CreateLoanDisbursementRequest } from '~/typings/API';

type CreateLoanDisbursementRequestForm = Omit<CreateLoanDisbursementRequest, 'amount'> & {
  disbursementAmount: {
    amount: string;
    currency: string;
  };
};

interface Params {
  id?: string;
}

export const PageLoansDisbursementCreate: React.FC = () => {
  const currentPermission = useSessionStore((state) => state.currentPermission);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [loan, setLoan] = useState<Partial<Loan>>({});
  const [disbursement, setDisbursement] = useState<Partial<CreateLoanDisbursementRequest>>({});
  const [loanList, setLoanList] = useState<DropdownOption[]>([]);
  const [bankAccountList, setBankAccountList] = useState<DropdownOption[]>([]);

  const formMethods = useForm<CreateLoanDisbursementRequestForm>({
    defaultValues: {
      hold: false,
      disbursementAmount: {},
    },
  });

  const { handleSubmit } = formMethods;

  const handleCreate = (data: CreateLoanDisbursementRequestForm) => {
    if (!data.disbursementAmount.currency) {
      data.disbursementAmount.currency = 'USD';
    }

    LoanRepository.createDisbursement({
      description: data.description,
      loanId: data.loanId,
      bankAccountId: data.bankAccountId,
      amount: data.disbursementAmount.amount,
      currency: data.disbursementAmount.currency,
      hold: data.hold,
    })
      .then((response) => {
        navigate(`${ROUTE.LOANS}/edit/${data.loanId}`);
        addSuccessNotification({
          content: `${response.description} added`,
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchBankAccounts = async (search?: string) => {
    let result: any = [];

    try {
      const response = await BankAccountRepository.getAll({ description: search });
      result = response.bankAccounts;
    } catch (error) {
      addDangerNotification({
        content: error as string,
      });
    }

    const entries: DropdownOption[] = [];
    result.map((entry: any) => {
      entries.push({
        label: `${
          entry?.displayName && entry?.description
            ? `${entry.displayName} – ${entry.description}`
            : entry?.description || 'Unnamed'
        } (${formatNumber(entry?.balances?.availableAmount)})`,
        small: entry.id,
        value: entry.id,
      });
    });

    setBankAccountList(entries);
  };

  const fetchLoans = (startingAfter?: string) => {
    LoanRepository.getAll({ startingAfter })
      .then((response) => {
        if (response.loans.length) {
          const entries: DropdownOption[] = startingAfter ? loanList.filter((e) => !e.isLoading) : [];
          response.loans.map((entry) => {
            entries.push({
              label: entry.description,
              value: entry.id,
            });
          });
          if (response.hasMore) {
            entries.push({
              label: 'Loading...',
              isLoading: true,
              value: false,
            });
          }
          setLoanList(entries);
        }
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const handleScrollEnd = () => {
    const gotLoading = loanList.find((e) => e.isLoading);

    if (gotLoading) {
      const filteredList = loanList.filter((e) => !e.isLoading);
      fetchLoans(filteredList[filteredList.length - 1]?.value);
    }
  };

  useEffect(() => {
    fetchBankAccounts();
    fetchLoans();

    if (!id) {
      return;
    }

    setDisbursement({ ...disbursement, loanId: id });

    LoanRepository.get(id)
      .then((response) => {
        setLoan(response);
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, []);

  return (
    <>
      <PageHeader text="Create Disbursement">
        <Button
          onClick={() => navigate(id ? `${ROUTE.LOANS}/edit/${id}` : `${ROUTE.LOANS}/disbursements`)}
          variant="secondary"
          size="small"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(handleCreate)}
          isDisabled={currentPermission?.loans !== 'write'}
          size="small"
          icon={<Icon.Plus />}
        >
          Create Disbursement
        </Button>
      </PageHeader>

      {currentPermission?.loans !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <FormProvider {...formMethods}>
        <form autoComplete="off" onSubmit={handleSubmit(handleCreate)}>
          {RenderFields<CreateLoanDisbursementRequestForm>({
            sections: [
              {
                headline: 'Information',
                fields: [
                  {
                    id: 'description',
                    label: 'Description',
                    rules: { required: true },
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <Input
                          value={value !== undefined ? String(value) : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="Description"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'loanId',
                    label: 'Loan',
                    rules: { required: true },
                    children: ({ value, onChange }) => {
                      return (
                        <Dropdown
                          fullWidth
                          maxWidth="640px"
                          active={value}
                          onChange={onChange}
                          onScrollEnd={handleScrollEnd}
                          options={loanList}
                          isDisabled={!!id}
                        />
                      );
                    },
                  },
                  {
                    id: 'bankAccountId',
                    label: 'Bank Account',
                    rules: { required: true },
                    children: ({ value, onChange }) => {
                      return (
                        <Dropdown
                          options={bankAccountList}
                          active={value}
                          fullWidth
                          maxWidth="640px"
                          search
                          searchLabel="Search for description"
                          onSearchChange={(search) => fetchBankAccounts(search)}
                          onChange={onChange}
                        />
                      );
                    },
                  },
                  {
                    id: 'disbursementAmount',
                    label: 'Amount',
                    rules: {
                      required: true,
                      validate: (value) =>
                        (value as CreateLoanDisbursementRequestForm['disbursementAmount']).amount !== undefined,
                    },
                    children: ({ value, onChange }) => {
                      const { amount, currency } = value as CreateLoanDisbursementRequestForm['disbursementAmount'];
                      return (
                        <AmountInput
                          value={amount ? parseInt(String(amount), 10) : undefined}
                          onChange={(newAmount) => onChange({ amount: newAmount, currency })}
                          onCurrencyCodeChange={(newCurrency) => onChange({ currency: newCurrency, amount: amount })}
                          currencyCode={currency}
                          currencyList={CurrenciesUSD}
                        />
                      );
                    },
                  },
                  {
                    id: 'hold',
                    label: 'On Hold?',
                    description:
                      'If checked, creates a disbursement in a hold state. The disbursement will not be completed until the clear API is called. Disbursements in a hold state may be updated or canceled.',
                    children: ({ value, onChange }) => {
                      const isChecked = typeof value === 'boolean' ? value : false;
                      return <Toggle isChecked={isChecked} onCheckedChange={onChange} />;
                    },
                  },
                ],
              },
            ],
          })}
        </form>
      </FormProvider>

      <Inner>
        <EditToolbar>
          <Button
            onClick={() => navigate(id ? `${ROUTE.LOANS}/edit/${id}` : `${ROUTE.LOANS}/disbursements`)}
            variant="secondary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleCreate)}
            isDisabled={currentPermission?.loans !== 'write'}
            size="small"
            icon={<Icon.Plus />}
          >
            Create Disbursement
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
