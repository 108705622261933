import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Dropdown, Icon } from '@column/column-ui-kit';

import { OptionsFullGranularWithDefault, OptionsThisBankAccount, OptionsThisEntity, Permissions } from '../types';
import { Breadcrumb, InfoTooltip, Modal, ModalSize, ModalVariant, RenderFields } from '~/components';
import { Divider, Inner, TinyHeadlineLight } from '~/styles';

import { PermissionToggles } from './PermissionToggles';

const ClearOverridesButton = styled(Button)`
  padding: 0;
`;

const CustomOverridesModalControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const CustomOverridesModalControlGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  * > div {
    margin-left: auto;
    max-width: 400px;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 48px);
  max-width: 800px;
  text-align: left;
`;

interface CustomOverridesModalProps {
  breadcrumbs: string[];
  open: boolean;
  onClose: () => void;
  onSave: (permissions: Permissions) => void;
  currentPermissions: Permissions;
  parentPermissions: Permissions;
  permissionsToLabelsMap: { [permission: string]: string };
}
export const CustomOverridesModal: React.FC<CustomOverridesModalProps> = ({
  breadcrumbs,
  currentPermissions,
  open,
  onClose,
  onSave,
  parentPermissions,
  permissionsToLabelsMap,
}) => {
  const permissionsTogglesToLabelsMap = useMemo(() => {
    const p = { ...permissionsToLabelsMap };
    delete p.bankAccounts;
    delete p.thisEntity;
    delete p.thisBankAccount;
    return p;
  }, [permissionsToLabelsMap]);

  const parentName = useMemo(() => {
    if (breadcrumbs.length < 2) {
      return 'the platform';
    }
    return breadcrumbs[1];
  }, [breadcrumbs]);

  const form = useForm<Permissions>({
    values: currentPermissions,
  });
  const { control, getValues, setValue, reset, watch } = form;
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const hasOverrides = useMemo(() => {
    return Object.values(form.watch()).some((value) => value !== 'default');
  }, [form.watch()]);

  const resetOverrides = useCallback(() => {
    Object.keys(permissionsToLabelsMap).forEach((permission) => {
      setValue(permission, 'default');
    });
  }, [setValue, parentPermissions]);

  return (
    <Modal
      headline="Custom overrides"
      open={open}
      onClose={onClose}
      primaryButton={{
        text: 'Save Changes',
        onClick: () => onSave(getValues()),
      }}
      secondaryButton={{ text: 'Cancel', onClick: onClose }}
      size={ModalSize.FitContent}
      variant={ModalVariant.Compact}
    >
      <Wrapper>
        <CustomOverridesModalControls>
          <Breadcrumb entries={breadcrumbs.map((breadcrumb) => ({ label: breadcrumb }))} />
          {hasOverrides && (
            <CustomOverridesModalControlGroup>
              <InfoTooltip
                content={`By default, permissions are inherited from ${parentName}. Permissions highlighted in blue override permissions from ${parentName}.`}
                zIndex={14}
              />
              <ClearOverridesButton
                icon={<Icon.Reset />}
                iconRight
                size={'small'}
                variant="subtle-primary"
                onClick={() => resetOverrides()}
              >
                Clear overrides
              </ClearOverridesButton>
            </CustomOverridesModalControlGroup>
          )}
        </CustomOverridesModalControls>
        <PermissionToggles
          control={control}
          parentPermissions={parentPermissions}
          permissionsToLabelsMap={permissionsTogglesToLabelsMap}
        />
        <Inner px={0}>
          <Divider fullWidth />
        </Inner>
        <Inner p={0} pb={12}>
          <TinyHeadlineLight>Edit Access</TinyHeadlineLight>
        </Inner>
        <FormProvider {...form}>
          {RenderFields<any>({
            sections: [
              {
                variant: 'isCompact',
                fields: [
                  {
                    id: 'thisBankAccount',
                    hide: !currentPermissions.thisBankAccount,
                    label: 'This Bank Account',
                    description: 'Set edit permissions on this Account.',
                    compact: true,
                    children: ({ value, onChange }) => {
                      return (
                        <DropdownWrapper>
                          <Dropdown
                            active={value ?? 'default'}
                            options={OptionsThisBankAccount}
                            onChange={onChange}
                            fullWidth
                          />
                        </DropdownWrapper>
                      );
                    },
                  },
                  {
                    id: 'thisEntity',
                    hide: !currentPermissions.thisEntity,
                    label: 'This Entity',
                    description: 'Set edit permissions on this Entity.',
                    compact: true,
                    children: ({ value, onChange }) => {
                      return (
                        <DropdownWrapper>
                          <Dropdown
                            active={value ?? 'default'}
                            options={OptionsThisEntity}
                            onChange={onChange}
                            fullWidth
                          />
                        </DropdownWrapper>
                      );
                    },
                  },
                  {
                    id: 'bankAccounts',
                    hide: !currentPermissions.bankAccounts,
                    label: 'Bank Accounts on Entity',
                    description: 'Set edit permissions on the underlying Accounts on this Entity.',
                    compact: true,
                    children: ({ value, onChange }) => {
                      return (
                        <DropdownWrapper>
                          <Dropdown
                            active={value ?? 'default'}
                            options={OptionsFullGranularWithDefault}
                            onChange={onChange}
                            fullWidth
                          />
                        </DropdownWrapper>
                      );
                    },
                  },
                ],
              },
            ],
          })}
        </FormProvider>

        <Inner px={0} pb={0}>
          <Divider fullWidth />
        </Inner>
      </Wrapper>
    </Modal>
  );
};
