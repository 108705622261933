import {
  CustomRoleBankAccountLevelOverrides,
  CustomRoleEntityLevelOverrides,
  CustomRoleGranularPermissionLevel,
  CustomRolePermissionLevel,
  CustomRolePlatformLevelPermissions,
  PlatformRoleResponseObject,
} from '~/typings/API';

export interface Action {
  permission:
    | keyof CustomRolePlatformLevelPermissions
    | keyof CustomRoleEntityLevelOverrides
    | keyof CustomRoleBankAccountLevelOverrides;
  permissionLevel: CustomRolePermissionLevel | CustomRoleGranularPermissionLevel;
  bankAccount?: {
    id: string;
    owners?: string[];
  };
}

export const checkAccess = (role: PlatformRoleResponseObject, action: Action): boolean => {
  const platformPermissionLevel =
    role.platformLevelPermissions[action.permission as keyof CustomRolePlatformLevelPermissions];
  const allowedOnPlatform = checkPermission(platformPermissionLevel, action.permissionLevel);
  if (!action.bankAccount) {
    // If no bank account is specified, access is allowed if some override grants access
    return (
      allowedOnPlatform ||
      (role.entityLevelOverrides ?? []).some((entityOverride) => {
        let entityPermissionLevel = entityOverride[action.permission as keyof CustomRoleEntityLevelOverrides];
        if (!entityPermissionLevel && entityOverride.thisEntity) {
          entityPermissionLevel = entityOverride.thisEntity;
        }
        if (entityPermissionLevel === 'default' || !entityPermissionLevel) {
          return false;
        }
        return checkPermission(
          entityPermissionLevel as CustomRolePermissionLevel | CustomRoleGranularPermissionLevel,
          action.permissionLevel
        );
      }) ||
      (role.bankAccountLevelOverrides ?? []).some((bankAccountOverride) => {
        let curBankAccountPermissionLevel =
          bankAccountOverride[action.permission as keyof CustomRoleBankAccountLevelOverrides];
        if (!curBankAccountPermissionLevel && bankAccountOverride.thisBankAccount) {
          curBankAccountPermissionLevel = bankAccountOverride.thisBankAccount;
        }
        if (curBankAccountPermissionLevel === 'default' || !curBankAccountPermissionLevel) {
          return false;
        }
        return checkPermission(
          curBankAccountPermissionLevel as CustomRolePermissionLevel | CustomRoleGranularPermissionLevel,
          action.permissionLevel
        );
      })
    );
  }

  // If a bank account is specified, resolve access on overrides (that may allow or deny access)
  let allowedOnEntity = allowedOnPlatform;
  if (action.bankAccount.owners && role.entityLevelOverrides) {
    role.entityLevelOverrides.forEach((entityOverride) => {
      if (action.bankAccount!.owners?.some((entityId) => entityId === entityOverride.entityId)) {
        let entityPermissionLevel = entityOverride[action.permission as keyof CustomRoleEntityLevelOverrides];
        if (!entityPermissionLevel && entityOverride.thisEntity) {
          entityPermissionLevel = entityOverride.thisEntity;
        }
        if (entityPermissionLevel === 'default' || !entityPermissionLevel) {
          return;
        }

        allowedOnEntity = checkPermission(
          entityPermissionLevel as CustomRolePermissionLevel | CustomRoleGranularPermissionLevel,
          action.permissionLevel
        );
      }
    });
  }

  const bankAccountPermissions = (role.bankAccountLevelOverrides ?? []).find(
    (bankAccountOverride) => bankAccountOverride.bankAccountId === action.bankAccount!.id
  );
  if (!bankAccountPermissions) {
    return allowedOnEntity;
  }
  let bankAccountPermissionLevel =
    bankAccountPermissions[action.permission as keyof CustomRoleBankAccountLevelOverrides];
  if (!bankAccountPermissionLevel && bankAccountPermissions.thisBankAccount) {
    bankAccountPermissionLevel = bankAccountPermissions.thisBankAccount;
  }
  if (bankAccountPermissionLevel === 'default' || !bankAccountPermissionLevel) {
    return allowedOnEntity;
  }

  return checkPermission(
    bankAccountPermissionLevel as CustomRolePermissionLevel | CustomRoleGranularPermissionLevel,
    action.permissionLevel
  );
};

const checkPermission = (
  requesterLevel: CustomRolePermissionLevel | CustomRoleGranularPermissionLevel | undefined,
  allowedLevel: CustomRolePermissionLevel | CustomRoleGranularPermissionLevel
): boolean => {
  if (!requesterLevel) {
    return false;
  }
  if (requesterLevel === 'create-delete') {
    return true;
  }
  if (requesterLevel === 'write' || requesterLevel === 'update') {
    switch (allowedLevel) {
      case 'create-delete':
        return false;
      case 'write':
      case 'update':
      case 'read':
      case 'none':
      case 'default':
        return true;
    }
  }
  if (requesterLevel === 'read') {
    switch (allowedLevel) {
      case 'create-delete':
      case 'update':
      case 'write':
        return false;
      case 'read':
      case 'none':
      case 'default':
        return true;
    }
  }
  if (requesterLevel === 'none') {
    return false;
  }

  return false;
};
