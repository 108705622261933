import { PlatformRoleName } from '~/repositories/UserRepository';
import {
  CreateCustomRoleRequestObject,
  CustomRoleResponseObject,
  CustomRolesList,
  UpdateCustomRoleRequestObject,
} from '~/typings/API';

import { client } from './client';

interface PrecannedRole {
  id: string;
  name: PlatformRoleName;
  label: string;
}

export const OwnerRoleId = 'role_2SA63qU9bOTyo9e9pxgSZydgvm4';

// Precanned role IDs are guaranteed by the backend response
const precannedRoles: PrecannedRole[] = [
  {
    id: OwnerRoleId,
    name: 'owner',
    label: 'Owner',
  },
  {
    id: 'role_2SA646mhjFjp3NnoCOBKnas3m5N',
    name: 'admin',
    label: 'Admin',
  },
  {
    id: 'role_2SA64DbnUvbmqdaSLK4JgYsOlmu',
    name: 'developer',
    label: 'Developer',
  },
  {
    id: 'role_2SA64IafluCLjiHnrTFkDkYfMd1',
    name: 'money_mover',
    label: 'Money Mover',
  },
  {
    id: 'role_2SA64TfXQ9ZfNdtD1WkG5e23n9O',
    name: 'readonly',
    label: 'Viewer',
  },
  {
    id: 'role_2dblSc9pnP0Kd7AEPI1YfUVgO0B',
    name: 'check_depositor',
    label: 'Check Depositor',
  },
];

export const precannedRolesByID = Object.fromEntries(precannedRoles.map((role) => [role.id, role]));
export const precannedRolesByName = Object.fromEntries(precannedRoles.map((role) => [role.name, role]));

export const getRoleLabel = (name: string) => (precannedRolesByName[name] ? precannedRolesByName[name].label : name);

export class RoleRepository {
  static async create({ mode, ...request }: CreateCustomRoleRequestObject & { mode?: 'live' | 'sandbox' }) {
    return client.post<unknown, CustomRoleResponseObject>('/dashboard/roles', request, {
      sandbox: mode ? mode === 'sandbox' : undefined,
    });
  }

  static async delete({ roleId }: { roleId: string }) {
    return client.delete<unknown, unknown>(`/dashboard/roles/${roleId}`);
  }

  static async get({ roleId }: { roleId: string }) {
    return client.get<unknown, CustomRoleResponseObject>(`/dashboard/roles/${roleId}`);
  }

  static async list() {
    return client.get<unknown, CustomRolesList>('/dashboard/roles');
  }

  static async update({
    mode,
    roleId,
    ...request
  }: { roleId: string } & UpdateCustomRoleRequestObject & { mode?: 'live' | 'sandbox' }) {
    return client.patch<unknown, CustomRoleResponseObject>(`/dashboard/roles/${roleId}`, request, {
      sandbox: mode ? mode === 'sandbox' : undefined,
    });
  }
}
