import React, { PropsWithChildren, createRef, forwardRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

export interface SearchBarStyleProps {
  isFocussed: boolean;
  isLoading: boolean;
}

export interface SearchBarProps extends Partial<SearchBarStyleProps> {
  onChange?: (value: string) => void;
  onClick?: () => void;
  onSearchSubmit?: (value: string) => void;
  onFocusChange?: (value: boolean) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  searchShowReset?: boolean;
  className?: string;
  value?: string;
  placeholder?: string;
}

const Wrapper = styled.div``;

const FieldWrapper = styled.div`
  --button-padding-x-sm: 6px;

  display: flex;
  align-items: center;
`;

const Reset = styled(Button)<{ show?: boolean }>`
  transition:
    opacity 0.2s,
    color 0.2s;

  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const Submit = styled(Reset)<{ show: boolean; showReset?: boolean }>`
  transition:
    opacity 0.2s,
    transform 0.2s,
    color 0.2s;

  ${({ showReset }) =>
    !showReset &&
    css`
      transform: translateX(-50px);
    `}

  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const SearchIcon = styled(Icon.AnimationSearch)`
  margin-right: 8px;
`;

const StyledInputlement = styled.input<SearchBarStyleProps>`
  appearance: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 8px 0 0;
  color: inherit;
  font: inherit;
  letter-spacing: -0.125px;
  line-height: 22px;
  height: 22px;
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.background};
  transition-delay: 9999s;
  transition-property: background-color;
  &::placeholder {
    color: ${({ theme }) => theme.secondary.blendToBackground(500)};
    transition: color 0.2s;
  }

  &:hover {
    &::placeholder {
      color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    }
  }
`;

export const SearchBar = forwardRef<{ focus: () => void }, PropsWithChildren<SearchBarProps>>((props, ref) => {
  const [isFocussedState, setIsFocussedState] = useState<boolean>(props.isFocussed ?? false);
  const [currentValue, setCurrentValue] = useState<string>(props.value ?? '');
  const onFocus =
    props.onFocus ??
    (() => {
      /* NOOP */
    });
  const onBlur =
    props.onBlur ??
    (() => {
      /* NOOP */
    });

  const inputRef = createRef<HTMLInputElement>();
  const wrapperRef = createRef<HTMLDivElement>();

  const styleProps: SearchBarStyleProps = {
    isFocussed: props.isFocussed ?? isFocussedState,
    isLoading: props.isLoading ?? false,
  };

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const handleFocusChange = (value: boolean) => {
    if (props.onFocusChange) {
      props.onFocusChange(value);
    }
    setIsFocussedState(value);
  };

  useEffect(() => {
    if (props.isFocussed ?? isFocussedState) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isFocussedState, props.isFocussed]);

  useEffect(() => {
    setCurrentValue(props.value ?? '');
  }, [props.value]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(currentValue);
    }
  }, [currentValue]);

  useEffect(() => {
    const fakeEl = document.createElement('span');

    const input = inputRef.current;
    if (input) {
      const style = window.getComputedStyle(input);
      fakeEl.style.fontFamily = style.fontFamily ?? '';
      fakeEl.style.fontSize = style.fontSize ?? '';
      fakeEl.style.fontWeight = style.fontWeight ?? '';
      fakeEl.style.letterSpacing = style.letterSpacing ?? '';
      fakeEl.innerHTML = (input.value || input.placeholder)
        .replace(/[ ]/g, '&nbsp;')
        .replace(/[<]/g, '&lt;')
        .replace(/[>]/g, '&gt;');
      fakeEl.style.position = 'fixed';
      fakeEl.style.top = '0';
      fakeEl.style.visibility = 'hidden';
      fakeEl.style.pointerEvents = 'none';
      document.body.appendChild(fakeEl);
      const width = fakeEl.clientWidth + 1;
      fakeEl.remove();
      input.style.width = `${width > 380 ? 380 : width}px`;
    }
  }, [props.value, inputRef]);

  const handleResetClick = () => {
    setCurrentValue('');
    inputRef.current?.blur();
    if (props.onSearchSubmit) {
      props.onSearchSubmit('');
    }
  };

  const handleSearchClick = () => {
    if (props.onSearchSubmit) {
      props.onSearchSubmit(currentValue);
    }
  };

  return (
    <Wrapper ref={wrapperRef} className={props.className} onClick={handleClick}>
      <FieldWrapper {...styleProps}>
        <SearchIcon running={styleProps.isLoading} />
        <StyledInputlement
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentValue(e.target.value)}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            handleFocusChange(true);
            onFocus(e);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleFocusChange(false);
            onBlur(e);
          }}
          ref={inputRef}
          value={currentValue}
          type="text"
          placeholder={props.placeholder}
          spellCheck={false}
          {...styleProps}
        />
        <Reset size="small" variant="subtle" onClick={handleResetClick} show={props.searchShowReset}>
          Reset
        </Reset>
        <Submit
          size="small"
          variant="subtle-primary"
          onClick={handleSearchClick}
          show={currentValue.length > 0}
          showReset={props.searchShowReset}
        >
          Search
        </Submit>
      </FieldWrapper>
    </Wrapper>
  );
});
