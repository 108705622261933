import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import { Checkbox } from '@column/column-ui-kit';

import { PopoverBase, PopoverBaseProps } from '~/components/Popover/Base';

export type ColumnListEntry = {
  id: string;
  label: string;
};

interface PopoverColumnsProps extends PopoverBaseProps {
  options: ColumnListEntry[];
  value?: string[] | boolean;
  onChange?: (value: string[] | boolean) => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-padding: 0;
  --popover-width: max-content;
  --popover-right: auto;
  --popover-left: auto;
  --popover-top: auto;

  position: static;
`;

const List = styled.div`
  display: grid;
  grid-gap: 12px;
  align-items: start;
  width: 200px;
  padding: 16px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.foreground};
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

export const PopoverColumns: FC<PopoverColumnsProps> = ({ value, options, onChange, ...props }) => {
  const isActive = useCallback(
    (current: string[] | boolean | undefined, currentValue: string) => {
      return current === true || (Array.isArray(current) && current.includes(currentValue));
    },
    [options]
  );

  const handleToggleChange = useCallback(
    (currentValue: string, checked: boolean) => {
      if (onChange) {
        let active = value;

        if (typeof active === 'boolean' || typeof active === 'undefined') {
          active = active
            ? options.map((entry: ColumnListEntry) => {
                return entry.id;
              })
            : [];
        }

        if (Array.isArray(active)) {
          if (checked) {
            active = [...active, currentValue];
          } else if (!checked && active.includes(currentValue)) {
            active = active.filter((e) => e !== currentValue);
          }
        }

        onChange(active);
      }
    },
    [onChange, value, options]
  );

  return (
    <Wrapper {...props}>
      <Title>Add Parameters</Title>
      <List>
        {options.map((entry: ColumnListEntry, index: number) => (
          <Checkbox
            key={index}
            isChecked={isActive(value, entry.id)}
            onCheckedChange={(checked) => handleToggleChange(entry.id, checked)}
            label={entry.label}
          />
        ))}
      </List>
    </Wrapper>
  );
};
