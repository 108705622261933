import React, { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Chip, Fade, formatNumber, Icon, Tooltip, CurrenciesUSD } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader } from '~/components';
import { LogoLoading } from '~/elements';
import { useFormErrorHandler } from '~/hooks/useFormErrorHandler';
import { useCreateACHTransfer, useCreateWireTransfer } from '~/hooks/useTransfers';
import { useGetTransferTemplate, useUpdateTransferTemplate } from '~/hooks/useTransferTemplates';
import { CreateAchTransfer, CreateWireTransfer } from '~/repositories';
import {
  AchTransferTemplateResponse,
  TransferTemplateResponse,
  TransferTemplateType,
  WireTransferTemplateResponse,
} from '~/repositories/Transfer/TransferTemplateRepository';
import { useNotificationStore } from '~/stores/Notification';
import { ApiError } from '~/typings/API';
import { formatString, getDateTimeString } from '~/util';

import { AmountCell } from './_partial/TransferList/Cell/Amount';
import { DropdownCell } from './_partial/TransferList/Cell/Dropdown';
import { BankAccountIdDropdownCell } from './_partial/TransferList/Cell/DropdownCell/BankAccountId';
import { CounterpartyIdDropdownCell } from './_partial/TransferList/Cell/DropdownCell/CounterpartyId';
import { BankAccountIdTextCell } from './_partial/TransferList/Cell/Text/BankAccountId';
import { CounterpartyIdTextCell } from './_partial/TransferList/Cell/Text/CounterpartyId';
import { TextareaCell } from './_partial/TransferList/Cell/Textarea';
import { Table, TableColumn } from './_partial/TransferList/Table';

type TransferState = { type: 'idle' } | { type: 'loading' } | { type: 'success' } | { type: 'error'; message: string };

interface ReviewFormValues extends Omit<TransferTemplateResponse, 'achTransfers' | 'wireTransfers'> {
  achTransfers: Partial<AchTransferTemplateResponse>[];
  wireTransfers: Partial<WireTransferTemplateResponse>[];
}

interface Params {
  id: string;
}

interface LocationState {
  defaultFormValues?: ReviewFormValues;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  padding: 24px;
  display: grid;
  gap: 24px;
`;

const Logo = styled(LogoLoading)`
  top: calc(50vh - 130px);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TextCell = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const Amount = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.foreground};
`;

const Idle = styled(Icon.CirclePendingTwo)`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Loading = styled(Icon.Loading)`
  --icon-color: ${({ theme }) => theme.primary.background};
`;

const Success = styled(Icon.CircleCheck)`
  --icon-color: ${({ theme }) => theme.success.background};
`;

const ErrorTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Error = styled(Icon.CircleCross)`
  --icon-color: ${({ theme }) => theme.danger.background};
`;

const State = styled.div<{ $state: TransferState }>`
  --icon-size: 20px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  width: 100%;
  height: 40px;
  position: relative;
  box-sizing: border-box;
  padding-left: 40px;
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum';

  ${Idle},
  ${Loading},
  ${Success},
  ${ErrorTooltip} {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    transition: opacity 0.3s ease;
  }

  ${Idle} {
    opacity: ${({ $state }) => ($state.type === 'idle' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'idle' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'idle' ? 'auto' : 'none')};
  }

  ${Loading} {
    opacity: ${({ $state }) => ($state.type === 'loading' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'loading' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'loading' ? 'auto' : 'none')};
  }

  ${Success} {
    opacity: ${({ $state }) => ($state.type === 'success' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'success' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'success' ? 'auto' : 'none')};
  }

  ${ErrorTooltip} {
    opacity: ${({ $state }) => ($state.type === 'error' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'error' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'error' ? 'auto' : 'none')};
  }
`;

const CounterCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
`;

const Counter = styled(Chip)`
  --chip-font-size: 11px;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: center;
  min-width: 24px;
`;

const achColumns: TableColumn<Partial<AchTransferTemplateResponse> & { id: string; state: TransferState }>[] = [
  {
    accessor: 'id',
    Header: '',
    Cell: ({ index }) => (
      <CounterCell>
        <Counter>{index + 1}</Counter>
      </CounterCell>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'state',
    Header: '',
    Cell: ({ value }) => (
      <State $state={value}>
        <Idle />
        <Loading />
        <Success />
        <ErrorTooltip content={value.type === 'error' ? value.message : 'Error'}>
          <Error />
        </ErrorTooltip>
      </State>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <AmountCell
          id="amount"
          array="achTransfers"
          index={index}
          currencyList={CurrenciesUSD}
          fixedCurrencyCode="USD"
        />
      ) : (
        <TextCell>
          <Amount>{formatNumber(value)}</Amount>
        </TextCell>
      );
    },
    customWidth: { fixed: 112 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'counterpartyId',
    Header: 'Counterparty',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <CounterpartyIdDropdownCell index={index} array="achTransfers" value={value} />
      ) : row.counterpartyDescription ? (
        <TextCell>{row.counterpartyDescription}</TextCell>
      ) : (
        <CounterpartyIdTextCell id={value} />
      );
    },
    customWidth: { fixed: 200 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'type',
    Header: 'Type',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="type"
          array="achTransfers"
          index={index}
          active={value}
          options={[
            { label: 'Debit', value: 'debit' },
            { label: 'Credit', value: 'credit' },
          ]}
        />
      ) : (
        <TextCell>
          <Chip>{formatString(value)}</Chip>
        </TextCell>
      );
    },
    customWidth: { fixed: 120 },
    isDefault: true,
  },
  {
    accessor: 'bankAccountId',
    Header: 'Bank Account',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <BankAccountIdDropdownCell index={index} array="achTransfers" value={value} />
      ) : row.bankAccountDescription ? (
        <TextCell>{row.bankAccountDescription}</TextCell>
      ) : (
        <BankAccountIdTextCell id={value} />
      );
    },
    customWidth: { fr: 2, min: 180 },
    isDefault: true,
  },
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="description" array="achTransfers" index={index} placeholder="Description" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: { fr: 2, min: 180 },
    isDefault: true,
  },
  {
    accessor: 'entryClassCode',
    Header: 'Entry Class Code',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="entryClassCode"
          array="achTransfers"
          index={index}
          placeholder="Please select"
          active={value}
          options={['CCD', 'CIE', 'CTX', 'PPD', 'TEL', 'WEB'].map((code: string) => ({
            label: code,
            value: code,
          }))}
        />
      ) : (
        <TextCell>
          <Chip>{value}</Chip>
        </TextCell>
      );
    },
    customWidth: {
      fr: 1,
      min: 180,
    },
    isDefault: true,
  },
  {
    accessor: 'allowOverdraft',
    Header: 'Allow Overdraft',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="allowOverdraft"
          array="achTransfers"
          index={index}
          placeholder="Please select"
          active={value}
          options={['Allow', 'Deny'].map((code: string) => ({
            label: code,
            value: code,
          }))}
        />
      ) : (
        <TextCell>
          <Chip>{value ? 'Allow' : 'Deny'}</Chip>
        </TextCell>
      );
    },
    customWidth: {
      fixed: 144,
    },
    isHidden: true,
  },
  {
    accessor: 'companyName',
    Header: 'Company Name',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="companyName" array="achTransfers" index={index} placeholder="Company Name" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
  {
    accessor: 'companyDiscretionaryData',
    Header: 'Company Discretionary',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell
          id="companyDiscretionaryData"
          array="achTransfers"
          index={index}
          placeholder="Company Discretionary"
        />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 200,
    },
    isHidden: true,
  },
  {
    accessor: 'companyEntryDescription',
    Header: 'Company Entry Info',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell
          id="companyEntryDescription"
          array="achTransfers"
          index={index}
          placeholder="Company Entry Info"
        />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 180,
    },
    isHidden: true,
  },
  {
    accessor: 'paymentRelatedInfo',
    Header: 'Payment Related Info',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="paymentRelatedInfo" array="achTransfers" index={index} placeholder="Payment Related Info" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 180,
    },
    isHidden: true,
  },
  {
    accessor: 'receiverName',
    Header: 'Receiver Name',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="receiverName" array="achTransfers" index={index} placeholder="Receiver Name" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
  {
    accessor: 'receiverId',
    Header: 'Receiver ID',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="receiverId" array="achTransfers" index={index} placeholder="Receiver ID" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
];

const wireColumns: TableColumn<Partial<WireTransferTemplateResponse> & { id: string; state: TransferState }>[] = [
  {
    accessor: 'id',
    Header: '',
    Cell: ({ index }) => (
      <CounterCell>
        <Counter>{index + 1}</Counter>
      </CounterCell>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'state',
    Header: '',
    Cell: ({ value }) => (
      <State $state={value}>
        <Idle />
        <Loading />
        <Success />
        <ErrorTooltip content={value.type === 'error' ? value.message : 'Error'}>
          <Error />
        </ErrorTooltip>
      </State>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <AmountCell
          id="amount"
          array="wireTransfers"
          index={index}
          currencyList={CurrenciesUSD}
          fixedCurrencyCode="USD"
        />
      ) : (
        <TextCell>
          <Amount>{formatNumber(value)}</Amount>
        </TextCell>
      );
    },
    customWidth: { fixed: 112 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'counterpartyId',
    Header: 'Counterparty',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <CounterpartyIdDropdownCell index={index} array="wireTransfers" value={value} />
      ) : row.counterpartyDescription ? (
        <TextCell>{row.counterpartyDescription}</TextCell>
      ) : (
        <CounterpartyIdTextCell id={value} />
      );
    },
    customWidth: { fixed: 240 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'bankAccountId',
    Header: 'Bank Account',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <BankAccountIdDropdownCell index={index} array="wireTransfers" value={value} />
      ) : row.bankAccountDescription ? (
        <TextCell>{row.bankAccountDescription}</TextCell>
      ) : (
        <BankAccountIdTextCell id={value} />
      );
    },
    customWidth: { min: 200 },
    isDefault: true,
  },
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="description" array="wireTransfers" index={index} placeholder="Description" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: { min: 180 },
    isDefault: true,
  },
  {
    accessor: 'allowOverdraft',
    Header: 'Allow Overdraft',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="allowOverdraft"
          array="wireTransfers"
          index={index}
          placeholder="Please select"
          active={value}
          options={['Allow', 'Deny'].map((code: string) => ({
            label: code,
            value: code,
          }))}
        />
      ) : (
        <TextCell>
          <Chip>{value ? 'Allow' : 'Deny'}</Chip>
        </TextCell>
      );
    },
    customWidth: {
      fixed: 144,
    },
    isHidden: true,
  },
];

export const PageTransferTemplatesReview: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const location = useLocation();
  const locationState = location.state as LocationState;
  const { handleFormErrors } = useFormErrorHandler();

  const methods = useForm<ReviewFormValues>({
    defaultValues: locationState?.defaultFormValues ?? {
      achTransfers: [],
      wireTransfers: [],
    },
  });

  const { handleSubmit, reset, control, watch } = methods;

  const { fields: achTransfers, update: updateAchTransfer } = useFieldArray({
    control,
    name: 'achTransfers',
  });

  const { fields: wireTransfers, update: updateWireTransfer } = useFieldArray({
    control,
    name: 'wireTransfers',
  });

  const [achColumnEntries, setAchColumnEntries] =
    useState<TableColumn<Partial<AchTransferTemplateResponse> & { id: string; state: TransferState }>[]>(achColumns);
  const [wireColumnEntries, setWireColumnEntries] =
    useState<TableColumn<Partial<WireTransferTemplateResponse> & { id: string; state: TransferState }>[]>(wireColumns);

  const [achTransferStates, setAchTransferStates] = useState<TransferState[]>([]);
  const [wireTransferStates, setWireTransferStates] = useState<TransferState[]>([]);

  useEffect(() => {
    setAchTransferStates((prev) => {
      const len = achTransfers.length;
      if (prev.length === len) return prev;
      return Array.from({ length: len }, () => ({ type: 'idle' }));
    });
  }, [achTransfers]);

  useEffect(() => {
    setWireTransferStates((prev) => {
      const len = wireTransfers.length;
      if (prev.length === len) return prev;
      return Array.from({ length: len }, () => ({ type: 'idle' }));
    });
  }, [wireTransfers]);

  const { isLoading, createRequest: getTransferTemplate } = useGetTransferTemplate({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });
      navigate(`${ROUTE.TRANSFERS}/templates`);
    },
    shouldNotLoad: !!locationState?.defaultFormValues,
  });

  const { createRequest: updateTransferTemplate } = useUpdateTransferTemplate();
  const { createRequest: createACHTransfer } = useCreateACHTransfer();
  const { createRequest: createWireTransfer } = useCreateWireTransfer();

  const setAchTransferState = useCallback((index: number, state: TransferState) => {
    setAchTransferStates((prev) => prev.map((s, i) => (i === index ? state : s)));
  }, []);

  const setWireTransferState = useCallback((index: number, state: TransferState) => {
    setWireTransferStates((prev) => prev.map((s, i) => (i === index ? state : s)));
  }, []);

  const handleAchTransferRow = useCallback(
    async (
      achTransfer: Partial<AchTransferTemplateResponse>,
      index: number,
      currentState: TransferState,
      updatedTransfer?: AchTransferTemplateResponse
    ) => {
      if (currentState.type === 'success') {
        return false;
      }
      setAchTransferState(index, { type: 'loading' });
      try {
        const formValues = watch(`achTransfers.${index}`);
        const newTransfer = {
          ...achTransfer,
          ...formValues,
          currencyCode: 'USD',
        };
        delete newTransfer.transferTemplateRowId;
        delete newTransfer.bankAccountDescription;
        delete newTransfer.counterpartyDescription;

        await createACHTransfer(newTransfer as CreateAchTransfer);
        if (updatedTransfer) {
          updateAchTransfer(index, {
            ...formValues,
            bankAccountDescription: updatedTransfer.bankAccountDescription,
            counterpartyDescription: updatedTransfer.counterpartyDescription,
          });
        }
        setAchTransferState(index, { type: 'success' });
        return true;
      } catch (e) {
        const error = e as ApiError & { details?: { reason?: string } };
        setAchTransferState(index, {
          type: 'error',
          message: error?.message ? `${error.message} ${error.details?.reason ?? ''}` : 'Unknown error occurred',
        });
        addDangerNotification({
          content: (
            <>
              <span className="underline">{index + 1}. Transfer</span> {error?.message ?? 'Unknown error occurred'}
            </>
          ),
          timeout: 8000,
        });
        return false;
      }
    },
    [methods, setAchTransferState, updateAchTransfer]
  );

  const handleWireTransferRow = useCallback(
    async (
      wireTransfer: Partial<WireTransferTemplateResponse>,
      index: number,
      currentState: TransferState,
      updatedTransfer?: WireTransferTemplateResponse
    ) => {
      if (currentState.type === 'success') {
        return false;
      }
      setWireTransferState(index, { type: 'loading' });
      try {
        const formValues = watch(`wireTransfers.${index}`);
        const newTransfer = {
          ...wireTransfer,
          ...formValues,
          currencyCode: 'USD',
        };
        delete newTransfer.transferTemplateRowId;
        delete newTransfer.bankAccountDescription;
        delete newTransfer.counterpartyDescription;

        const wire = await createWireTransfer(newTransfer as unknown as CreateWireTransfer);
        if (!wire) {
          return false;
        }
        if (updatedTransfer) {
          updateWireTransfer(index, {
            ...formValues,
            bankAccountDescription: updatedTransfer.bankAccountDescription,
            counterpartyDescription: updatedTransfer.counterpartyDescription,
          });
        }
        setWireTransferState(index, { type: 'success' });
        return true;
      } catch (e) {
        const error = e as ApiError & { details?: { reason?: string } };
        setWireTransferState(index, {
          type: 'error',
          message: error?.message ? `${error.message} ${error.details?.reason ?? ''}` : 'Unknown error occurred',
        });
        addDangerNotification({
          content: (
            <>
              <span className="underline">{index + 1}. Transfer</span> {error?.message ?? 'Unknown error occurred'}
            </>
          ),
          timeout: 8000,
        });
        return false;
      }
    },
    [methods, setWireTransferState, updateWireTransfer]
  );

  const handleSendTransfersClick = useCallback(
    async (data: ReviewFormValues) => {
      if (isSubmitLoading) {
        return;
      }
      setIsSubmitLoading(true);
      let successfulTransfers = 0;
      let updatedAchTransfers: AchTransferTemplateResponse[] = [];
      let updatedWireTransfers: WireTransferTemplateResponse[] = [];

      const achHasError = achTransferStates.some((s) => s.type === 'error');
      const wireHasError = wireTransferStates.some((s) => s.type === 'error');

      if (achHasError || wireHasError) {
        if (data.templateType === TransferTemplateType.ACH) {
          const updateAchTransfersReq = [
            ...data.achTransfers.map(({ counterpartyDescription: _, bankAccountDescription: __, ...t }) => ({ ...t })),
          ];
          const updatedTemplate = await updateTransferTemplate({
            transferTemplateId: id,
            achTransfers: updateAchTransfersReq,
          });
          if (!updatedTemplate) {
            setIsSubmitLoading(false);
            return;
          }
          updatedAchTransfers = updatedTemplate.achTransfers ?? [];
          updatedAchTransfers.forEach((ut, i) => {
            updateAchTransfer(i, {
              ...data.achTransfers[i],
              bankAccountDescription: ut.bankAccountDescription,
              counterpartyDescription: ut.counterpartyDescription,
            });
          });
        }
        if (data.templateType === TransferTemplateType.Wire) {
          const updateWireTransfersReq = [
            ...data.wireTransfers.map(({ counterpartyDescription: _, bankAccountDescription: __, ...t }) => ({ ...t })),
          ];
          const updatedTemplate = await updateTransferTemplate({
            transferTemplateId: id,
            wireTransfers: updateWireTransfersReq,
          });
          if (!updatedTemplate) {
            setIsSubmitLoading(false);
            return;
          }
          updatedWireTransfers = updatedTemplate.wireTransfers ?? [];
          updatedWireTransfers.forEach((ut, i) => {
            updateWireTransfer(i, {
              ...data.wireTransfers[i],
              bankAccountDescription: ut.bankAccountDescription,
              counterpartyDescription: ut.counterpartyDescription,
            });
          });
        }
      }

      if (data.templateType === TransferTemplateType.ACH) {
        for (let i = 0; i < data.achTransfers.length; i++) {
          const currentState = achTransferStates[i] ?? { type: 'idle' };
          const success = await handleAchTransferRow(data.achTransfers[i], i, currentState, updatedAchTransfers[i]);
          if (success) {
            successfulTransfers++;
          }
        }
      }

      if (data.templateType === TransferTemplateType.Wire) {
        for (let i = 0; i < data.wireTransfers.length; i++) {
          const currentState = wireTransferStates[i] ?? { type: 'idle' };
          const success = await handleWireTransferRow(data.wireTransfers[i], i, currentState, updatedWireTransfers[i]);
          if (success) {
            successfulTransfers++;
          }
        }
      }

      updateTransferTemplate({
        transferTemplateId: id,
        usageCount: (watch('usageCount') ?? 0) + 1,
        lastUsedAt: getDateTimeString(new Date()),
      }).finally(() => {
        setIsSubmitLoading(false);
        setIsDone(true);
        if (successfulTransfers > 0) {
          addSuccessNotification({
            content: (
              <>
                <strong>{successfulTransfers}</strong> Transfers from <strong>"{data.description}"</strong> successfully
                sent
              </>
            ),
            timeout: 8000,
          });
        }
      });
    },
    [
      id,
      watch,
      isSubmitLoading,
      achTransferStates,
      wireTransferStates,
      handleAchTransferRow,
      handleWireTransferRow,
      updateAchTransfer,
      updateWireTransfer,
      addSuccessNotification,
      updateTransferTemplate,
    ]
  );

  const handleEditClick = useCallback(() => {
    navigate(`${ROUTE.TRANSFERS}/templates/edit/${id}`, { state: { defaultFormValues: watch() } });
  }, [id, navigate]);

  const handleCancelClick = useCallback(() => {
    navigate(`${ROUTE.TRANSFERS}/templates`, { state: { defaultFormValues: null } });
  }, [navigate]);

  const handleViewTransfersClick = useCallback(() => {
    navigate(ROUTE.TRANSFERS, { state: { defaultFormValues: null } });
  }, [navigate]);

  const onSuccess = useCallback(
    (data: ReviewFormValues) => {
      handleSendTransfersClick(data);
    },
    [handleSendTransfersClick]
  );

  const onError: SubmitHandler<FieldValues> = useCallback(
    (errors) => {
      handleFormErrors({ errors });
    },
    [handleFormErrors]
  );

  useEffect(() => {
    if (id && !locationState?.defaultFormValues) {
      getTransferTemplate({ transferTemplateId: id }).then((data) => {
        if (!data) {
          return;
        }

        const newTransferTemplate = { ...data } as Partial<ReviewFormValues>;

        delete newTransferTemplate.version;

        reset(newTransferTemplate);
      });
    }
    if (locationState?.defaultFormValues) {
      reset(locationState.defaultFormValues);
    }
  }, [id, locationState?.defaultFormValues]);

  useEffect(() => {
    if (watch('templateType') === TransferTemplateType.ACH) {
      setAchColumnEntries((entries) =>
        entries.map((entry) => ({
          ...entry,
          isHidden: entry.isDefault ? false : !(watch('visibleColumns') ?? []).includes(entry.accessor),
        }))
      );
    }
    if (watch('templateType') === TransferTemplateType.Wire) {
      setWireColumnEntries((entries) =>
        entries.map((entry) => ({
          ...entry,
          isHidden: entry.isDefault ? false : !(watch('visibleColumns') ?? []).includes(entry.accessor),
        }))
      );
    }
  }, [watch('visibleColumns')]);

  return (
    <FormProvider {...methods}>
      <Container onSubmit={handleSubmit(onSuccess, onError)}>
        <PageHeader text="Review Transfer Template">
          <Button
            type="button"
            variant="secondary"
            size="small"
            onClick={handleCancelClick}
            isDisabled={isSubmitLoading}
          >
            Cancel
          </Button>
          {isDone ? (
            <ButtonGroup>
              <Button
                type="button"
                variant="secondary"
                size="small"
                onClick={handleEditClick}
                isDisabled={isSubmitLoading}
              >
                Edit
              </Button>
              {(achTransferStates ?? []).some((s) => s.type === 'error') ||
              (wireTransferStates ?? []).some((s) => s.type === 'error') ? (
                <Button type="submit" icon={<Icon.Send />} size="small" isLoading={isSubmitLoading}>
                  Retry
                </Button>
              ) : (
                <Button type="button" size="small" onClick={handleViewTransfersClick}>
                  View Transfers
                </Button>
              )}
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button
                type="button"
                variant="secondary"
                size="small"
                onClick={handleEditClick}
                isDisabled={isSubmitLoading}
              >
                Edit
              </Button>
              <Button type="submit" icon={<Icon.Send />} size="small" isLoading={isSubmitLoading}>
                Send Transfers
              </Button>
            </ButtonGroup>
          )}
        </PageHeader>
        <Fade show={!!isLoading && !locationState?.defaultFormValues}>
          <Logo />
        </Fade>
        <Fade show={!isLoading || !!locationState?.defaultFormValues}>
          <Wrapper>
            {watch('templateType') === TransferTemplateType.ACH && (
              <Table<Partial<AchTransferTemplateResponse> & { id: string; state: TransferState }>
                columns={achColumnEntries}
                data={achTransfers.map((transfer, i) => ({
                  ...transfer,
                  id: transfer.id ?? String(i),
                  state: achTransferStates[i] ?? { type: 'idle' },
                }))}
              />
            )}
            {watch('templateType') === TransferTemplateType.Wire && (
              <Table<Partial<WireTransferTemplateResponse> & { id: string; state: TransferState }>
                columns={wireColumnEntries}
                data={wireTransfers.map((transfer, i) => ({
                  ...transfer,
                  id: transfer.id ?? String(i),
                  state: wireTransferStates[i] ?? { type: 'idle' },
                }))}
              />
            )}
            <ButtonWrapper>
              <Button
                type="button"
                size="small"
                variant="secondary"
                onClick={handleCancelClick}
                isDisabled={isSubmitLoading}
              >
                Cancel
              </Button>
              {isDone ? (
                <ButtonGroup>
                  <Button
                    type="button"
                    variant="secondary"
                    size="small"
                    onClick={handleEditClick}
                    isDisabled={isSubmitLoading}
                  >
                    Edit
                  </Button>
                  {achTransferStates.some((s) => s.type === 'error') ||
                  wireTransferStates.some((s) => s.type === 'error') ? (
                    <Button type="submit" icon={<Icon.Send />} size="small" isLoading={isSubmitLoading}>
                      Retry
                    </Button>
                  ) : (
                    <Button type="button" size="small" onClick={handleViewTransfersClick}>
                      View Transfers
                    </Button>
                  )}
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  <Button
                    type="button"
                    variant="secondary"
                    size="small"
                    onClick={handleEditClick}
                    isDisabled={isSubmitLoading}
                  >
                    Edit
                  </Button>
                  <Button type="submit" icon={<Icon.Send />} size="small" isLoading={isSubmitLoading}>
                    Send Transfers
                  </Button>
                </ButtonGroup>
              )}
            </ButtonWrapper>
          </Wrapper>
        </Fade>
      </Container>
    </FormProvider>
  );
};
