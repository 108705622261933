import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { ParagraphLighter, SmallHeadline } from '~/styles';

export interface EmptyStateProps {
  headline: string;
  text: string;
  className?: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 280px;
  margin: 0 auto;
  padding: 10vh 0;
`;

const Text = styled.div`
  display: grid;
  grid-gap: 4px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;

export const EmptyState: React.FC<PropsWithChildren<EmptyStateProps>> = (props) => {
  return (
    <Wrapper className={props.className}>
      <Text>
        <SmallHeadline>{props.headline}</SmallHeadline>
        <ParagraphLighter>{props.text}</ParagraphLighter>
      </Text>
      <Buttons>{props.children}</Buttons>
    </Wrapper>
  );
};
