import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useFormContext, useFieldArray, FieldArrayWithId } from 'react-hook-form';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { Button, CurrenciesUSD, Icon, Portal, PortalPlacement } from '@column/column-ui-kit';

import {
  AchTransferTemplate,
  TransferTemplateResponse,
  TransferTemplateType,
  WireTransferTemplate,
} from '~/repositories/Transfer/TransferTemplateRepository';
import { useAlertStore } from '~/stores/Alert';

import { AmountCell } from './TransferList/Cell/Amount';
import { DropdownCell } from './TransferList/Cell/Dropdown';
import { BankAccountIdDropdownCell } from './TransferList/Cell/DropdownCell/BankAccountId';
import { CounterpartyIdDropdownCell } from './TransferList/Cell/DropdownCell/CounterpartyId';
import { TextareaCell } from './TransferList/Cell/Textarea';
import { ColumnListEntry, PopoverColumns } from './TransferList/Popover/Columns';
import { Table, TableColumn, TableProps } from './TransferList/Table';

interface TransferListProps {
  type: TransferTemplateType;
}

const TableWrapper = styled.div`
  display: grid;
  gap: 8px;
`;

const AddButton = styled(Button)`
  --button-scale: 1;

  &:after {
    background: ${({ theme }) => theme.gray.blendToBackground(25)};
  }

  &:hover {
    &:after {
      background: ${({ theme }) => theme.gray.blendToBackground(50)};
    }
  }
`;

const Asterix = styled.span`
  color: ${({ theme }) => theme.danger.background};
  margin-left: 4px;
`;

const achColumns: TableColumn<Partial<AchTransferTemplate> & { id: string }>[] = [
  {
    accessor: 'amount',
    Header: (
      <>
        Amount
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index }) => (
      <AmountCell id="amount" array="achTransfers" index={index} currencyList={CurrenciesUSD} fixedCurrencyCode="USD" />
    ),
    customWidth: {
      fixed: 112,
    },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'counterpartyId',
    Header: (
      <>
        Counterparty
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index, value }) => <CounterpartyIdDropdownCell index={index} array="achTransfers" value={value} />,
    customWidth: {
      fixed: 180,
    },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'type',
    Header: (
      <>
        Type
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index, value }) => (
      <DropdownCell
        id="type"
        array="achTransfers"
        index={index}
        active={value}
        options={[
          { label: 'Debit', value: 'DEBIT' },
          { label: 'Credit', value: 'CREDIT' },
        ]}
      />
    ),
    customWidth: {
      fixed: 120,
    },
    isDefault: true,
  },
  {
    accessor: 'bankAccountId',
    Header: (
      <>
        Bank Account
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index, value }) => <BankAccountIdDropdownCell index={index} array="achTransfers" value={value} />,
    customWidth: {
      fr: 2,
      min: 200,
    },
    isDefault: true,
  },
  {
    accessor: 'description',
    Header: (
      <>
        Description
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index }) => <TextareaCell id="description" array="achTransfers" index={index} placeholder="Description" />,
    customWidth: {
      fr: 2,
      min: 180,
    },
    isDefault: true,
  },
  {
    accessor: 'entryClassCode',
    Header: (
      <>
        Entry Class Code
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ row, index }) => (
      <DropdownCell
        id="entryClassCode"
        array="achTransfers"
        index={index}
        placeholder="Please select"
        active={row.entryClassCode ?? 'CCD'}
        options={['CCD', 'CIE', 'CTX', 'PPD', 'TEL', 'WEB'].map((code: string) => ({
          label: code,
          value: code,
        }))}
      />
    ),
    customWidth: {
      fr: 1,
      min: 160,
    },
    isDefault: true,
  },
  {
    accessor: 'allowOverdraft',
    Header: 'Allow Overdraft',
    Cell: ({ row, index }) => (
      <DropdownCell
        id="allowOverdraft"
        array="achTransfers"
        index={index}
        placeholder="Please select"
        active={row.allowOverdraft}
        options={[
          { label: 'Allow', value: true },
          { label: 'Deny', value: false },
        ]}
      />
    ),
    customWidth: {
      fixed: 144,
    },
    isHidden: true,
  },
  {
    accessor: 'companyName',
    Header: 'Company Name',
    Cell: ({ index }) => (
      <TextareaCell id="companyName" array="achTransfers" index={index} placeholder="Company Name" />
    ),
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
  {
    accessor: 'companyDiscretionaryData',
    Header: 'Company Discretionary',
    Cell: ({ index }) => (
      <TextareaCell
        id="companyDiscretionaryData"
        array="achTransfers"
        index={index}
        placeholder="Company Discretionary Data"
      />
    ),
    customWidth: {
      min: 200,
    },
    isHidden: true,
  },
  {
    accessor: 'companyEntryDescription',
    Header: 'Company Entry Info',
    Cell: ({ index }) => (
      <TextareaCell
        id="companyEntryDescription"
        array="achTransfers"
        index={index}
        placeholder="Company Entry Description"
      />
    ),
    customWidth: {
      min: 180,
    },
    isHidden: true,
  },
  {
    accessor: 'paymentRelatedInfo',
    Header: 'Payment Related Info',
    Cell: ({ index }) => (
      <TextareaCell id="paymentRelatedInfo" array="achTransfers" index={index} placeholder="Payment Related Info" />
    ),
    customWidth: {
      min: 180,
    },
    isHidden: true,
  },
  {
    accessor: 'receiverName',
    Header: 'Receiver Name',
    Cell: ({ index }) => (
      <TextareaCell id="receiverName" array="achTransfers" index={index} placeholder="Receiver Name" />
    ),
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
  {
    accessor: 'receiverId',
    Header: 'Receiver ID',
    Cell: ({ index }) => <TextareaCell id="receiverId" array="achTransfers" index={index} placeholder="Receiver ID" />,
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
];

const wireColumns: TableColumn<Partial<WireTransferTemplate> & { id: string }>[] = [
  {
    accessor: 'amount',
    Header: (
      <>
        Amount
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index }) => (
      <AmountCell
        id="amount"
        array="wireTransfers"
        index={index}
        currencyList={CurrenciesUSD}
        fixedCurrencyCode="USD"
      />
    ),
    customWidth: {
      fixed: 112,
    },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'counterpartyId',
    Header: (
      <>
        Counterparty
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index, value }) => <CounterpartyIdDropdownCell index={index} array="wireTransfers" value={value} />,
    customWidth: {
      fixed: 180,
    },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'bankAccountId',
    Header: (
      <>
        Bank Account
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index, value }) => <BankAccountIdDropdownCell index={index} array="wireTransfers" value={value} />,
    customWidth: {
      fr: 2,
      min: 200,
    },
    isDefault: true,
  },
  {
    accessor: 'description',
    Header: (
      <>
        Description
        <Asterix>*</Asterix>
      </>
    ),
    Cell: ({ index }) => (
      <TextareaCell id="description" array="wireTransfers" index={index} placeholder="Description" />
    ),
    customWidth: {
      fr: 2,
      min: 180,
    },
    isDefault: true,
  },
  {
    accessor: 'allowOverdraft',
    Header: 'Allow Overdraft',
    Cell: ({ row, index }) => (
      <DropdownCell
        id="allowOverdraft"
        array="wireTransfers"
        index={index}
        placeholder="Please select"
        active={row.allowOverdraft}
        options={[
          { label: 'Allow', value: true },
          { label: 'Deny', value: false },
        ]}
      />
    ),
    customWidth: {
      fixed: 148,
    },
    isHidden: true,
  },
];

const HeaderDropdown = styled.div`
  position: relative;
`;

const Dots = styled(Icon.Dots)<{ $isOpen: boolean }>`
  --icon-color: ${({ theme, $isOpen }) => theme.secondary.blendToBackground($isOpen ? 1000 : 800)};

  padding: 0;
  transition:
    transform 0.2s,
    color 0.2s;

  &:hover {
    --icon-color: ${({ theme }) => theme.secondary.background};
  }

  transform: rotate(${({ $isOpen }) => ($isOpen ? 90 : 0)}deg);
`;

export const TransferList: FC<TransferListProps> = ({ type }) => {
  const openAlert = useAlertStore((state) => state.openAlert);
  const referenceRef = useRef<HTMLButtonElement>(null);
  const [isHeaderPopoverOpen, setIsHeaderPopoverOpen] = useState<boolean>(false);
  const [headerPlacement, setHeaderPlacement] = useState<PortalPlacement>('left-start');

  const [achColumnEntries, setAchColumnEntries] =
    useState<TableColumn<Partial<AchTransferTemplate> & { id: string }>[]>(achColumns);
  const [wireColumnEntries, setWireColumnEntries] =
    useState<TableColumn<Partial<WireTransferTemplate> & { id: string }>[]>(wireColumns);

  const { control, watch, setValue } = useFormContext<TransferTemplateResponse>();

  const {
    fields: achTransfers,
    append: achAppend,
    remove: achRemove,
  } = useFieldArray({
    control,
    name: 'achTransfers',
  });

  const {
    fields: wireTransfers,
    append: wireAppend,
    remove: wireRemove,
  } = useFieldArray({
    control,
    name: 'wireTransfers',
  });

  useEffect(() => {
    if (TransferTemplateType.ACH === type) {
      setAchColumnEntries((entries) =>
        entries.map((entry) => ({
          ...entry,
          isHidden: entry.isDefault ? false : !(watch('visibleColumns') ?? []).includes(entry.accessor),
        }))
      );
    }

    if (TransferTemplateType.Wire === type) {
      setWireColumnEntries((entries) =>
        entries.map((entry) => ({
          ...entry,
          isHidden: entry.isDefault ? false : !(watch('visibleColumns') ?? []).includes(entry.accessor),
        }))
      );
    }
  }, [type]);

  useEffect(() => {
    setValue(
      'visibleColumns',
      TransferTemplateType.ACH === type
        ? achColumnEntries.filter((column) => !column.isHidden).map((column) => column.accessor)
        : wireColumnEntries.filter((column) => !column.isHidden).map((column) => column.accessor)
    );
  }, [TransferTemplateType, achColumnEntries, wireColumnEntries]);

  const handleAddClick = useCallback(() => {
    if (type === TransferTemplateType.ACH) {
      achAppend({
        transferTemplateRowId: uuid(),
        amount: undefined,
        counterpartyId: undefined,
        type: watch('achDefaultParameters.type') ?? 'DEBIT',
        bankAccountId: watch('achDefaultParameters.bankAccountId'),
        description: undefined,
        entryClassCode: watch('achDefaultParameters.entryClassCode') ?? 'CCD',
      } as Partial<AchTransferTemplate>);
    }
    if (type === TransferTemplateType.Wire) {
      wireAppend({
        transferTemplateRowId: uuid(),
        amount: undefined,
        counterpartyId: undefined,
        bankAccountId: watch('wireDefaultParameters.bankAccountId'),
        description: watch('wireDefaultParameters.description'),
      } as Partial<WireTransferTemplate>);
    }
  }, [type, watch('achDefaultParameters'), watch('wireDefaultParameters')]);

  const handleDeleteClick = useCallback(
    (id?: string) => {
      if (!id) return;

      openAlert({
        headline: 'Remove transfer?',
        text: 'Are you sure you want to remove this transfer and the related data?',
        submitText: 'Remove',
        size: 'small',
        callback: () => {
          const index =
            type === TransferTemplateType.ACH
              ? achTransfers?.findIndex((transfer) => transfer.transferTemplateRowId === id)
              : wireTransfers?.findIndex((transfer) => transfer.transferTemplateRowId === id);

          if (index === -1) return;

          if (type === TransferTemplateType.ACH) {
            achRemove(index);
          }

          if (type === TransferTemplateType.Wire) {
            wireRemove(index);
          }
        },
      });
    },
    [type, achTransfers, wireTransfers]
  );

  const handleOutClick = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (event.target === referenceRef.current || referenceRef.current?.contains(event.target as HTMLElement)) {
        return;
      }

      if (!isHeaderPopoverOpen) {
        return;
      }

      setIsHeaderPopoverOpen(false);
    },
    [referenceRef, isHeaderPopoverOpen]
  );

  const handleColumnClick = useCallback(() => {
    if (isHeaderPopoverOpen) {
      return;
    }

    flushSync(() => {
      setIsHeaderPopoverOpen((s) => !s);
    });
  }, [isHeaderPopoverOpen]);

  const handleColumnsChange = useCallback(
    (columns: string[] | boolean) => {
      if (typeof columns === 'boolean') {
        return;
      }

      if (TransferTemplateType.ACH === type) {
        setAchColumnEntries((entries) => {
          return entries.map((entry) => {
            if (columns.includes(entry.accessor)) {
              return { ...entry, isHidden: false };
            }

            return { ...entry, isHidden: true };
          });
        });
      }

      if (TransferTemplateType.Wire === type) {
        setWireColumnEntries((entries) => {
          return entries.map((entry) => {
            if (columns.includes(entry.accessor)) {
              return { ...entry, isHidden: false };
            }

            return { ...entry, isHidden: true };
          });
        });
      }
    },
    [type, achColumnEntries, wireColumnEntries]
  );

  const tableProps: Partial<TableProps<FieldArrayWithId<TransferTemplateResponse, 'achTransfers', 'id'>>> = {
    rowAction: (row) => (
      <Button type="button" size="small" variant="muted" onClick={() => handleDeleteClick(row.transferTemplateRowId)}>
        <Icon.Trash />
      </Button>
    ),
    headerAction: (
      <HeaderDropdown>
        <Button
          ref={referenceRef}
          type="button"
          size="small"
          variant="subtle"
          onClick={handleColumnClick}
          icon={<Dots $isOpen={isHeaderPopoverOpen} />}
        />
        <Portal
          reference={referenceRef}
          placement="left-start"
          onPlacementChange={setHeaderPlacement}
          offsetCross={headerPlacement === 'left-start' ? -5 : 5}
          offsetMain={4}
          onOutClick={handleOutClick}
          zIndex={4}
          shouldCalculate={{ is: isHeaderPopoverOpen, timeout: 200 }}
        >
          <PopoverColumns
            options={
              (TransferTemplateType.ACH === type
                ? achColumns
                    .filter((column) => !column.isDefault)
                    .map((column) => ({ id: column.accessor, label: column.Header }))
                : wireColumns
                    .filter((column) => !column.isDefault)
                    .map((column) => ({ id: column.accessor, label: column.Header }))) as ColumnListEntry[]
            }
            value={
              TransferTemplateType.ACH === type
                ? achColumnEntries.filter((column) => !column.isHidden).map((column) => column.accessor)
                : wireColumnEntries.filter((column) => !column.isHidden).map((column) => column.accessor)
            }
            onChange={handleColumnsChange}
            show={isHeaderPopoverOpen}
            onClose={() => setIsHeaderPopoverOpen(false)}
          />
        </Portal>
      </HeaderDropdown>
    ),
  };

  return (
    <TableWrapper>
      {type === TransferTemplateType.ACH && (
        <Table<FieldArrayWithId<TransferTemplateResponse, 'achTransfers', 'id'>>
          columns={achColumnEntries}
          data={achTransfers ?? []}
          {...tableProps}
        />
      )}

      {type === TransferTemplateType.Wire && (
        <Table<FieldArrayWithId<TransferTemplateResponse, 'wireTransfers', 'id'>>
          columns={wireColumnEntries}
          data={wireTransfers ?? []}
          {...tableProps}
        />
      )}

      <AddButton type="button" size="small" fullWidth variant="muted" onClick={handleAddClick}>
        <Icon.Plus />
      </AddButton>
    </TableWrapper>
  );
};
