import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { useCounterparty } from '~/hooks';

interface CounterpartyIdTextCellProps {
  id: string;
}

const TextCell = styled.div<{ $isShow?: boolean }>`
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  position: relative;

  span {
    transition: opacity 0.2s;
    opacity: ${({ $isShow }) => ($isShow ? 1 : 0)};
  }
`;

const Loading = styled(Icon.Loading)<{ $isShow?: boolean }>`
  --icon-size: 16px;
  --icon-color: ${({ theme }) => theme.secondary.background};

  position: absolute;
  left: 50%;
  top: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s;
  opacity: ${({ $isShow }) => ($isShow ? 1 : 0)};
`;

export const CounterpartyIdTextCell: FC<CounterpartyIdTextCellProps> = ({ id }) => {
  const { createRequest: fetchCounterparty } = useCounterparty();

  const [active, setActive] = useState<string>('');

  useEffect(() => {
    fetchCounterparty({ id })
      .then((counterparty) => {
        setActive(counterparty?.description ?? '');
      })
      .catch(() => {
        setActive('-');
      });
  }, [id]);

  return (
    <TextCell $isShow={!!active}>
      <Loading $isShow={!active} />
      <span>{active ?? id}</span>
    </TextCell>
  );
};
