import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Icon, Radio } from '@column/column-ui-kit';

import { Form } from '../../Login';
import {
  Option,
  OptionLabel,
  OptionList,
  RegisterAction,
  RegisterContent,
  RegisterContentGrid,
  StyledCheckbox,
} from '../Questions';
import { FileUpload, NotificationList } from '~/components';
import { LearnMore } from '~/elements';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { GoogleFile, GoogleFileRepository, validDocumentExtensions } from '~/repositories';
import { BusinessPageIdType, DepgraphNodeValues } from '~/repositories/PlatformRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FormElement, FormLabel, Hint, Headline, Paragraph, Line } from '~/styles';
import { helpSidebarContent } from '~/util/helpSidebarContent';

import { FormElementHint } from './Location';
import { Columns, Person } from './Partial/Person';

const ColumnsBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLearnMore = styled(LearnMore)`
  position: absolute;
  top: 12px;
  right: 12px;
  line-height: 20px;
`;

const FormLabelLearnMore = styled(LearnMore)`
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledLine = styled(Line)`
  margin: 24px 0;
`;

const StyledRegisterContentGrid = styled(RegisterContentGrid)`
  padding: 0 0 24px 0;
`;

export const PageRegisterBusinessYourInformation: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addDangerNotification } = useNotificationStore();
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const navigate = useNavigate();
  const useFormMethods = useForm<DepgraphNodeValues>();

  const [accountOpenerTypes, setAccountOpenerTypes] = useState<string[]>([]);
  const [accountOpenerIsControlPerson, setAccountOpenerIsControlPerson] = useState<boolean>(false);
  const [accountOpenerIsBeneficialOwner, setAccountOpenerIsBeneficialOwner] = useState<boolean>(false);
  const [controlPersonIsBeneficialOwner, setControlPersonIsBeneficialOwner] = useState<boolean>(false);

  const optionControlPerson = 'control-person';
  const optionBeneficialOwner = 'beneficial-owner';

  const handleAccountOpenerTypeChange = (optionKey: string) => {
    const newOptions = accountOpenerTypes.includes(optionKey)
      ? accountOpenerTypes.filter((k) => k !== optionKey)
      : [...accountOpenerTypes, optionKey];

    setAccountOpenerTypes(newOptions);
    setAccountOpenerIsControlPerson(newOptions.includes(optionControlPerson));
    setAccountOpenerIsBeneficialOwner(newOptions.includes(optionBeneficialOwner));
  };

  const { handleSubmit, setValue, reset, watch } = useFormMethods;

  const onSuccess = (depgraphNodeValues: DepgraphNodeValues) => {
    if (isLoading) {
      return;
    }

    if (accountOpenerIsControlPerson) {
      depgraphNodeValues.controlPerson = undefined;
    }

    if (accountOpenerTypes.length === 0 && !depgraphNodeValues.bankAccountAuthorizationDocument) {
      addDangerNotification({
        content: 'Please upload a proof documentation',
        display: 'bank-account-authorization-document',
      });
      return;
    }

    if (depgraphNodeValues.accountOpener && !depgraphNodeValues.accountOpener.documentaryProof) {
      addDangerNotification({
        content: 'Please upload account opener ID document proof',
        display: 'accountOpener-id-document-proof',
      });
      return;
    }

    if (depgraphNodeValues.controlPerson && !depgraphNodeValues.controlPerson?.documentaryProof) {
      addDangerNotification({
        content: 'Please upload control person ID document proof',
        display: 'controlPerson-id-document-proof',
      });
      return;
    }

    const idxMissingProof = depgraphNodeValues.beneficialOwners?.findIndex((item) => {
      return !item.documentaryProof;
    });
    if (idxMissingProof !== undefined && idxMissingProof >= 0) {
      addDangerNotification({
        content: 'Please upload document to verify your ID',
        display: `beneficialOwners[${idxMissingProof}]-id-document-proof`,
      });
      return;
    }

    setIsLoading(true);

    currentPlatformState
      ?.updateDepgraphNodeValues({
        accountOpener: depgraphNodeValues.accountOpener,
        entityAccountOpenerIsControlPerson: accountOpenerIsControlPerson,
        entityAccountOpenerIsBeneficialOwner: accountOpenerIsBeneficialOwner,
        controlPerson: depgraphNodeValues.controlPerson,
        entityControlPersonIsBeneficialOwner: controlPersonIsBeneficialOwner,
        beneficialOwners: depgraphNodeValues.beneficialOwners,
        bankAccountAuthorizationDocument: depgraphNodeValues.bankAccountAuthorizationDocument,
        completedPages: [
          ...new Set<BusinessPageIdType>([
            ...(currentPlatformState?.depgraphNodeValues?.completedPages ?? []),
            'your-information',
          ]),
        ],
      })
      .then(() => {
        navigate(ROUTE.REGISTER_BUSINESS_SUBMIT);
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({ currentPlatform: state.currentPlatform, currentUser: state.currentUser }),
        ({ currentPlatform }) => {
          if (isLoading) {
            return;
          }

          reset(currentPlatform?.depgraphNodeValues);

          if (currentPlatform?.depgraphNodeValues?.entityAccountOpenerIsControlPerson) {
            setAccountOpenerTypes([...accountOpenerTypes, optionControlPerson]);
          }
          if (currentPlatform?.depgraphNodeValues?.entityAccountOpenerIsBeneficialOwner) {
            setAccountOpenerTypes([...accountOpenerTypes, optionBeneficialOwner]);
          }

          setAccountOpenerIsControlPerson(
            currentPlatform?.depgraphNodeValues?.entityAccountOpenerIsControlPerson ?? true
          );
          setAccountOpenerIsBeneficialOwner(
            currentPlatform?.depgraphNodeValues?.entityAccountOpenerIsBeneficialOwner ?? true
          );
          setControlPersonIsBeneficialOwner(
            currentPlatform?.depgraphNodeValues?.entityControlPersonIsBeneficialOwner ?? true
          );
        },
        { fireImmediately: true }
      ),
    []
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Your information</Headline>
        <Paragraph size="small">
          Finally, as a bank we are required to verify key persons associated with your business. You are defined as the
          account opener of your Column platform.
        </Paragraph>
      </RegisterContentGrid>
      <FormProvider {...useFormMethods}>
        <Form onSubmit={handleSubmit(onSuccess)}>
          <RegisterContent>
            <Person
              id="accountOpener"
              isBeneficialOwner={accountOpenerTypes.includes(optionBeneficialOwner)}
              isControlPerson={accountOpenerTypes.includes(optionControlPerson)}
            >
              <FormElement>
                <FormLabel>Do you qualify as one or more of the following?</FormLabel>
                <FormElementHint>
                  If you are not the control person, you will be asked to provide the information of that person next.
                </FormElementHint>
                <OptionList>
                  <Option
                    isActive={accountOpenerTypes.includes(optionControlPerson)}
                    onClick={() => handleAccountOpenerTypeChange(optionControlPerson)}
                  >
                    <StyledCheckbox
                      isChecked={accountOpenerTypes.includes(optionControlPerson)}
                      onCheckedChange={() => handleAccountOpenerTypeChange(optionControlPerson)}
                    />
                    <OptionLabel>Control Person</OptionLabel>
                    <Hint>Required one per platform.</Hint>
                    <StyledLearnMore headline="Control person">{helpSidebarContent.controlPerson}</StyledLearnMore>
                  </Option>
                  <Option
                    isActive={accountOpenerTypes.includes(optionBeneficialOwner)}
                    onClick={() => handleAccountOpenerTypeChange(optionBeneficialOwner)}
                  >
                    <StyledCheckbox
                      isChecked={accountOpenerTypes.includes(optionBeneficialOwner)}
                      onCheckedChange={() => handleAccountOpenerTypeChange(optionBeneficialOwner)}
                    />
                    <OptionLabel>Beneficial Owner</OptionLabel>
                    <Hint>Individuals who own 25% or more of the company.</Hint>
                    <StyledLearnMore headline="Beneficial owner">{helpSidebarContent.beneficialOwner}</StyledLearnMore>
                  </Option>
                </OptionList>
              </FormElement>
            </Person>

            {accountOpenerTypes.length === 0 && (
              <RegisterContentGrid key="bankAccountAuthorizationDocument">
                <Headline size="middle">Bank account authorization</Headline>
                <Paragraph size="small">
                  Please upload an evidence of authorization to open bank account on behalf of company. This can be a
                  statement on company letterhead signed by a senior executive, indicating that you are authorized.
                </Paragraph>
                <NotificationList display="bank-account-authorization-document" />
                <FileUpload<GoogleFile>
                  handleUpload={(file) =>
                    GoogleFileRepository.upload(
                      {
                        subfolderName: 'CDD',
                        file,
                      },
                      currentPlatformState?.id as string
                    )
                  }
                  name="bankAccountAuthorizationDocument"
                  description="Bank account authorization"
                  onFileUpload={(file: GoogleFile) => setValue('bankAccountAuthorizationDocument', file)}
                  onFileRemove={(file: GoogleFile) => setValue('bankAccountAuthorizationDocument', undefined)}
                  renderFileName={(file) => <>{file?.fileName}</>}
                  allowedExtensions={validDocumentExtensions}
                  file={watch('bankAccountAuthorizationDocument')}
                />
              </RegisterContentGrid>
            )}

            {!accountOpenerTypes.includes(optionControlPerson) && (
              <>
                <StyledLine />
                <Person id="controlPerson" isBeneficialOwner={controlPersonIsBeneficialOwner} isControlPerson>
                  <RegisterContentGrid>
                    <Headline>Control person</Headline>
                    <Paragraph size="small">
                      The control person has significant control of the business and is responsible for the account.{' '}
                      <LearnMore headline="Control person">{helpSidebarContent.controlPerson}</LearnMore>
                    </Paragraph>
                  </RegisterContentGrid>
                  <FormElement>
                    <FormLabel>Is this person a beneficial owner?</FormLabel>
                    <FormElementHint>Beneficial owners own 25% or more of a company.</FormElementHint>
                    <Columns>
                      <Option
                        isActive={controlPersonIsBeneficialOwner}
                        isSimple
                        onClick={() => setControlPersonIsBeneficialOwner(true)}
                      >
                        <Radio
                          isChecked={controlPersonIsBeneficialOwner}
                          onCheckedChange={() => setControlPersonIsBeneficialOwner(true)}
                          label="Yes"
                        />
                      </Option>
                      <Option
                        isActive={!controlPersonIsBeneficialOwner}
                        isSimple
                        onClick={() => setControlPersonIsBeneficialOwner(false)}
                      >
                        <Radio
                          isChecked={!controlPersonIsBeneficialOwner}
                          onCheckedChange={() => setControlPersonIsBeneficialOwner(false)}
                          label="No"
                        />
                      </Option>
                    </Columns>
                  </FormElement>
                </Person>
              </>
            )}
            <StyledLine />
            {(watch('beneficialOwners') ?? []).filter(Boolean).map((__, index: number) => {
              return (
                <RegisterContent key={index}>
                  <Person id={`beneficialOwners[${index}]`} isBeneficialOwner>
                    <RegisterContentGrid>
                      <ColumnsBetween>
                        <Headline>{index + 1}. Beneficial owner</Headline>
                        <Button
                          variant="subtle"
                          type="button"
                          icon={<Icon.Cross />}
                          onClick={() => {
                            const array = watch('beneficialOwners') ?? [];

                            delete array[index];

                            setValue('beneficialOwners', array.filter(Boolean));
                          }}
                        />
                      </ColumnsBetween>
                      <Paragraph size="small">
                        Beneficial owners are anyone who owns 25% or more of&nbsp;the&nbsp;company.
                      </Paragraph>
                    </RegisterContentGrid>
                  </Person>

                  <StyledLine />
                </RegisterContent>
              );
            })}
            <StyledRegisterContentGrid>
              <FormElement>
                <FormLabel>
                  Add Beneficial Owner
                  <FormLabelLearnMore headline="Beneficial owner">
                    {helpSidebarContent.beneficialOwner}
                  </FormLabelLearnMore>
                </FormLabel>
                <FormElementHint>Add anyone else who owns 25% or more of the company.</FormElementHint>
              </FormElement>
              <Button
                icon={<Icon.Plus />}
                variant="muted"
                type="button"
                fullWidth
                onClick={() => setValue('beneficialOwners', [...(watch('beneficialOwners') ?? []), {}].filter(Boolean))}
              >
                Add Beneficial Owner
              </Button>
            </StyledRegisterContentGrid>
          </RegisterContent>
          <RegisterAction>
            <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_BUSINESS_CUSTOMER_TYPES)} type="button">
              Back
            </Button>
            <Button isLoading={isLoading} isDisabled={false}>
              Continue
            </Button>
          </RegisterAction>
        </Form>
      </FormProvider>
    </RegisterContent>
  );
};
