import React from 'react';
import styled from 'styled-components';

import { Fade } from '@column/column-ui-kit';

import { LogoLoading } from '~/elements/LogoLoading';

export interface Props {
  isLoading: boolean;
}

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

export const Loading: React.FC<Props> = ({ isLoading }) => (
  <StyledFade show={isLoading}>
    <LogoLoading />
  </StyledFade>
);
