import { createApiHook } from '~/hooks/utils/createApiHook';
import { RoleRepository } from '~/repositories/RoleRepository';
import {
  CreateCustomRoleRequestObject,
  CustomRoleResponseObject,
  CustomRolesList,
  UpdateCustomRoleRequestObject,
} from '~/typings/API';

export const useCreateCustomRole = createApiHook<
  CustomRoleResponseObject,
  CreateCustomRoleRequestObject & { mode?: 'live' | 'sandbox' }
>(RoleRepository.create);

export const useDeleteCustomRole = createApiHook<unknown, { roleId: string }>(RoleRepository.delete);

export const useGetCustomRole = createApiHook<CustomRoleResponseObject, { roleId: string }>(RoleRepository.get, {
  triggerOnSessionStoreSubscribe: true,
});

export const useListRoles = createApiHook<CustomRolesList, undefined>(RoleRepository.list, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
});

export const useUpdateCustomRole = createApiHook<
  CustomRoleResponseObject,
  { roleId: string } & UpdateCustomRoleRequestObject & { mode?: 'live' | 'sandbox' }
>(RoleRepository.update);
