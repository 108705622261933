import * as React from 'react';
import styled from 'styled-components';

import { Tooltip } from '@column/column-ui-kit';

const TransferTypeComingSoon = styled(Tooltip)`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.primary.background};
  background-color: ${({ theme }) => theme.primary.blendToBackground(150)};
  display: flex;
`;

export const ComingSoonBadge: React.FC = () => (
  <TransferTypeComingSoon content="Coming soon to the dashboard.">API Only</TransferTypeComingSoon>
);
