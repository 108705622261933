import { ReportingSettlementReport } from '~/typings/API';
import { getPrettyFilename } from '~/util/downloadFile';

export const getFilenameForStatementReport = (entry: ReportingSettlementReport, ext: string) => {
  let fileType;
  switch (entry.type) {
    case 'bank_account_daily_statement':
      fileType = 'Daily Statement';
      break;
    case 'bank_account_monthly_statement':
      fileType = 'Monthly Statement';
      break;
    case 'bank_account_transaction':
      fileType = 'Transaction Report';
      break;
    case 'bank_account_summary':
      fileType = 'Summary Report';
      break;
    default:
      fileType = 'Statement';
  }

  const fileDescription = [];
  if (entry.statementSubjectDescription) {
    fileDescription.push(entry.statementSubjectDescription);
  }
  if (entry.fromDate && entry.toDate) {
    fileDescription.push(`${entry.fromDate} to ${entry.toDate}`);
  }

  return getPrettyFilename({
    fileType,
    fileDescription,
    ext,
  });
};
