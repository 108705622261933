import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Icon, Input, SegmentedControl, Textarea } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { CopyInput, PageHeader, RenderFields, RestrictedBanner } from '~/components';
import { APIKey, ApiKeyRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EditToolbar, Inner } from '~/styles';

interface Params {
  id: string;
  URLType: string;
}

interface APIKeyProps extends APIKey {
  type: string;
}

export const PageApiKeysEdit: React.FC = () => {
  const { currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id, URLType } = useParams<keyof Params>() as Params;
  const [name] = useState<string>('');
  const [description] = useState<string>('');
  const [type] = useState<string>('sandbox');

  const formMethods = useForm<APIKeyProps>({
    defaultValues: {
      name: name,
      description: description,
      platformId: currentPlatform?.id,
      type: type === 'sandbox' ? 'sandbox' : 'production',
    },
  });

  const { handleSubmit, setValue } = formMethods;

  const handleSave = (data: APIKeyProps) => {
    if (!currentUser) {
      return;
    }
    if (id) {
      ApiKeyRepository.update(
        data.platformId,
        id,
        data.name,
        data.description,
        URLType.toLowerCase() === 'sandbox' ? true : false
      )
        .then(() => {
          navigate(`${ROUTE.PLATFORM_API_KEYS}`);
          addSuccessNotification({
            content: 'API key updated',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
          });
        });
      return;
    }
    ApiKeyRepository.create(data.platformId, data.name, data.description, data.type === 'sandbox' ? true : false)
      .then((response) => {
        navigate(`${ROUTE.PLATFORM_API_KEYS}/edit/${type === 'sandbox' ? 'Sandbox' : 'Production'}/${response.id}`);
        addSuccessNotification({
          content: 'API key created',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchData = () => {
    if (!currentUser) {
      return;
    }

    ApiKeyRepository.get(currentUser.defaultPlatformId, id, URLType === 'Sandbox')
      .then((response) => {
        setValue('name', response.name);
        setValue('description', response.description);
        setValue('apiKeyToken', response.apiKeyToken);
      })
      .catch((error: any) => {
        navigate(ROUTE.PLATFORM_API_KEYS);
        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(() => {
    if (id && currentUser) {
      fetchData();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (id && currentUser) {
            fetchData();
          }
        }
      ),
    []
  );

  return (
    <>
      <PageHeader text={id ? 'Edit API Key' : 'Create API Key'} />

      {currentPermission?.apiKeys !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}
      <FormProvider {...formMethods}>
        <form autoComplete="off" onSubmit={handleSubmit(handleSave)}>
          {RenderFields<APIKeyProps>({
            sections: [
              {
                headline: 'General',
                fields: [
                  {
                    id: 'name',
                    label: 'Name',
                    rules: { required: true },
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <Input
                          value={String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="Name"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'description',
                    label: 'Description',
                    rules: { required: true },
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <Textarea
                          value={value !== undefined ? String(value) : ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="Description"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'type',
                    label: 'Environment',
                    rules: { required: true },
                    children: ({ value, onChange }) => {
                      return (
                        <SegmentedControl
                          options={[
                            {
                              label: 'Live',
                              value: 'production',
                            },
                            {
                              label: 'Sandbox',
                              value: 'sandbox',
                            },
                          ]}
                          active={id ? URLType.toLowerCase() : value}
                          onOptionChange={onChange}
                          isDisabled={!!id}
                        />
                      );
                    },
                  },
                ],
              },
              {
                headline: 'API Key',
                fields: [
                  {
                    id: 'apiKeyToken',
                    label: 'API Key',
                    children: ({ value }) => {
                      return (
                        <CopyInput
                          value={value === undefined ? '' : String(value)}
                          placeholder="Key Will be Generated"
                          icon={<Icon.CopyCode />}
                        />
                      );
                    },
                  },
                ],
              },
            ],
          })}

          <Inner py={0}>
            <EditToolbar>
              <Button onClick={() => navigate(-1)} variant="secondary" size="small">
                {id ? 'Back' : 'Cancel'}
              </Button>
              <Button
                onClick={handleSubmit(handleSave)}
                isDisabled={currentPermission?.apiKeys !== 'write'}
                size="small"
                icon={!id && <Icon.Plus />}
              >
                {id ? 'Save changes' : 'Create API Key'}
              </Button>
            </EditToolbar>
          </Inner>
        </form>
      </FormProvider>
    </>
  );
};
