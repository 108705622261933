import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, SegmentedControl, SegmentedControlVariant } from '@column/column-ui-kit';

import { AdditionalPermissions } from '../partials/AdditionalPermissions';
import { RoleInfo } from '../partials/RoleInfo';
import { TransfersAndAccounts } from '../partials/TransfersAndAccounts';
import { Mode } from '../types';
import { useEntitiesAndAccounts } from '../useEntitiesAndAccounts';
import { buildCreateRequestForEntitiesAndAccountsSubset } from '../utils';
import { ROUTE } from '~/app/routes';
import { PageHeader } from '~/components';
import { useCreateCustomRole, useUpdateCustomRole } from '~/hooks/useRoles';
import { useNavigate } from '~/lib/navigation';
import { RoleRepository } from '~/repositories/RoleRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { CreateCustomRoleRequestObject } from '~/typings/API';

enum Section {
  SectionRoleInfo = 'Role Info',
  SectionTransfersAndAccounts = 'Transfers & Accounts',
  SectionAdditionalPermissions = 'Additional Permissions',
}

const trimDefaults = (data: CreateCustomRoleRequestObject): CreateCustomRoleRequestObject => {
  data.platformLevelPermissions = Object.fromEntries(
    Object.entries(data.platformLevelPermissions).filter(([, v]) => v !== 'default')
  );
  data.entityLevelOverrides = (data.entityLevelOverrides ?? [])
    .map((entity) => {
      return Object.fromEntries(Object.entries(entity).filter(([k, v]) => k === 'entityId' || v !== 'default'));
    })
    .filter((entity) => Object.keys(entity).length > 1);
  data.bankAccountLevelOverrides = (data.bankAccountLevelOverrides ?? [])
    .map((bankAccount) => {
      return Object.fromEntries(
        Object.entries(bankAccount).filter(([k, v]) => k === 'bankAccountId' || v !== 'default')
      );
    })
    .filter((entity) => Object.keys(entity).length > 1);
  return data;
};

export const PageCreateRole: React.FC = () => {
  const { currentPlatform } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const createRole = useCreateCustomRole({
    onSuccess: () => {
      addSuccessNotification({ content: 'Role created successfully' });
      navigate(ROUTE.PLATFORM_ROLES);
    },
    onError: (error) => {
      addDangerNotification({ content: error.message });
    },
  });
  const updateRole = useUpdateCustomRole({
    onSuccess: () => {
      addSuccessNotification({ content: 'Role created successfully' });
      navigate(ROUTE.PLATFORM_ROLES);
    },
    onError: (error) => {
      addDangerNotification({ content: error.message });
    },
  });
  const sandboxEntitiesAndAccounts = useEntitiesAndAccounts({ mode: Mode.Sandbox });
  const liveEntitiesAndAccounts = useEntitiesAndAccounts({ mode: Mode.Live });

  const [visibleSection, setVisibleSection] = useState<Section>(Section.SectionRoleInfo);
  const [lastSection, setLastSection] = useState<Section>();
  const sections = useMemo(() => {
    return Object.values(Section).map((section) => ({ label: section, value: section }));
  }, []);
  const handleGoForward = useCallback(() => {
    setVisibleSection((curSection) => {
      if (curSection === Section.SectionRoleInfo) {
        return Section.SectionTransfersAndAccounts;
      }
      return Section.SectionAdditionalPermissions;
    });
  }, [lastSection]);

  const form = useForm<CreateCustomRoleRequestObject>({
    defaultValues: {
      name: '',
      description: '',
      platformLevelPermissions: {
        achCredits: 'read',
        achDebits: 'read',
        achReturns: 'read',
        apiKeys: 'none',
        entities: 'read',
        loans: 'read',
        platformInfo: 'none',
        realtimeTransfers: 'read',
        wireTransfers: 'read',
        depositChecks: 'read',
        issueChecks: 'read',
        bookTransfers: 'read',
        stopPayments: 'read',
        counterparties: 'read',
        bankAccounts: 'read',
        reporting: 'read',
        webhooks: 'read',
      },
    },
  });

  const handleSubmit = form.handleSubmit((data: CreateCustomRoleRequestObject) => {
    const trimmedData = trimDefaults(data);

    // Due to some limitations in the API (we can't add both sandbox and live entities/accounts in the same request,
    // we must perform two updates to 1. set the sandbox entities/accounts then 2. set the live entities/accounts.
    if (currentPlatform?.isLiveEnabled) {
      const sandboxData = buildCreateRequestForEntitiesAndAccountsSubset(trimmedData, sandboxEntitiesAndAccounts);
      RoleRepository.create({ mode: 'sandbox', ...sandboxData })
        .then((resp) => updateRole.createRequest({ roleId: resp.id, mode: 'live', ...trimmedData }))
        .catch((err) => addDangerNotification({ content: err.message }));
    } else {
      createRole.createRequest(trimmedData);
    }
  });

  return (
    <>
      <PageHeader
        text="Create Role"
        tabs={
          <SegmentedControl
            active={visibleSection}
            options={sections}
            onOptionChange={(section) => {
              setLastSection(visibleSection);
              setVisibleSection(section);
            }}
            variant={SegmentedControlVariant.Secondary}
          />
        }
      >
        {visibleSection !== Section.SectionAdditionalPermissions && (
          <Button onClick={handleGoForward} size="small">
            Next
          </Button>
        )}
        {visibleSection === Section.SectionAdditionalPermissions && (
          <Button isLoading={!createRole.isInitialLoad && createRole.isLoading} onClick={handleSubmit} size="small">
            Save Role
          </Button>
        )}
      </PageHeader>
      <FormProvider {...form}>
        {visibleSection === Section.SectionRoleInfo && <RoleInfo />}
        {visibleSection === Section.SectionTransfersAndAccounts && (
          <TransfersAndAccounts
            sandboxEntitiesAndAccounts={sandboxEntitiesAndAccounts}
            liveEntitiesAndAccounts={liveEntitiesAndAccounts}
          />
        )}
        {visibleSection === Section.SectionAdditionalPermissions && <AdditionalPermissions />}
      </FormProvider>
    </>
  );
};
