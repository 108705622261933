import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Chip, Fade, Icon } from '@column/column-ui-kit';

import { Modal, ModalSize } from '~/components';
import { useAcceptPlatformInvite } from '~/hooks/useUsers';
import { Invite } from '~/repositories/InviteRepository';
import { getRoleLabel } from '~/repositories/RoleRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Inner, Paragraph } from '~/styles';

export interface Props {
  inviteCode?: string;
  invite?: Invite;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

const StyledBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  vertical-align: middle;

  & > * {
    margin-right: 6px;
  }
`;

export const AcceptPlatformInviteModal: React.FC<Props> = ({ invite, inviteCode, open, onClose, onSave }) => {
  const { triggerEvent: triggerSessionEvent } = useSessionStore();
  const [isAcceptingInvite, setAcceptingInvite] = useState<boolean>(false);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const addSuccessNotification = useNotificationStore((s) => s.addSuccessNotification);

  const acceptPlatformInvite = useAcceptPlatformInvite({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'popup',
      });
      setAcceptingInvite(false);
    },
    onSuccess: () => {
      addSuccessNotification({
        content: 'Invite accepted',
      });
      setAcceptingInvite(false);
      triggerSessionEvent('accept_platform_invite');
      onSave();
    },
  });

  const handleAcceptClick = useCallback(() => {
    if (!invite || !inviteCode) return;
    acceptPlatformInvite.createRequest({ inviteCode, platformId: invite.platformId });
    setAcceptingInvite(true);
  }, [acceptPlatformInvite, inviteCode]);

  const roleName = useMemo(() => {
    if (!invite) return '';
    return getRoleLabel(invite.platformRole);
  }, [invite]);

  return (
    <Modal
      headline={`Join ${invite?.platformName}?`}
      icon={<Icon.AnimationMailbox />}
      onClose={onClose}
      open={open}
      primaryButton={{
        icon: <Icon.CircleCheck />,
        isDisabled: !invite || isAcceptingInvite,
        isLoading: isAcceptingInvite,
        onClick: handleAcceptClick,
        text: 'Accept',
      }}
      secondaryButton={{
        onClick: onClose,
        text: 'Cancel',
      }}
      size={ModalSize.Small}
    >
      <Fade show={!!invite}>
        <Inner pb={0} pt={8} px={0}>
          <StyledBody>
            <Paragraph>Your role will be set as </Paragraph>
            <Chip>{roleName}</Chip>
          </StyledBody>
        </Inner>
      </Fade>
    </Modal>
  );
};
