import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon, ToggleHeight } from '@column/column-ui-kit';

import { Headline } from '~/styles';

export type SectionVariantType = 'isBoxed' | 'isCompact';

export interface SectionProps extends PropsWithChildren {
  headline?: ReactNode | string;
  description?: string;
  isDisabled?: boolean;
  isCollapsed?: boolean;
  hide?: boolean;
  variant?: SectionVariantType | SectionVariantType[];
}

interface SectionStyleProps {
  $isOpen?: boolean;
  $isBoxed?: boolean;
  $isCompact?: boolean;
  $isDisabled?: boolean;
  $isCollapsed?: boolean;
  $hide?: boolean;
}

const Wrapper = styled.div<SectionStyleProps>`
  --section-content-gap: ${({ $isCompact }) => ($isCompact ? '16px' : '24px')};

  padding: 24px;
  display: grid;
  grid-gap: ${({ $isOpen, $isCompact }) =>
    $isCompact ? '0px' : $isOpen || typeof $isOpen === 'undefined' ? '24px' : '0'};
  box-sizing: border-box;
  grid-template-columns: 100%;
  align-items: start;
  justify-content: start;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.secondary.blendToBackground(200)};

  &:first-of-type {
    box-shadow: none;
  }

  ${({ $hide }) =>
    $hide &&
    css`
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: none;
    `}

  ${({ $isCompact }) =>
    $isCompact &&
    css`
      padding: 0;

      &:not(:first-of-type) {
        box-shadow: none;
      }
    `}

  ${({ $isBoxed }) =>
    $isBoxed &&
    css`
      background-color: transparent;
      border-radius: 8px;
      margin: 0 24px 24px;
      padding: 0;
      grid-gap: 0;
      box-shadow: none;
      overflow: hidden;
      position: relative;
      box-shadow:
        0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)},
        0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(200)};
      transition:
        background-color 0.2s,
        grid-gap 0.2s;

      &:not(:last-of-type) {
        box-shadow:
          0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)},
          0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(200)};
      }

      background-color: ${({ theme }) => theme.secondary.blendToBackground(35)};
      box-shadow:
        0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(100)},
        0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(100)};

      &:not(:last-of-type) {
        box-shadow:
          0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(100)},
          0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(100)};
      }

      ${Header} {
        margin: 0;
      }

      > .toggleHeight {
        --toggle-height-padding: 0 24px 24px;

        > div:first-child {
          margin-top: 8px;
        }

        .section {
          padding: 24px;
          margin: 0 -24px;
          box-shadow: 0 -1px 0 ${({ theme }) => theme.secondary.blendToBackground(200)};
        }
      }

      .form-field {
        grid-template-columns: calc(25% - 12px) auto calc(25% - 12px);
      }
    `}
`;

const Header = styled.div<SectionStyleProps>`
  display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
  align-items: start;
  gap: 4px;
  overflow: hidden;
  max-height: ${({ $hide }) => ($hide ? '0' : '100px')};
  transition:
    background-color 0.2s,
    opacity 0.2s,
    max-height 0.2s;

  opacity: ${({ $isDisabled }) => ($isDisabled ? '.5' : '1')};

  ${({ $isBoxed }) =>
    $isBoxed &&
    css`
      padding: 16px 24px;
      border-radius: 8px;
    `}

  ${({ $isCollapsed }) =>
    typeof $isCollapsed !== 'undefined' &&
    css`
      cursor: pointer;

      svg {
        margin-left: -4px;
      }
    `}

  ${({ $isOpen, $isBoxed }) =>
    ($isOpen === false || typeof $isOpen === 'undefined') &&
    $isBoxed &&
    css`
      background-color: ${({ theme }) => theme.background};

      &:hover {
        background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
      }
    `};

  ${({ $isOpen }) =>
    $isOpen === true &&
    css`
      &:hover {
        background-color: transparent;
      }
    `};
`;

const Info = styled.div`
  display: grid;
  grid-gap: 4px;
  justify-content: start;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  font-size: 12px;
  line-height: 18px;
`;

const Content = styled.div`
  display: grid;
  gap: var(--section-content-gap);
`;

export const Section: FC<SectionProps> = ({
  headline,
  description,
  children,
  variant,
  isCollapsed,
  isDisabled,
  hide,
}) => {
  const [isOpenState, setIsOpenState] = useState<boolean | undefined>(
    isDisabled === true ? false : typeof isCollapsed === 'boolean' ? !isCollapsed : undefined
  );

  const handleOpenToggle = useCallback(() => {
    if (typeof isCollapsed === 'undefined' || isDisabled === true) {
      return;
    }

    setIsOpenState((s) => !s);
  }, [isCollapsed, isDisabled]);

  useEffect(() => {
    if (isDisabled === false) {
      setIsOpenState(true);
    } else if (isDisabled === true) {
      setIsOpenState(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (hide === false) {
      setIsOpenState(true);
    } else if (hide === true) {
      setIsOpenState(false);
    }
  }, [hide]);

  const variants = useMemo(() => (typeof variant === 'string' ? [variant] : variant), [variant]);
  const checkClass = useCallback((style: SectionVariantType) => variants?.includes(style) ?? false, [variants]);

  return (
    <Wrapper
      className="section"
      $isBoxed={checkClass('isBoxed')}
      $isCompact={checkClass('isCompact')}
      $isOpen={isOpenState}
      $hide={hide}
    >
      {headline && (
        <Header
          $isOpen={isOpenState}
          $isDisabled={isDisabled}
          onClick={handleOpenToggle}
          $isBoxed={checkClass('isBoxed')}
          $isCollapsed={isCollapsed}
          $hide={hide}
        >
          {typeof isOpenState !== 'undefined' &&
          typeof isDisabled === 'undefined' &&
          typeof isCollapsed !== 'undefined' ? (
            isOpenState ? (
              <Icon.ChevronUp />
            ) : (
              <Icon.ChevronDown />
            )
          ) : null}

          <Info>
            <Headline size="small">{headline}</Headline>

            {String(description).length > 0 && <Description>{description}</Description>}
          </Info>
        </Header>
      )}

      <ToggleHeight isClose={typeof isOpenState !== 'undefined' && !isOpenState} className="toggleHeight">
        <Content>{(typeof isOpenState === 'undefined' || isOpenState === true) && children}</Content>
      </ToggleHeight>
    </Wrapper>
  );
};
