import * as React from 'react';
import styled from 'styled-components';

import { Icon, Tooltip, TooltipProps } from '@column/column-ui-kit';

const InfoTooltipWrapper = styled(Tooltip)`
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(500)};
    --icon-size: 16px;
  }

  &:hover {
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(900)};
    }
  }
`;

export const InfoTooltip: React.FC<TooltipProps> = (props) => (
  <InfoTooltipWrapper {...props}>
    <Icon.CircleQuestionmark />
  </InfoTooltipWrapper>
);
