import { PageTransfersAdd } from './Add';
import { PageTransfersCheck } from './Check';
import { PageTransfers } from './Overview';
import { PageTransferTemplatesEdit } from './Templates/Edit';
import { PageTransferTemplatesOverview } from './Templates/Overview';
import { PageTransferTemplatesReview } from './Templates/Review';
import { PageTransfersView } from './View';

export const TRANSFERS = {
  Transfers: PageTransfers,
  TransfersAdd: PageTransfersAdd,
  TransfersCheck: PageTransfersCheck,
  TransfersView: PageTransfersView,
  TransferTemplatesOverview: PageTransferTemplatesOverview,
  TransferTemplatesEdit: PageTransferTemplatesEdit,
  TransferTemplatesReview: PageTransferTemplatesReview,
} as const;
