import {
  AssignPlatformRoleRequest,
  CustomRoleGranularPermissionLevel,
  CustomRolePermissionLevel,
  PlatformRoleResponseObject,
  PlatformRolesList,
  PrecannedRoleName,
} from '~/typings/API';
import { convertValues } from '~/util';

import { client } from './client';
import { Invite, ListInvitesResponse } from './InviteRepository';
import { PlatformRepository } from './PlatformRepository';

export type PlatformRoleName = PrecannedRoleName;
export type PermissionLevel = CustomRoleGranularPermissionLevel | CustomRolePermissionLevel;

export type ListPlatformRoles = PlatformRolesList;

export type PlatformRole = ListPlatformRoles['platformRoles'][number];
export type PlatformRolePermissions = PlatformRole['platformLevelPermissions'];
export type PlatformRolePermissionKey = keyof PlatformRolePermissions;

export interface PlatformRolesExtended extends PlatformRole {
  platformName: string;
  isLiveEnabled: boolean;
}

export interface ListPlatformRolesExtended {
  platformRoles: PlatformRolesExtended[];
}

export enum FactorType {
  SMS = 0,
  TOTP = 1,
}

export enum FactorTypeResponse {
  SMS = 'sms',
  TOTP = 'totp',
}

export enum ChallengeName {
  CHALLENGE_NONE = 'CHALLENGE_NONE',
  CHALLENGE_MFA_SMS = 'CHALLENGE_MFA_SMS',
  CHALLENGE_MFA_TOTP = 'CHALLENGE_MFA_TOTP',
}

export enum MFAStatus {
  NONE = 'NONE',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum EmailState {
  EMAILSTATE_NONE = 'EMAILSTATE_NONE',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFY_IN_PROGRESS = 'VERIFY_IN_PROGRESS',
  VERIFIED = 'VERIFIED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export type ThemeType = 'default' | 'dark' | 'warm';
export const themeTypes: ThemeType[] = ['default', 'dark', 'warm'];

interface TablePageSettings {
  limit: number;
}

type TableSettings = Record<string, TablePageSettings>;

interface UploadSettings {
  rdcFrontImageCrop?: boolean;
  rdcBackImageCrop?: boolean;
}

export interface Settings {
  themeType: ThemeType;
  tableSettings: TableSettings;
  uploadSettings: UploadSettings;
  platformBannerDismissed: boolean;
}

export interface DashboardUser {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  emailState: EmailState;
  dashboardDisplayMode: string;
  defaultPlatformId: string;
  factors: Factor[];
  platformRoles: PlatformRole[];
}

export interface DashboardUserRedacted {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  platformId: string;
  platformRole: PlatformRole;
  factors: Factor[];
}

export interface CreateDashboardUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  platformId: string;
  inviteCode?: string;
}

export interface GetDashboardUserRequest {
  dashboardUserId: string;
}

export interface UpdateDashboardUserRequest {
  dashboardUserId: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  defaultPlatformId: string;
}

export interface Factor {
  createdAt: string;
  displayName: string;
  factorId: string;
  factorType: FactorTypeResponse;
  factorVerified: boolean;
  updatedAt: string;
}

export type DeleteFactorRequest = {
  factorId: string;
};

export type DeleteFactorResponse = {
  factorId: string;
};

export type ListFactorsResponse = {
  factors: Factor[];
};

export type ResendFactorVerificationRequest = {
  factorId: string;
};

export type ResendFactorVerificationResponse = {
  factorId: string;
};

export interface ListDashboardUsersOnPlatforRequest {
  platformId: string;
}

export interface ListDashboardUsersOnPlatformResponse {
  dashboardUsers: DashboardUserRedacted[];
}

export type ListPlatformRolesResponse = {
  platformRoles: (PlatformRoleResponseObject & {
    isLiveEnabled: boolean;
    platformName: string;
  })[];
};

export interface ListPlatformRolesRequest {
  dashboardUserId: string;
}

export interface ListInvitesRequest {
  dashboardUserId: string;
}

export interface SetupMFARequest {
  factorName?: string;
  factorType: FactorType;
}

export interface SetupMFAResponse {
  dashboardUserId: string;
  factorId?: string;
  otpauthUri?: string;
}

export interface UpdatePasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export interface AcceptInviteToPlatformRequest {
  inviteCode?: string;
  platformId: string;
}

export interface AcceptInviteToPlatformResponse {}

export interface VerifyEmailRequest {
  emailVerificationId: string;
}

export interface VerifyEmailResponse {}

export interface DashboardLoginRequest {
  email: string;
  password: string;
}

export interface DashboardLoginResponse {
  dashboardUserId: string;
  sessionId: string;
  defaultPlatformId: string;
  mfaStatus: MFAStatus;
  phoneNumber: string;
  challengeName: ChallengeName;
}

export interface ChallengeMFARequest {
  code: string;
}

export interface ChallengeMFAResponse {
  dashboardUserId: string;
  mfaStatus: MFAStatus;
}

export interface VerifyMFARequest {
  code: string;
  factorId?: string;
}

export interface VerifyMFAResponse {
  dashboardUserId: string;
  mfaStatus: MFAStatus;
}

export interface CreatePasswordResetRequest {
  email: string;
}

export interface ResetPasswordRequest {
  passwordResetId: string;
  newPassword: string;
}

export interface RemoveUserFromPlatformRequest {
  dashboardUserId: string;
  platformId: string;
}

export class UserRepository {
  static async create(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    platformId: string,
    inviteCode: string | null,
    reCaptchaToken: string
  ) {
    const request: CreateDashboardUserRequest = {
      firstName,
      lastName,
      email,
      password,
      platformId,
      inviteCode: inviteCode || undefined,
    };

    return client
      .post<CreateDashboardUserRequest, DashboardUser>('/dashboard/dashboard-users', request, {
        reCaptchaToken,
      })
      .then((response) =>
        convertValues<DashboardUser>(response, {
          emailState: Object.values(EmailState),
        })
      );
  }

  static async createPasswordReset(email: string, reCaptchaToken: string) {
    const request: CreatePasswordResetRequest = {
      email,
    };

    return client.post<CreatePasswordResetRequest, object>('/dashboard/password_reset_request', request, {
      reCaptchaToken,
    });
  }

  static async passwordReset(newPassword: string, passwordResetId: string, reCaptchaToken: string) {
    const request: ResetPasswordRequest = {
      newPassword,
      passwordResetId,
    };

    return client.post<ResetPasswordRequest, object>(`/dashboard/reset_password/${passwordResetId}`, request, {
      reCaptchaToken,
    });
  }

  static async login(email: string, password: string, reCaptchaToken: string) {
    const request: DashboardLoginRequest = {
      email,
      password,
    };

    return client
      .post<DashboardLoginRequest, DashboardLoginResponse>('/dashboard/login', request, {
        reCaptchaToken,
      })
      .then((response) =>
        convertValues<DashboardLoginResponse>(response, {
          challengeName: Object.values(ChallengeName),
          mfaStatus: Object.values(MFAStatus),
        })
      );
  }

  static async updateSettings(request: Partial<Settings>) {
    return client
      .patch<Partial<Settings>, Partial<Settings>>('/dashboard/dashboard-users/settings', request, {
        sandbox: true,
      })
      .then((response) => convertValues<Partial<Settings>>(response));
  }

  static async getSettings() {
    return client
      .get<unknown, Partial<Settings>>('/dashboard/dashboard-users/settings', { sandbox: true })
      .then((response) => convertValues<Partial<Settings>>(response));
  }

  static async acceptLegalAgreement() {
    return client.post<object, object>('/dashboard/accept-legal-agreement', {});
  }

  static async challengeMfa(request: ChallengeMFARequest, reCaptchaToken: string) {
    return client
      .post<VerifyMFARequest, VerifyMFAResponse>('/dashboard/mfa/challenge', request, { sandbox: true, reCaptchaToken })
      .then((response) => convertValues<ChallengeMFAResponse>(response, { mfaStatus: Object.values(MFAStatus) }));
  }

  static async verifyMfa(reCaptchaToken: string, request: VerifyMFARequest) {
    return client
      .post<VerifyMFARequest, VerifyMFAResponse>('/dashboard/mfa/verify', request, { sandbox: true, reCaptchaToken })
      .then((response) => convertValues<VerifyMFAResponse>(response, { mfaStatus: Object.values(MFAStatus) }));
  }

  static async logout() {
    return client.post('/dashboard/logout', {});
  }

  static async fetch(userId: string) {
    return client
      .get<GetDashboardUserRequest, DashboardUserRedacted>(`/dashboard/dashboard-users/${userId}`, {
        sandbox: true,
      })
      .then((response) =>
        convertValues<DashboardUser>(response, {
          factorType: Object.values(FactorTypeResponse),
        })
      );
  }

  static async fetchSelf() {
    return client.get<unknown, DashboardUser>('/dashboard/self', { sandbox: true }).then((response) =>
      convertValues<DashboardUser>(response, {
        factorType: Object.values(FactorTypeResponse),
        emailState: Object.values(EmailState),
      })
    );
  }

  static async update(attrs: Partial<UpdateDashboardUserRequest>, reCaptchaToken: string) {
    return client
      .patch<
        Partial<UpdateDashboardUserRequest>,
        DashboardUser
      >(`/dashboard/dashboard-users/${attrs.dashboardUserId}`, attrs, { sandbox: true, reCaptchaToken })
      .then((response) =>
        convertValues<DashboardUser>(response, {
          factorType: Object.values(FactorTypeResponse),
          emailState: Object.values(EmailState),
        })
      );
  }

  static async updatePassword(request: UpdatePasswordRequest) {
    return client.patch<UpdatePasswordRequest, object>('/dashboard/dashboard-users/password', request, {
      sandbox: true,
    });
  }

  static async setupMfa(reCaptchaToken: string, request?: SetupMFARequest) {
    return client
      .post<
        object,
        SetupMFAResponse
      >('/dashboard/mfa/setup', request ?? {}, { allowNumbersInRequest: true, sandbox: true, reCaptchaToken })
      .then((response) => convertValues<SetupMFAResponse>(response));
  }

  static async resendMfa(reCaptchaToken: string) {
    return client.post<object, object>('/dashboard/mfa/resend', {}, { sandbox: true, reCaptchaToken });
  }

  static async createVerifyEmail() {
    return client.post<object, object>('/dashboard/email_verifications', {}, { sandbox: true });
  }

  static async verifyEmail(code: string) {
    return client
      .get<VerifyEmailRequest, VerifyEmailResponse>(`/dashboard/email_verifications/verify/${code}`, { sandbox: true })
      .then((response) => convertValues<VerifyEmailResponse>(response));
  }

  static async acceptInvite({ inviteCode, platformId }: AcceptInviteToPlatformRequest) {
    return client.post<object, AcceptInviteToPlatformResponse>(
      `/dashboard/dashboard-users/accept_platform_invite/${platformId}`,
      {
        inviteCode,
      },
      { sandbox: true }
    );
  }

  static async deleteFactor({ factorId }: DeleteFactorRequest) {
    return client.delete<object, DeleteFactorResponse>(`/dashboard/dashboard-users/factors/${factorId}`);
  }

  static async listFactors() {
    return client.get<object, ListFactorsResponse>('/dashboard/dashboard-users/factors').then((response) => ({
      factors: Array.isArray(response?.factors) ? response.factors.map((e) => convertValues<Factor>(e)) : [],
    }));
  }

  static async resendFactorVerification({ factorId }: ResendFactorVerificationRequest) {
    return client.post<object, ResendFactorVerificationResponse>(
      `/dashboard/dashboard-users/factors/${factorId}/resend-verification`,
      {}
    );
  }

  static async getInvites(request: ListInvitesRequest) {
    return client
      .get<ListInvitesRequest, ListInvitesResponse>(`/dashboard/dashboard-users/${request.dashboardUserId}/invites`, {
        sandbox: true,
      })
      .then((response) => ({
        invites: Array.isArray(response?.invites) ? response.invites.map((e) => convertValues<Invite>(e)) : [],
      }));
  }

  static async platformRoles(request: ListPlatformRolesRequest): Promise<ListPlatformRolesResponse> {
    return client
      .get<ListPlatformRolesRequest, ListPlatformRolesExtended>(
        `/dashboard/dashboard-users/${request.dashboardUserId}/platform-roles`,
        {
          sandbox: true,
        }
      )
      .then((response) => ({
        platformRoles: Array.isArray(response?.platformRoles)
          ? response.platformRoles.map((e) => convertValues<PlatformRole>(e))
          : [],
      }))
      .then(async (response) => {
        const platformRoles = await Promise.all(
          response.platformRoles.map(async (platformRole: PlatformRole) => {
            const platform = await PlatformRepository.get(platformRole.platformId);

            return {
              ...platformRole,
              platformName: platform.name,
              isLiveEnabled: platform.isLiveEnabled,
            };
          })
        );

        return {
          platformRoles,
        };
      });
  }

  static async platformRolesWithUser(userId: string) {
    return this.platformRoles({ dashboardUserId: userId }).then(async (response) => {
      const platformRoles = await Promise.all(
        response.platformRoles.map(async (platformRole) => {
          const users = await this.getAll({ platformId: platformRole.platformId });

          return {
            ...platformRole,
            dashboardUsers: users.dashboardUsers,
          };
        })
      );

      return {
        platformRoles,
      };
    });
  }

  static async getAll({ platformId }: ListDashboardUsersOnPlatforRequest) {
    return client.get<unknown, ListDashboardUsersOnPlatformResponse>(
      `/dashboard/dashboard_users?platformId=${platformId}`,
      { platformId, sandbox: true }
    );
  }

  static async updatePlatformRole(platformId: string, request: AssignPlatformRoleRequest) {
    return client
      .post<AssignPlatformRoleRequest, ListPlatformRoles>(
        `/dashboard/dashboard-users/${request.userId}/assign-platform-role`,
        request,
        {
          platformId,
        }
      )
      .then((response) => ({
        platformRoles: Array.isArray(response?.platformRoles)
          ? response.platformRoles.map((e) => convertValues<PlatformRole>(e))
          : [],
      }));
  }

  static async removeUserFromPlatform(request: RemoveUserFromPlatformRequest) {
    return client.delete<object, object>(`/dashboard/dashboard-users/${request.dashboardUserId}`, {
      platformId: request.platformId,
      sandbox: true,
    });
  }
}
