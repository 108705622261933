import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import { EmptyState, RestrictedBanner } from '~/components';
import { ReportingTable } from '~/components/ReportingTable';
import { useSessionStore } from '~/stores/Session';
import { Inner } from '~/styles';

import { BankAccountRouteProps } from './Route';

export const PageBankAccountsDocuments: FC = () => {
  const { isAuthorized } = useSessionStore();
  const { bankAccount } = useOutletContext<BankAccountRouteProps>();

  const hasReportingPermissions = isAuthorized({
    permission: 'reporting',
    permissionLevel: 'read',
    bankAccount: { id: bankAccount.id, owners: bankAccount.owners },
  });

  return (
    <>
      {!hasReportingPermissions && (
        <Inner pt={0}>
          <RestrictedBanner />
        </Inner>
      )}
      <ReportingTable
        tableId="statements"
        bankAccountId={bankAccount.id}
        filter={{ category: 'statement' }}
        visibleFiles={['pdf', 'csv']}
        hiddenFiles={['json', 'parquet', 'bai2']}
        empty={<EmptyState headline="No documents found" text="Wait for your first statements to get generated." />}
      />
    </>
  );
};
