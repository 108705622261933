import { datadogLogs } from '@datadog/browser-logs';

interface LogOptions {
  name: string;
  type?: 'debug' | 'info' | 'warn' | 'error';
  context?: any;
}

export const log = (options: LogOptions) => {
  if (process.env.NODE_ENV === 'development') {
    console.info('Development', options);
  }

  switch (options.type) {
    case 'debug':
      datadogLogs.logger.debug(options.name, options.context);
      break;
    case 'warn':
      datadogLogs.logger.warn(options.name, options.context);
      break;
    case 'error':
      datadogLogs.logger.error(options.name, options.context);
      break;
    default:
      datadogLogs.logger.info(options.name, options.context);
  }
};

interface ConsoleType {
  type?: 'success' | 'error' | 'default';
  content: string;
  obj?: object;
}

const svgDataUrl =
  'data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00LjUgNC41VjcuNUg2QzYuNzk1NjUgNy41IDcuNTU4NzEgNy44MTYwNyA4LjEyMTMyIDguMzc4NjhDOC42ODM5MyA4Ljk0MTI5IDkgOS43MDQzNSA5IDEwLjVWMTJIM0MyLjIwNDM1IDEyIDEuNDQxMjkgMTEuNjgzOSAwLjg3ODY4IDExLjEyMTNDMC4zMTYwNzEgMTAuNTU4NyAwIDkuNzk1NjUgMCA5VjNDMCAyLjIwNDM1IDAuMzE2MDcxIDEuNDQxMjkgMC44Nzg2OCAwLjg3ODY4QzEuNDQxMjkgMC4zMTYwNzEgMi4yMDQzNSAwIDMgMEw5IDBDOS43OTU2NSAwIDEwLjU1ODcgMC4zMTYwNzEgMTEuMTIxMyAwLjg3ODY4QzExLjY4MzkgMS40NDEyOSAxMiAyLjIwNDM1IDEyIDNWNC41SDQuNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=';

export const consoleLog = (options: ConsoleType) => {
  console.info(
    `%c${options.content}`,
    `background-image: url("${svgDataUrl}"); background-size: 12px 12px; background-position: 5px 5px; line-height: 14px; background-repeat: no-repeat; background-color: ${
      options?.type === 'error' ? '#c64d62' : options?.type === 'success' ? '#64b28e' : '#6f6f7f'
    }; color: #fff; font-family: monospace; font-size: 12px; font-weight: 500; padding: 4px 8px 4px 22px; border-radius: 6px; text-shadow: 0 1px .5px rgba(0, 0, 0, .15)`,
    options?.obj ?? ''
  );
};
