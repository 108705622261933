import { toSnake } from './convertKeys';

const isISODate = (value: any) => {
  if (typeof value !== 'string' || isNaN(Date.parse(value))) {
    return false;
  }

  return value.length === 20 && !/\s/.test(value) && `${new Date(value).toISOString().split('.')[0]}Z` === value;
};

export const convertValues = <T>(
  object: Record<string, any>,
  enums?: Record<string, string[]>,
  cast?: Partial<Record<keyof T, any>>
) => {
  const newObject: any = {};

  const getValue = (k: any) => {
    const value = object[k];

    if (cast && typeof cast[k as keyof T] !== 'undefined' && Object.keys(cast).includes(k)) {
      return cast[k as keyof T];
    } else if (enums && Object.keys(enums).includes(k) && typeof enums[k][value] !== 'undefined') {
      return enums[k][value];
    } else if (typeof value === 'undefined' || value === '') {
      return undefined;
    } else if (isISODate(value)) {
      return new Date(value);
    } else if (typeof value === 'boolean') {
      return !!value;
    } else if (value === 'true' || value === 'false') {
      return value === 'true';
    } else if (!isNaN(value) && typeof value === 'string' && !value.includes('+')) {
      return parseInt(value, 10);
    } else {
      return value;
    }
  };

  for (const k in object) {
    if (object.hasOwnProperty(k)) {
      const value = object[k];

      if (typeof value === 'object' && !(value instanceof Date) && value !== null && !Array.isArray(value)) {
        newObject[k] = convertValues(
          value,
          enums,
          cast ? (typeof cast[k as keyof T] !== 'undefined' ? cast[k as keyof T] : undefined) : undefined
        );
      } else {
        newObject[k] = getValue(k);
      }
    }
  }

  return newObject as T;
};

export const convertValuesToString = (object: Record<string, any>) => {
  const newObject = object;

  Object.keys(object).forEach((key: string) => {
    newObject[key] =
      object[key] && typeof object[key] === 'object'
        ? convertValuesToString(object[key])
        : typeof object[key] === 'number' || object[key] === null
          ? String(object[key])
          : object[key];
  });

  return newObject;
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};

export const base64ToFile = (base64: string, filename: string, mimeType: string): File => {
  const byteString = atob(base64.split(',')[1]);

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeType });

  return new File([blob], filename, { type: mimeType });
};

export const convertCleanBase64 = (base64String: string): string => {
  return base64String.replace(/^data:\w+\/.+;base64,/, '');
};

export const convertObjectValuesToSnakeCase = (obj: Record<string, any>): Record<string, any> => {
  const newObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string') {
      newObj[key] = toSnake(obj[key]);
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const convertCamelCaseStringToSnakeCase = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const removeEmptyString = <T>(object: Record<string, any>): T => {
  Object.entries(object).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      removeEmptyString(value);
    }

    if (
      (value && typeof value === 'object' && !Object.keys(value).length) ||
      value === null ||
      value === undefined ||
      value.length === 0
    ) {
      if (Array.isArray(object)) {
        object.splice(Number(key), 1);
      } else {
        delete object[key];
      }
    }
  });

  return object as T;
};
