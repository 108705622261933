import React, { useCallback, useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Icon, Tooltip } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader, Table, TableColumn } from '~/components';
import { useListRoles } from '~/hooks/useRoles';
import { useNavigate } from '~/lib/navigation';
import { precannedRolesByName } from '~/repositories/RoleRepository';
import { ParagraphLight, TinyHeadline } from '~/styles';

interface TableRow {
  roleId?: string;
  name: {
    name?: string;
    description?: string;
  };
  userCount: number;
  editable: boolean;
}

const TextWrapper = styled.div`
  display: flex;
  align-items: start;
  vertical-align: middle;
  flex-direction: column;
  max-width: 450px;
`;

const ActionWrapper = styled.div`e
  display: flex;
  justify-content: end;
`;

const LockIcon = styled(Icon.Lock)`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const getColumns = (navigate: NavigateFunction): TableColumn[] => [
  {
    Header: 'Name',
    accessor: 'name',
    width: 'minmax(min-content, auto)',
    Cell: (current) => (
      <TextWrapper>
        <TinyHeadline bold>{current.value.name ?? 'Unnamed'}</TinyHeadline>
        {current.value.description && <ParagraphLight size={'small'}>{current.value.description}</ParagraphLight>}
      </TextWrapper>
    ),
  },
  {
    Header: 'Users',
    accessor: 'userCount',
    width: 'minmax(min-content, 25%)',
    Cell: (current) => (current.value === 1 ? '1 User' : `${current.value ?? 0} Users`),
  },
  {
    Header: '',
    accessor: 'editable',
    width: 'min-content',
    Cell: (current) => {
      const row = current.row.original as TableRow;
      return (
        <ActionWrapper>
          {current.value ? (
            <Button
              onClick={() => navigate(`${ROUTE.PLATFORM_ROLES}/${row.roleId}`)}
              icon={<Icon.Edit />}
              size={'small'}
              variant="muted"
            >
              Edit
            </Button>
          ) : (
            <Tooltip content={'Column-managed roles cannot be edited.'} placement={'left'}>
              <LockIcon />
            </Tooltip>
          )}
        </ActionWrapper>
      );
    },
  },
];

export const PageRoles: React.FC = () => {
  const navigate = useNavigate();
  const handleCreateRole = useCallback(() => {
    navigate(ROUTE.PLATFORM_ROLES_CREATE);
  }, [navigate]);

  const { response: rolesResponse, isInitialLoad, isLoading } = useListRoles();
  const roles = useMemo(() => {
    return (rolesResponse?.roles || []).map((role) => ({
      roleId: role.id,
      name: {
        name: role.editable ? role.name : precannedRolesByName[role.name].label,
        description: role.description,
      },
      userCount: role.userCount,
      editable: role.editable,
    }));
  }, [rolesResponse]);

  const columns = useMemo(() => getColumns(navigate), [navigate]);

  return (
    <>
      <PageHeader text="Roles">
        <Button onClick={handleCreateRole} icon={<Icon.Plus />} size={'small'} variant="primary">
          Create Role
        </Button>
      </PageHeader>
      <Table columns={columns} data={isInitialLoad || isLoading ? [] : roles} isLoading={isInitialLoad || isLoading} />
    </>
  );
};
