import { client } from '~/repositories/client';

export enum TransferTemplateType {
  ACH = 'ach',
  Wire = 'wire',
}

export interface AchTransferTemplate {
  transferTemplateRowId?: string;
  amount?: number;
  accountNumberId?: string;
  allowOverdraft?: boolean;
  bankAccountId?: string;
  counterpartyId?: string;
  currencyCode?: string;
  description?: string;
  companyDiscretionaryData?: string;
  companyEntryDescription?: string;
  companyName?: string;
  entryClassCode?: string;
  paymentRelatedInfo?: string;
  receiverName?: string;
  receiverId?: string;
  type?: string;
}

export type AchTransferTemplateResponse = AchTransferTemplate & {
  bankAccountDescription?: string;
  counterpartyDescription?: string;
};

export interface WireTransferTemplate {
  transferTemplateRowId?: string;
  amount?: number;
  accountNumberId?: string;
  allowOverdraft?: boolean;
  bankAccountId?: string;
  counterpartyId?: string;
  currencyCode?: string;
  description?: string;
}

export type WireTransferTemplateResponse = WireTransferTemplate & {
  bankAccountDescription?: string;
  counterpartyDescription?: string;
};

export interface TransferTemplate {
  description: string;
  templateType: TransferTemplateType;
  achDefaultParameters?: AchTransferTemplate;
  achTransfers?: AchTransferTemplate[];
  wireDefaultParameters?: WireTransferTemplate;
  wireTransfers?: WireTransferTemplate[];
  lastUsedAt?: string;
  usageCount?: number;
  visibleColumns?: string[];
}

export interface TransferTemplateResponse {
  transferTemplateId: string;
  version: number;
  description: string;
  templateType: TransferTemplateType;
  achDefaultParameters?: AchTransferTemplateResponse;
  achTransfers?: AchTransferTemplateResponse[];
  wireDefaultParameters?: WireTransferTemplateResponse;
  wireTransfers?: WireTransferTemplateResponse[];
  lastUsedAt?: string;
  usageCount?: number;
  visibleColumns?: string[];
}

export type CreateTransferTemplateRequest = TransferTemplate;

export type CreateTransferTemplateResponse = TransferTemplateResponse;

export interface DeleteTransferTemplateRequest {
  transferTemplateId: string;
}

export interface DeleteTransferTemplateResponse {}

export interface GetTransferTemplateRequest {
  transferTemplateId: string;
}

export type GetTransferTemplateResponse = TransferTemplateResponse;

export interface ListTransferTemplateRequest extends PaginationQueryParams {}

export interface ListTransferTemplateResponse {
  transferTemplates: TransferTemplateResponse[];
  hasMore: boolean;
}

export type UpdateTransferTemplateRequest = Partial<Omit<TransferTemplate, 'templateType'>> & {
  transferTemplateId: string;
};

export type UpdateTransferTemplateResponse = TransferTemplateResponse;

export class TransferTemplateRepository {
  static async create(request: CreateTransferTemplateRequest) {
    return client.post<CreateTransferTemplateRequest, CreateTransferTemplateResponse>(
      '/dashboard/platforms/transfer-templates',
      request
    );
  }

  static async delete(request: DeleteTransferTemplateRequest) {
    return client.delete<object, DeleteTransferTemplateResponse>(
      `/dashboard/platforms/transfer-templates/${request.transferTemplateId}`,
      {}
    );
  }

  static async get(request: GetTransferTemplateRequest) {
    return client.get<object, GetTransferTemplateResponse>(
      `/dashboard/platforms/transfer-templates/${request.transferTemplateId}`,
      {}
    );
  }

  static async list(request: ListTransferTemplateRequest) {
    return client.get<ListTransferTemplateRequest, ListTransferTemplateResponse>(
      '/dashboard/platforms/transfer-templates',
      {
        query: request,
      }
    );
  }

  static async update(request: UpdateTransferTemplateRequest) {
    const { transferTemplateId, ...rest } = request;
    return client.patch<Omit<UpdateTransferTemplateRequest, 'transferTemplateId'>, UpdateTransferTemplateResponse>(
      `/dashboard/platforms/transfer-templates/${transferTemplateId}`,
      rest,
      {
        allowNumbersInRequest: true,
      }
    );
  }
}
