import { useCallback, useEffect, useRef, useState } from 'react';

import { useDebounce } from '~/hooks/useDebounce';
import { BankAccount, BankAccountRepository } from '~/repositories/BankAccountRepository';
import { EntityRepository } from '~/repositories/EntityRepository';
import { useSessionStore } from '~/stores/Session';
import { EntityEntity } from '~/typings/API';

import { Mode } from './types';

export interface EntitiesAndAccounts {
  bankAccounts: {
    data: BankAccount[];
    isLoading: boolean;
    loadMore: (entityId: string) => void;
    paginationState: BankAccountsPaginationState;
    search: (searchTerm: string) => void;
    isSearchLoading: boolean;
  };
  entities: {
    data: EntityEntity[];
    loadMore: () => void;
    paginationState: PaginationState;
    isLoading: boolean;
  };
}

interface PaginationState {
  startingAfter?: string;
  hasMore: boolean;
}

interface BankAccountsPaginationState {
  [bankAccountId: string]: PaginationState;
}

const mergeBankAccounts = (existingBankAccounts: BankAccount[], newBankAccounts: BankAccount[]) => {
  return newBankAccounts.reduce((acc, bankAccount) => {
    const existingBankAccount = existingBankAccounts.find((e) => e.id === bankAccount.id);
    if (existingBankAccount) {
      return acc;
    }
    return [...acc, bankAccount];
  }, existingBankAccounts);
};

const mergeEntities = (existingEntities: EntityEntity[], newEntities: EntityEntity[]) => {
  return newEntities.reduce((acc, entity) => {
    const existingBankAccount = existingEntities.find((e) => e.id === entity.id);
    if (existingBankAccount) {
      return acc;
    }
    return [...acc, entity];
  }, existingEntities);
};

export const useEntitiesAndAccounts = ({
  mode,
  ...inputs
}: {
  bankAccountIDs?: string[];
  entityIds?: string[];
  mode?: Mode;
}): EntitiesAndAccounts => {
  const { currentPlatform } = useSessionStore();
  const skipFetch = mode === Mode.Live && !currentPlatform?.isLiveEnabled;

  const { bankAccountIDs = [], entityIds = [] } = inputs ?? {};
  const fetchedBankAccountIDs = useRef<Set<string>>(new Set());
  const fetchedEntityIDs = useRef<Set<string>>(new Set());

  const [entitiesPaginationState, setEntitiesPaginationState] = useState<PaginationState>({ hasMore: true });
  const [bankAccountsPaginationState, setBankAccountsPaginationState] = useState<BankAccountsPaginationState>({});

  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [entities, setEntities] = useState<EntityEntity[]>([]);

  const [isBankAccountSearchLoading, setIsBankAccountSearchLoading] = useState<boolean>(false);
  const debouncedSearchBankAccounts = useDebounce(
    (searchTerm: string) => {
      BankAccountRepository.getAll({ description: searchTerm, mode })
        .then((resp) => {
          setBankAccounts((prevBankAccounts) => mergeBankAccounts(prevBankAccounts, resp.bankAccounts));
        })
        .finally(() => {
          setIsBankAccountSearchLoading(false);
        });
    },
    500,
    [mode]
  );
  const searchBankAccounts = useCallback(
    (searchTerm: string) => {
      setIsBankAccountSearchLoading(true);
      debouncedSearchBankAccounts(searchTerm);
    },
    [mode]
  );

  const [isBankAccountsLoading, setIsBankAccountsLoading] = useState<boolean>(false);
  const loadMoreBankAccounts = useCallback(
    (entityId: string) => {
      const { hasMore, startingAfter } = bankAccountsPaginationState[entityId] ?? { hasMore: true };
      if (!hasMore) {
        return;
      }
      setIsBankAccountsLoading(true);
      BankAccountRepository.getAll({ entityId, startingAfter, mode })
        .then((resp) => {
          setBankAccountsPaginationState((curPaginationState) => ({
            ...curPaginationState,
            [entityId]: {
              startingAfter:
                resp.bankAccounts && resp.bankAccounts.length > 0
                  ? resp.bankAccounts[resp.bankAccounts.length - 1].id
                  : undefined,
              hasMore: resp.hasMore,
            },
          }));
          setBankAccounts((prevBankAccounts) => mergeBankAccounts(prevBankAccounts, resp.bankAccounts));
          resp.bankAccounts.forEach((bankAccount) => fetchedBankAccountIDs.current.add(bankAccount.id));
        })
        .finally(() => {
          setIsBankAccountsLoading(false);
        });
    },
    [bankAccountsPaginationState, mode]
  );

  const [isEntitiesLoading, setIsEntitiesLoading] = useState<boolean>(false);
  const loadMoreEntities = useCallback(() => {
    const { hasMore, startingAfter } = entitiesPaginationState;
    if (!hasMore) {
      return;
    }
    setIsEntitiesLoading(true);
    EntityRepository.getAll({ startingAfter, isRoot: false, mode })
      .then((resp) => {
        setEntitiesPaginationState({
          startingAfter:
            resp.entities && resp.entities.length > 0 ? resp.entities[resp.entities.length - 1].id : undefined,
          hasMore: resp.hasMore,
        });

        setEntities((prevEntities) => mergeEntities(prevEntities, resp.entities));
        resp.entities.forEach((entity) => fetchedEntityIDs.current.add(entity.id ?? ''));
      })
      .finally(() => {
        setIsEntitiesLoading(false);
      });
  }, [entitiesPaginationState, mode]);

  const [isRootEntityLoading, setIsRootEntityLoading] = useState<boolean>(false);
  const loadRootEntity = useCallback(() => {
    setIsRootEntityLoading(true);
    EntityRepository.getAll({ isRoot: true, mode })
      .then((resp) => {
        const rootEntity = resp.entities.length > 0 ? resp.entities[0] : undefined;
        if (rootEntity) {
          setEntities((prevEntities) => mergeEntities(prevEntities, [rootEntity]));
          fetchedEntityIDs.current.add(rootEntity.id ?? '');
        }
      })
      .finally(() => {
        setIsRootEntityLoading(false);
      });
  }, [entitiesPaginationState, mode]);

  // If the list of bank account IDs changes, fetch the new bank accounts
  useEffect(() => {
    if (skipFetch) return;

    Promise.allSettled(
      bankAccountIDs
        .filter((id) => !fetchedBankAccountIDs.current.has(id))
        .map((id) => BankAccountRepository.get({ id, mode }))
    ).then((results) => {
      const newBankAccounts = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);
      setBankAccounts((prevBankAccounts) => mergeBankAccounts(prevBankAccounts, newBankAccounts));
      newBankAccounts.forEach((bankAccount) => fetchedBankAccountIDs.current.add(bankAccount.id));
    });
  }, [bankAccountIDs, skipFetch, mode]);

  // If the list of entity IDs changes, fetch the new entities
  useEffect(() => {
    if (skipFetch) return;

    Promise.allSettled(
      entityIds.filter((id) => !fetchedEntityIDs.current.has(id)).map((id) => EntityRepository.get(id, mode))
    ).then((results) => {
      const newEntities = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);
      setEntities((prevEntities) => mergeEntities(prevEntities, newEntities));
      newEntities.forEach((entity) => fetchedEntityIDs.current.add(entity.id));
    });
  }, [entityIds, mode, skipFetch]);

  // If the list of bank accounts changes, make sure we have corresponding entities
  useEffect(() => {
    if (skipFetch) return;

    bankAccounts.forEach((bankAccount) => {
      bankAccount.owners.forEach((entityId) => {
        if (!fetchedEntityIDs.current.has(entityId)) {
          EntityRepository.get(entityId, mode).then((entity) => {
            setEntities((prevEntities) => mergeEntities(prevEntities, [entity]));
            fetchedEntityIDs.current.add(entity.id);
          });
        }
      });
    });
  }, [bankAccounts, mode, skipFetch]);

  // If the list of entities changes, make sure we have corresponding bank accounts
  useEffect(() => {
    if (skipFetch) return;

    entities.forEach((entity) => {
      const entityId = entity.id;
      if (!entityId) {
        return;
      }
      if (!bankAccountsPaginationState[entityId]) {
        loadMoreBankAccounts(entityId);
      }
    });
  }, [bankAccountsPaginationState, entities, loadMoreBankAccounts, skipFetch]);

  // Load the first page of entities by default
  useEffect(() => {
    if (skipFetch) return;

    loadRootEntity();
    loadMoreEntities();
  }, [skipFetch]);

  return {
    bankAccounts: {
      data: bankAccounts,
      isLoading: isBankAccountSearchLoading || isBankAccountsLoading,
      loadMore: loadMoreBankAccounts,
      paginationState: bankAccountsPaginationState,
      search: searchBankAccounts,
      isSearchLoading: isBankAccountSearchLoading,
    },
    entities: {
      data: entities,
      isLoading: isEntitiesLoading || isRootEntityLoading,
      loadMore: loadMoreEntities,
      paginationState: entitiesPaginationState,
    },
  };
};
