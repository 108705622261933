import React from 'react';

import { ModalAlert } from './Alert';
import { ModalAuthenticate } from './Authenticate';
import { ModalCheckReturn } from './CheckReturn';
import { ModalClearDisbursement } from './ClearDisbursement';
import { ModalClearTransfer } from './ClearTransfer';
import { ModalCounterparty } from './Counterparty';
import { ModalCounterpartyInternational } from './Counterparty/International';
import { ModalCreateStatement } from './CreateStatement';
import { ModalEditCheck } from './EditCheck';
import { ModalEnableProduction } from './EnableProduction';
import { ModalErrorCheck } from './ErrorCheck';
import { ModalEventDetail } from './EventDetail';
import { ModalPasswordPrompt } from './PasswordPrompt';
import { ModalReturn } from './Return';
import { ModalReviewTransferTemplate } from './ReviewTransferTemplate';
import { ModalSandboxTransfer } from './SandboxTransfer';
import { ModalUpdateDisbursement } from './UpdateDisbursement';
import { ModalWebhookResult } from './WebhookResult';

// import { ModalCropImage } from './CropImage';

export const modals = [
  <ModalAlert key="ModalAlert" />,
  <ModalAuthenticate key="ModalAuthenticate" />,
  <ModalCounterparty key="ModalCounterparty" />,
  <ModalCounterpartyInternational key="ModalCounterpartyInternational" />,
  <ModalCreateStatement key="ModalCreateStatement" />,
  <ModalPasswordPrompt key="ModalPasswordPrompt" />,
  <ModalSandboxTransfer key="ModalSandboxTransfer" />,
  <ModalWebhookResult key="ModalWebhookResult" />,
  <ModalEventDetail key="ModalEventDetail" />,
  <ModalReturn key="ModalReturn" />,
  <ModalCheckReturn key="ModalCheckReturn" />,
  <ModalClearTransfer key="ModalClearTransfer" />,
  <ModalClearDisbursement key="ModalClearDisbursement" />,
  <ModalUpdateDisbursement key="ModalUpdateDisbursement" />,
  <ModalEnableProduction key="ModalEnableProduction" />,
  <ModalEditCheck key="ModalEditCheck" />,
  <ModalErrorCheck key="ModalErrorCheck" />,
  <ModalReviewTransferTemplate key="ModalReviewTransferTemplate" />,
  // <ModalCropImage key="ModalCropImage" />,
];
