import { datadogLogs } from '@datadog/browser-logs';
import { basicLogger, LDProvider } from 'launchdarkly-react-client-sdk';
import React, { memo, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ROUTE as ROUTE_APP } from '~/app/routes';
import {
  APP_VERSION,
  DATATDOG_BROWSER_LOGS_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY,
  HOST,
  IS_PRODUCTION,
  LAUNCH_DARKLY_CLIENT_ID,
  MODE,
} from '~/constants';
import { Router as PublicRouter } from '~/public/Router';
import { ThemeProvider } from '~/Theme/ThemeProvider';

import { App } from './app';

ReactGA.initialize('G-SSHXC1SVP5');

export const Router = memo(() => {
  const handleErrors = useCallback(({ error, message, filename, lineno, colno }: ErrorEvent) => {
    datadogLogs.logger.error('Unhandled Exception', {
      error,
      message,
      filename,
      lineno,
      colno,
    });
  }, []);

  const handleUnhandledRejection = useCallback(({ reason }: PromiseRejectionEvent) => {
    datadogLogs.logger.error('Unhandled Promise Rejection', {
      reason,
    });
  }, []);

  useEffect(() => {
    if (!IS_PRODUCTION || window.location.hostname !== HOST) {
      return;
    }

    datadogLogs.init({
      clientToken: DATATDOG_BROWSER_LOGS_KEY,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: 100,
      version: APP_VERSION,
      env: MODE === 'production' ? 'prod' : MODE,
      service: 'dashboard',
      beforeSend: (log) => {
        if (log.http && log.http.status_code !== 500) {
          return false;
        }

        return true;
      },
    });

    window.addEventListener('error', handleErrors);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleErrors);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return (
    <ThemeProvider>
      <LDProvider
        clientSideID={LAUNCH_DARKLY_CLIENT_ID}
        options={{ logger: basicLogger({ level: 'error' }) }}
        reactOptions={{ useCamelCaseFlagKeys: false }}
        timeout={5}
      >
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY} useEnterprise>
          <BrowserRouter basename={import.meta.env.BASE_URL}>
            <Routes>
              <Route path={`${ROUTE_APP.ROOT}/*`} element={<App />} />
              <Route path="*" element={<PublicRouter />} />
            </Routes>
          </BrowserRouter>
        </GoogleReCaptchaProvider>
      </LDProvider>
    </ThemeProvider>
  );
});
