import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AmountInput, CurrenciesUSD, Dropdown, Input, Radio, SegmentedControl } from '@column/column-ui-kit';

import { RestrictedBadge } from '../_components/RestrictedBadge';
import { useBankAccountsDropdown } from '../_hooks/useBankAccountsDropdown';
import { CreateTransfer } from '../Add';
import {
  DateInput,
  RadioGroup,
  RenderFields,
  TwoFieldsWrapperFirst,
  OptionGroup,
  Option,
  OptionLabel,
  OptionDescription,
} from '~/components';
import { UnderlineLink } from '~/elements';
import { useSessionStore } from '~/stores/Session';
import { DefinitionList } from '~/styles';

export const EntryClassCodeDefinitions = (
  <>
    <DefinitionList>
      <dd>
        A Standard Entry Class (SEC) code is a three-character code used to identify various properties of ACH
        transfers. Column supports all SEC codes for incoming ACH transfers. However, for outgoing ACH transfers that
        are initiated via the dashboard, only the following codes are supported:
      </dd>
      <dt>CCD</dt>
      <dd>
        Corporate Credit or Debit: Entry initiated by an Organization to transfer funds to or from an account of that
        Organization or another Organization.
      </dd>
      <dt>CIE</dt>
      <dd>
        Customer Initiated Entry: Credit Entry initiated by or on behalf of the holder of a Consumer Account to transfer
        funds to the account of the Receiver.
      </dd>
      <dt>CTX</dt>
      <dd>
        Corporate Trade Exchange: Entry initiated by an Organization to transfer funds to or from the account of that
        Organization or another Organization that permits the inclusion of payment-related remittance information in
        ANSI or UN/EDIFACT syntax.
      </dd>
      <dt>PPD</dt>
      <dd>
        Prearranged Payment and Deposit: Entry initiated by an Organization based on a standing or a Single Entry
        authorization from a Receiver to transfer funds to or from a Consumer Account of the Receiver.
      </dd>
      <dt>TEL</dt>
      <dd>
        Telephone-Initiated Entry: Debit initiated by an Originator pursuant to an oral authorization obtained over the
        telephone to transfer funds from a Consumer Account of the Receiver.
      </dd>
      <dt>WEB</dt>
      <dd>
        Internet-Initiated/Mobile Entry: (1) a debit Entry initiated by an Originator to a Consumer Account of the
        Receiver based on (a) an authorization that is communicated, other than by an oral communication, from the
        Receiver to the Originator via the Internet or a Wireless Network, or (b) any form of authorization if the
        Receiver's instruction for the initiation of the individual debit Entry is designed by the Originator to be
        communicated, other than by an oral communication, to the Originator via a Wireless Network; or (2) a credit
        Entry initiated by or on behalf of the holder of a Consumer Account that is intended for the Consumer Account of
        a Receiver, regardless of whether the authorization of such Entry is communicated via the Internet or Wireless
        Network.
      </dd>
    </DefinitionList>

    <UnderlineLink href="https://column.com/docs/ach/types#ach-standard-entry-class-codes" newTab>
      Learn more about Entry Class Codes
    </UnderlineLink>
  </>
);

const AchContent: FC<ComponentLoaderTypeProps> = ({ onLoaded }) => {
  const { watch } = useFormContext();
  const [isCounterpartiesLoaded, setIsCounterpartiesLoaded] = useState<boolean>(false);
  const [isEffectiveSameDay, setIsEffectiveSameDay] = useState<boolean>(true);
  const { isAuthorized } = useSessionStore();

  const selectedAchType: 'DEBIT' | 'CREDIT' | undefined = watch('ach.type');
  const {
    dropdownBankAccounts,
    isLoading: bankAccountsLoading,
    searchBankAccounts,
  } = useBankAccountsDropdown({
    permission: selectedAchType === 'DEBIT' ? 'achDebits' : selectedAchType === 'CREDIT' ? 'achCredits' : undefined,
    unauthorizedText: `You do not have permission to use ${
      selectedAchType === 'DEBIT' ? 'ACH debits' : selectedAchType === 'CREDIT' ? 'ACH credits' : ''
    } on this bank account.`,
  });

  useEffect(() => {
    if (!bankAccountsLoading && isCounterpartiesLoaded) {
      onLoaded();
    }
  }, [isCounterpartiesLoaded, bankAccountsLoading]);

  const isDebitAuthorized = useMemo(
    () =>
      isAuthorized({
        permission: 'achDebits',
        permissionLevel: 'write',
      }),
    [isAuthorized]
  );
  const isCreditAuthorized = useMemo(
    () =>
      isAuthorized({
        permission: 'achCredits',
        permissionLevel: 'write',
      }),
    [isAuthorized]
  );

  return (
    <>
      {RenderFields<CreateTransfer, 'ach'>({
        prefix: 'ach',
        sections: [
          {
            headline: 'Information',
            fields: [
              {
                id: 'type',
                label: 'Type',
                description: 'Select between ACH Debit or ACH Credit.',
                rules: { required: true },
                defaultValue: isDebitAuthorized ? 'DEBIT' : 'CREDIT',
                children: ({ value, onChange }) => (
                  <OptionGroup orientation="horizontal">
                    <Option
                      isActive={value === 'DEBIT'}
                      isDisabled={!isDebitAuthorized}
                      onClick={() => isDebitAuthorized && onChange('DEBIT')}
                    >
                      <Radio
                        isChecked={value === 'DEBIT'}
                        isDisabled={!isDebitAuthorized}
                        onCheckedChange={() => isDebitAuthorized && onChange('DEBIT')}
                      />
                      <OptionLabel isDisabled={!isDebitAuthorized}>Debit</OptionLabel>
                      <OptionDescription isDisabled={!isDebitAuthorized}>
                        Pull money from a counterparty to your bank account.
                      </OptionDescription>
                      {!isDebitAuthorized && (
                        <RestrictedBadge content={'You do not have permission to use ACH Debits.'} />
                      )}
                    </Option>
                    <Option
                      isActive={value === 'CREDIT'}
                      isDisabled={!isCreditAuthorized}
                      onClick={() => isCreditAuthorized && onChange('CREDIT')}
                    >
                      <Radio
                        isChecked={value === 'CREDIT'}
                        isDisabled={!isCreditAuthorized}
                        onCheckedChange={() => isCreditAuthorized && onChange('CREDIT')}
                      />
                      <OptionLabel isDisabled={!isCreditAuthorized}>Credit</OptionLabel>
                      <OptionDescription isDisabled={!isCreditAuthorized}>
                        Push money from your bank account to a counterparty.
                      </OptionDescription>
                      {!isCreditAuthorized && (
                        <RestrictedBadge content={'You do not have permission to use ACH Credits.'} />
                      )}
                    </Option>
                  </OptionGroup>
                ),
              },
              {
                id: 'bankAccountId',
                defaultValue: '',
                label: 'Bank Account',
                rules: { required: true },
                description: 'Select a bank account to originate the ACH transfer.',
                children: ({ value, onChange, onBlur }, { error }) => (
                  <Dropdown
                    options={dropdownBankAccounts}
                    active={value}
                    fullWidth
                    maxWidth="640px"
                    search
                    searchLabel="Search for description"
                    onSearchChange={(description: string) => searchBankAccounts({ description })}
                    onChange={onChange}
                    onOpenChange={(open) => !open && onBlur()}
                    hasError={!!error}
                  />
                ),
              },
              {
                id: 'counterpartyId',
                defaultValue: '',
                label: 'Counterparty',
                rules: { required: true },
                description: 'Select or create a counterparty to receive the ACH transfer.',
                isCounterparty: 'ach',
                children: () => null,
                onLoaded: () => setIsCounterpartiesLoaded(true),
              },
              {
                id: 'amount',
                label: 'Amount',
                rules: { required: true, validate: (v) => Number(v) > 0 },
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <AmountInput
                    hasError={isTouched && !!error}
                    value={Number(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    currencyList={CurrenciesUSD}
                    fixedCurrencyCode="USD"
                  />
                ),
              },
              {
                id: 'entryClassCode',
                label: 'Entry Class Code',
                defaultValue: 'CCD',
                tooltip: {
                  headline: 'Entry Class Code Definitions',
                  content: EntryClassCodeDefinitions,
                },
                rules: {
                  required: true,
                },
                children: ({ value, onChange, onBlur }, { error }) => (
                  <Dropdown
                    options={['CCD', 'CIE', 'CTX', 'PPD', 'TEL', 'WEB'].map((code: string) => ({
                      label: code,
                      value: code,
                    }))}
                    active={value}
                    fullWidth
                    onChange={onChange}
                    onOpenChange={(open) => !open && onBlur()}
                    hasError={!!error}
                  />
                ),
              },
            ],
          },
          {
            fields: [
              {
                id: 'description',
                label: 'Description',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 255,
                    message: 'Description cannot exceed 255 characters.',
                  },
                },
                tooltip:
                  'Optional field to add a description of the transfer for your internal reference and is not sent externally. Maximum 255 characters.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Description"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
              },
              {
                id: 'effectiveDate',
                label: 'Effective Date',
                defaultValue: '',
                tooltip:
                  'Optional field to select a future date for the transfer to be effective, otherwise today will be set as the effective date.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <TwoFieldsWrapperFirst>
                    <SegmentedControl
                      options={[
                        {
                          label: 'Today',
                          value: true,
                        },
                        {
                          label: 'Other',
                          value: false,
                        },
                      ]}
                      active={isEffectiveSameDay}
                      onOptionChange={setIsEffectiveSameDay}
                    />
                    <DateInput
                      disableBefore={new Date()}
                      value={String(value)}
                      onChange={onChange}
                      isDisabled={isEffectiveSameDay}
                      hasError={!isEffectiveSameDay && isTouched && !!error}
                    />
                  </TwoFieldsWrapperFirst>
                ),
              },
            ],
          },
          {
            headline: 'Company',
            fields: [
              {
                id: 'companyName',
                label: 'Name',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 16,
                    message: 'Company name cannot exceed 16 characters.',
                  },
                },
                tooltip:
                  'Optional field to add the name of the company which initiated the ACH transfer. Maximum 16 characters.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Company Name"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
              },
              {
                id: 'companyEntryDescription',
                label: 'Entry Description',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 10,
                    message: 'Entry description cannot exceed 10 characters.',
                  },
                },
                tooltip:
                  'Optional field to provide the Receiver with a description of the purpose of the transfer. Default value: "PAYMENT". Maximum 10 characters.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Company Entry Description"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
              },
              {
                id: 'companyDiscretionaryData',
                label: 'Discretionary Data',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 20,
                    message: 'Discretionary data cannot exceed 20 characters.',
                  },
                },
                tooltip:
                  'Optional field to include codes (one or more), of significance only to you, to enable specialized handling of the transfer. There is no standardized interpretation for the value of the field. Maximum 20 characters.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Company Discretionary Data"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
              },
            ],
          },
          {
            headline: 'Receiver',
            fields: [
              {
                id: 'receiverName',
                label: 'Name',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 22,
                    message: 'Receiver name cannot exceed 22 characters.',
                  },
                },
                tooltip:
                  'Optional field to add the name of the receiver, usually the name on the bank account. Maximum 22 characters.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Receiver Name"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
              },
              {
                id: 'receiverId',
                label: 'ID',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 15,
                    message: 'Receiver ID cannot exceed 15 characters.',
                  },
                },
                tooltip:
                  'Optional field to add an accounting reference number by which the Receiver is known to the Originator. It is included for further identification and for descriptive purposes. For example, it may be the number shown on an invoice, statement, billhead, notice, or other communication as the reference for the Receiver to update account receivable records. Maximum 15 characters.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Receiver ID"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
              },
            ],
          },
          {
            headline: 'Advanced',
            isCollapsed: true,
            fields: [
              {
                id: 'allowOverdraft',
                label: 'Allow Overdraft',
                defaultValue: false,
                tooltip:
                  'Selecting "Yes" allows an outgoing transfer to cause the selected account to go negative. (Only eligible on overdraftable accounts.)',
                children: ({ value, onChange }) => (
                  <RadioGroup>
                    <Radio isChecked={!Boolean(value)} onCheckedChange={() => onChange(false)} label="No" />
                    <Radio isChecked={Boolean(value)} onCheckedChange={onChange} label="Yes" />
                  </RadioGroup>
                ),
              },
              {
                id: 'paymentRelatedInfo',
                label: 'Payment Related Info',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 80,
                    message: 'Payment related info cannot exceed 80 characters.',
                  },
                },
                tooltip:
                  'Optional field to add payment instructions to the receiving depository financial institution (RDFI). Note, the visibility of this information to the end recipient can vary.',
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Payment Related Info"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
              },
              {
                id: 'idempotencyKey',
                label: 'Idempotency Key',
                defaultValue: '',
                rules: {
                  maxLength: {
                    value: 255,
                    message: 'Idempotency key cannot exceed 255 characters.',
                  },
                },
                children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                  <Input
                    placeholder="Idempotency Key"
                    hasError={isTouched && !!error}
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                ),
                tooltip: {
                  headline: 'Idempotency Keys',
                  content: (
                    <>
                      <DefinitionList>
                        <dd>
                          Column automatically generates idempotency keys for safely retrying transfers without
                          accidentally duplicating the result. Custom idempotency keys can be set by clearing the
                          default value and replacing it with your own key. This can be helpful when your API call fails
                          and you aren't sure if Column received the request. Idempotency enables you to retry a request
                          without worrying it will create a duplicate transaction.
                        </dd>
                        <dd>
                          In order to perform an idempotent request, provide any unique string up to 255 characters long
                          and is case sensitive. Column reserves the right to expire idempotency keys after 30 days.
                        </dd>
                      </DefinitionList>
                      <UnderlineLink href="https://column.com/docs/workingwithapi/idempotency" newTab>
                        Learn more about Idempotency Keys
                      </UnderlineLink>
                    </>
                  ),
                },
              },
            ],
          },
        ],
      })}
    </>
  );
};

export default AchContent;
