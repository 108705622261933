import { gsap } from 'gsap';
import { SlowMo } from 'gsap/EasePack';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import React, { forwardRef, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface LogoLoadingProps {
  className?: string;
}

gsap.registerPlugin(MorphSVGPlugin, SlowMo as any);

const Wrapper = styled.div`
  color: ${({ theme }) => theme.secondary.blendToBackground(500)};
  position: absolute;
  left: 50%;
  top: calc(50% - 48px);
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
`;

const Logo = styled.svg`
  display: block;
  width: 24px;
  height: 24px;
`;

const Tail = styled.svg`
  position: absolute;
  fill: none;
  left: -14px;
  top: -14px;
  width: 52px;
  height: 52px;
  stroke-linecap: round;
  stroke-width: 1.5;
  opacity: 0;
`;

export const LogoLoading = forwardRef<HTMLDivElement, LogoLoadingProps>((props, ref) => {
  const logoRef = useRef<SVGSVGElement>(null);
  const pathRef = useRef<SVGPathElement>(null);
  const tailRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!pathRef.current || !logoRef.current || !tailRef.current) {
      return;
    }

    const logo = logoRef.current;
    const path = pathRef.current;
    const tail = tailRef.current;

    let pathTween: gsap.core.Tween | null = null;
    let tailTween: gsap.core.Tween | null = null;

    const morph = () => {
      pathTween = gsap.to(path, {
        keyframes: [
          {
            morphSVG: {
              shape:
                'M24 12C24 15.554 16.5536 11.9013 14.0986 14.0985C11.9755 15.9987 20.0734 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z',
              shapeIndex: 14,
            },
            duration: 0.1,
          },
          {
            morphSVG:
              'M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z',
            duration: 0.1,
          },
          {
            morphSVG:
              'M24 12C24 15.554 16.5536 11.9013 14.0986 14.0985C11.9755 15.9987 20.0734 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z',
            duration: 0.1,
          },
          {
            morphSVG: {
              shape: path,
              shapeIndex: 2,
            },
            duration: 0.5,
          },
        ],
      });

      tailTween = gsap.to(tail, {
        keyframes: [
          {
            opacity: 1,
            duration: 0.15,
          },
          {
            opacity: 0,
            delay: 0.15,
            duration: 0.3,
          },
        ],
      });
    };

    const rotateTween: gsap.core.Tween = gsap.to([logo, tail], {
      rotateZ: '+=360deg',
      ease: 'elastic.out(1, 0.9)',
      duration: 1.8,
      delay: 0.1,
      repeat: -1,
      onStart: morph,
      onRepeat: morph,
    });

    return () => {
      pathTween?.kill();
      tailTween?.kill();
      rotateTween?.kill();
    };
  }, []);

  return (
    <Wrapper ref={ref} className={props.className}>
      <Logo ref={logoRef} viewBox="0 0 24 24" fill="currentColor">
        <path
          ref={pathRef}
          d="M9 9V15H12C13.5913 15 15.1174 15.6321 16.2426 16.7574C17.3679 17.8826 18 19.4087 18 21V24H6C4.4087 24 2.88258 23.3679 1.75736 22.2426C0.632141 21.1174 0 19.5913 0 18V6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0H18C19.5913 0 21.1174 0.632141 22.2426 1.75736C23.3679 2.88258 24 4.4087 24 6V9H9Z"
        />
      </Logo>
      <Tail ref={tailRef} viewBox="0 0 52 52">
        <path
          d="M8.11115 10C12.5057 5.09 18.892 2 26 2C33.108 2 39.4943 5.09 43.8888 10"
          stroke="url(#logo-trail-gradient-1)"
        />
        <path
          d="M43.8888 42C39.4942 46.91 33.1079 50 25.9999 50C18.8919 50 12.5056 46.91 8.11108 42"
          stroke="url(#logo-trail-gradient-2)"
        />
        <defs>
          <radialGradient
            id="logo-trail-gradient-1"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(44 10) rotate(-173.66) scale(36.2215 161.99)"
          >
            <stop stopColor="currentColor" />
            <stop offset="1" stopColor="currentColor" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="logo-trail-gradient-2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(7.99993 42) rotate(6.34019) scale(36.2215 161.99)"
          >
            <stop stopColor="currentColor" />
            <stop offset="1" stopColor="currentColor" stopOpacity="0" />
          </radialGradient>
        </defs>
      </Tail>
    </Wrapper>
  );
});
