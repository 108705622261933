import React, { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { DropdownCell } from '../Dropdown';
import { useCounterparties, useCounterparty, useDebounce } from '~/hooks';
import { Counterparty } from '~/repositories/CounterpartyRepository';
import { TransferTemplateResponse } from '~/repositories/Transfer/TransferTemplateRepository';
import { useNotificationStore } from '~/stores/Notification';
import { ApiError } from '~/typings/API';

interface CounterpartyIdDropdownCellProps {
  index: number;
  value?: string;
  array: 'achTransfers' | 'wireTransfers';
}

export const CounterpartyIdDropdownCell: FC<CounterpartyIdDropdownCellProps> = ({ index, value, array }) => {
  const { addDangerNotification } = useNotificationStore();
  const { createRequest: fetchCounterpartyActive, isInitialLoad: isLoadingActive } = useCounterparty();
  const { createRequest: fetchCounterparties, isInitialLoad } = useCounterparties();

  const [counterpartyActive, setCounterpartyActive] = useState<Counterparty | undefined>(undefined);
  const [counterpartyList, setCounterpartyList] = useState<Counterparty[]>([]);

  const { setValue } = useFormContext<TransferTemplateResponse>();

  const getOption = useCallback(
    (counterparty: Counterparty) =>
      counterparty && {
        label:
          counterparty?.description && counterparty?.name
            ? `${counterparty.name} (${counterparty.description})`
            : counterparty?.description || counterparty?.name || counterparty?.wire?.beneficiaryName || 'Unnamed',
        small: `Rtn# ${counterparty.routingNumber} / Acc# ${counterparty.accountNumber}`,
        value: counterparty.id,
      },
    []
  );

  const handleSearchCounterparties = useDebounce(
    async (searchValue?: string) => {
      let list: Counterparty[] = [];

      try {
        if (searchValue && searchValue.length > 0) {
          const isNumber = !isNaN(parseInt(searchValue, 10));
          const results = await Promise.all([
            isNumber ? fetchCounterparties({ accountNumber: searchValue }) : undefined,
            isNumber ? fetchCounterparties({ routingNumber: searchValue }) : undefined,
            fetchCounterparties({ description: searchValue }),
          ]);

          list = [...new Set(results.map((result) => result?.counterparties ?? []).flat())];
        } else {
          const result = await fetchCounterparties();

          list = [...new Set(result?.counterparties ?? [])];
        }
      } catch (error) {
        addDangerNotification({
          content: (error as ApiError).message,
        });
      }

      setCounterpartyList(list);
    },
    500,
    [fetchCounterparties]
  );

  useEffect(() => {
    handleSearchCounterparties();

    const currentCounterparty = counterpartyList?.find((counterparty) => counterparty.id === value);

    if (value && !currentCounterparty) {
      fetchCounterpartyActive({ id: value })
        .then((counterparty) => {
          if (!counterparty) {
            return;
          }
          setCounterpartyActive(counterparty);
        })
        .catch(() => {
          setCounterpartyActive(undefined);
        });
    } else {
      setCounterpartyActive(currentCounterparty ?? undefined);
    }
  }, [value]);

  useEffect(() => {
    if (counterpartyActive) {
      setValue(`${array}.${index}.counterpartyDescription`, counterpartyActive.description?.toString() ?? undefined);

      return;
    }

    setValue(`${array}.${index}.counterpartyDescription`, undefined);
  }, [counterpartyActive, array, index, setValue]);

  return (
    <DropdownCell
      id="counterpartyId"
      array={array}
      index={index}
      active={value}
      options={counterpartyList.map(getOption).filter((option) => option !== undefined) ?? []}
      hiddenOptions={counterpartyActive ? [getOption(counterpartyActive)] : []}
      fullWidth
      search
      searchLabel="Search..."
      onSearchChange={handleSearchCounterparties}
      isLoading={isInitialLoad && isLoadingActive}
      onChange={(newValue) => setValue(`${array}.${index}.counterpartyId`, newValue)}
    />
  );
};
