import {
  CreateCustomRoleRequestObject,
  CustomRoleBankAccountLevelOverrides,
  CustomRoleEntityLevelOverrides,
  CustomRolePermissionLevel,
  CustomRolePlatformLevelPermissions,
  UpdateCustomRoleRequestObject,
} from '~/typings/API';

import { Access, Permissions } from './types';
import { EntitiesAndAccounts } from './useEntitiesAndAccounts';

export const PlatformLevelPermissionsToLabelsMap: {
  [K in keyof CreateCustomRoleRequestObject['platformLevelPermissions']]: string;
} = {
  achCredits: 'ACH Credits',
  achDebits: 'ACH Debits',
  achReturns: 'ACH Returns',
  bankAccounts: 'Bank Accounts',
  entities: 'Entities',
  wireTransfers: 'Wire Transfers',
  depositChecks: 'Deposit Checks',
  issueChecks: 'Issue Checks',
  bookTransfers: 'Book Transfers',
  realtimeTransfers: 'Realtime Transfers',
  stopPayments: 'Stop Payments',
};
export const BankAccountLevelPermissionsToLabelsMap: {
  [K in keyof CreateCustomRoleRequestObject['bankAccountLevelOverrides'][number]]: string;
} = {
  achCredits: 'ACH Credits',
  achDebits: 'ACH Debits',
  achReturns: 'ACH Returns',
  wireTransfers: 'Wire Transfers',
  depositChecks: 'Deposit Checks',
  issueChecks: 'Issue Checks',
  bookTransfers: 'Book Transfers',
  realtimeTransfers: 'Realtime Transfers',
  stopPayments: 'Stop Payments',
  thisBankAccount: 'This Bank Account',
};

export const BankAccountDefaultPermissions: {
  [K in keyof CreateCustomRoleRequestObject['bankAccountLevelOverrides'][number]]: CustomRolePermissionLevel;
} = {
  achCredits: Access.Default,
  achDebits: Access.Default,
  achReturns: Access.Default,
  wireTransfers: Access.Default,
  depositChecks: Access.Default,
  issueChecks: Access.Default,
  bookTransfers: Access.Default,
  realtimeTransfers: Access.Default,
  stopPayments: Access.Default,
  thisBankAccount: Access.Default,
};

export const EntityLevelPermissionsToLabelsMap: {
  [K in keyof CreateCustomRoleRequestObject['entityLevelOverrides'][number]]: string;
} = {
  achCredits: 'ACH Credits',
  achDebits: 'ACH Debits',
  achReturns: 'ACH Returns',
  wireTransfers: 'Wire Transfers',
  depositChecks: 'Deposit Checks',
  issueChecks: 'Issue Checks',
  bookTransfers: 'Book Transfers',
  realtimeTransfers: 'Realtime Transfers',
  stopPayments: 'Stop Payments',
  bankAccounts: 'Bank Accounts on Entity',
  thisEntity: 'This Entity',
};

export const EntityLevelDefaultPermissions: {
  [K in keyof CreateCustomRoleRequestObject['entityLevelOverrides'][number]]: CustomRolePermissionLevel;
} = {
  achCredits: Access.Default,
  achDebits: Access.Default,
  achReturns: Access.Default,
  wireTransfers: Access.Default,
  depositChecks: Access.Default,
  issueChecks: Access.Default,
  bookTransfers: Access.Default,
  realtimeTransfers: Access.Default,
  stopPayments: Access.Default,
  bankAccounts: Access.Default,
  thisEntity: Access.Default,
};

export const buildBankAccountOverridesFromPermissions = (
  bankAccountId: string,
  permissions: Permissions
): CustomRoleBankAccountLevelOverrides =>
  Object.entries(permissions).reduce((o, [permission, value]) => ({ ...o, [permission]: value }), { bankAccountId });

export const buildBankAccountOverridesFromPermissionLevel = (
  bankAccountId: string,
  permissionLevel: CustomRolePermissionLevel
): CustomRoleBankAccountLevelOverrides =>
  Object.keys(BankAccountLevelPermissionsToLabelsMap).reduce(
    (o, permission) => ({ ...o, [permission]: permissionLevel }),
    {
      bankAccountId,
    }
  );

export const buildEntityOverridesFromPermissions = (
  entityId: string,
  permissions: Permissions
): CustomRoleEntityLevelOverrides =>
  Object.entries(permissions).reduce((o, [permission, value]) => ({ ...o, [permission]: value }), { entityId });

export const buildEntityOverridesFromPermissionLevel = (
  entityId: string,
  permissionLevel: CustomRolePermissionLevel
): CustomRoleEntityLevelOverrides =>
  Object.keys(EntityLevelPermissionsToLabelsMap).reduce((o, permission) => ({ ...o, [permission]: permissionLevel }), {
    entityId,
  });

export const getAccessLevel = (permissions: Permissions): Access => {
  if (Object.values(permissions).every((value) => value === 'default')) {
    return Access.Default;
  }
  if (Object.values(permissions).every((value) => value === 'none')) {
    return Access.NoAccess;
  }
  if (Object.values(permissions).every((value) => value === 'read')) {
    return Access.ViewOnly;
  }
  if (Object.values(permissions).every((value) => value === 'write')) {
    return Access.FullAccess;
  }
  return Access.Custom;
};

export const getBankAccountPermissions = ({
  bankAccountId,
  bankAccountLevelOverrides,
}: {
  bankAccountId: string;
  bankAccountLevelOverrides: CustomRoleBankAccountLevelOverrides[];
}): { bankAccountIndex: number; actualPermissions: Permissions; numOverrides: number } => {
  // bankAccountIndex is the index of the bank account in the bankAccountLevelOverrides array
  const bankAccountIndex = bankAccountLevelOverrides.findIndex((override) => override.bankAccountId === bankAccountId);
  const bankAccountOverride = bankAccountIndex !== -1 ? bankAccountLevelOverrides[bankAccountIndex] : undefined;

  // actualPermissions is the list of permission for the bank account, as represented by the API
  const actualPermissions = Object.keys(BankAccountLevelPermissionsToLabelsMap).reduce(
    (o, permission) => ({
      ...o,
      [permission]: bankAccountOverride?.[permission as keyof CustomRoleBankAccountLevelOverrides] ?? 'default',
    }),
    {}
  );

  // numOverrides is the number of permissions that are different from the entity level
  const numOverrides = Object.values(actualPermissions).filter((value) => value !== 'default').length;

  return { bankAccountIndex, actualPermissions, numOverrides };
};

export const getEntityPermissions = ({
  entityId,
  entityLevelOverrides,
  platformPermissions,
}: {
  entityId: string;
  entityLevelOverrides: CustomRoleEntityLevelOverrides[];
  platformPermissions: CustomRolePlatformLevelPermissions;
}): {
  entityIndex: number;
  actualPermissions: Permissions;
  effectivePermissions: Permissions;
  numOverrides: number;
} => {
  // entityIndex is the index of the entity in the entityLevelOverrides array
  const entityIndex = entityLevelOverrides.findIndex((override) => override.entityId === entityId);
  const entityOverrides = entityIndex !== -1 ? entityLevelOverrides[entityIndex] : undefined;

  // actualPermissions is the list of permission for the entity, as represented by the API
  const actualPermissions = Object.keys(EntityLevelPermissionsToLabelsMap).reduce(
    (o, permission) => ({
      ...o,
      [permission]: entityOverrides?.[permission as keyof CustomRoleEntityLevelOverrides] ?? 'default',
    }),
    {}
  );

  // effectivePermissions is the full computed list of permission for the entity, taking into account parent permissions
  const effectivePermissions = Object.keys(EntityLevelPermissionsToLabelsMap).reduce(
    (o, permission) => ({
      ...o,
      [permission]:
        entityOverrides?.[permission as keyof CustomRoleEntityLevelOverrides] &&
        entityOverrides[permission as keyof CustomRoleEntityLevelOverrides] !== 'default'
          ? entityOverrides[permission as keyof CustomRoleEntityLevelOverrides]
          : platformPermissions[permission as keyof CustomRolePlatformLevelPermissions],
    }),
    {}
  );

  // numOverrides is the number of permissions that are different from the platform level
  const numOverrides = Object.values(actualPermissions).filter((value) => value !== 'default').length;

  return { entityIndex, actualPermissions, effectivePermissions, numOverrides };
};

export const setBankAccountPermissionsFromPermissions = ({
  permissions,
  keyPrefix,
  setter,
}: {
  permissions: Permissions;
  keyPrefix: string;
  setter: (key: string, value: string) => void;
}) => {
  Object.entries(permissions).forEach(([permission, value]) => {
    setter(`${keyPrefix}${permission}`, value);
  });
};

export const setBankAccountPermissionsFromPermissionLevel = ({
  permissionLevel,
  keyPrefix,
  setter,
}: {
  permissionLevel: CustomRolePermissionLevel;
  keyPrefix: string;
  setter: (key: string, value: string) => void;
}) => {
  Object.keys(BankAccountLevelPermissionsToLabelsMap).forEach((permission) => {
    setter(`${keyPrefix}${permission}`, permissionLevel);
  });
};

export const setEntityPermissionsFromPermissions = ({
  permissions,
  keyPrefix,
  setter,
}: {
  permissions: Permissions;
  keyPrefix: string;
  setter: (key: string, value: string) => void;
}) => {
  Object.entries(permissions).forEach(([permission, value]) => {
    setter(`${keyPrefix}${permission}`, value);
  });
};

export const setEntityPermissionsFromPermissionLevel = ({
  permissionLevel,
  keyPrefix,
  setter,
}: {
  permissionLevel: CustomRolePermissionLevel;
  keyPrefix: string;
  setter: (key: string, value: string) => void;
}) => {
  Object.keys(EntityLevelPermissionsToLabelsMap).forEach((permission) => {
    setter(`${keyPrefix}${permission}`, permissionLevel);
  });
};

// buildCreateRequestForEntitiesAndAccountsSubset gets a subset of the request which contains only sandbox
// entities and accounts
export const buildCreateRequestForEntitiesAndAccountsSubset = (
  data: CreateCustomRoleRequestObject,
  entitiesAndAccountsSubset: EntitiesAndAccounts
): CreateCustomRoleRequestObject => {
  return {
    ...data,
    entityLevelOverrides: data.entityLevelOverrides?.filter(
      (override) =>
        entitiesAndAccountsSubset.entities.data.findIndex((entity) => entity.id === override.entityId) !== -1
    ),
    bankAccountLevelOverrides: data.bankAccountLevelOverrides?.filter(
      (override) =>
        entitiesAndAccountsSubset.bankAccounts.data.findIndex(
          (bankAccount) => bankAccount.id === override.bankAccountId
        ) !== -1
    ),
  };
};

// buildUpdateRequestForEntitiesAndAccountsSubset gets a subset of the request which contains only sandbox
// entities and accounts
export const buildUpdateRequestForEntitiesAndAccountsSubset = (
  data: UpdateCustomRoleRequestObject,
  entitiesAndAccountsSubset: EntitiesAndAccounts
): UpdateCustomRoleRequestObject => {
  return {
    ...data,
    entityLevelOverrides: data.entityLevelOverrides?.filter(
      (override) =>
        entitiesAndAccountsSubset.entities.data.findIndex((entity) => entity.id === override.entityId) !== -1
    ),
    bankAccountLevelOverrides: data.bankAccountLevelOverrides?.filter(
      (override) =>
        entitiesAndAccountsSubset.bankAccounts.data.findIndex(
          (bankAccount) => bankAccount.id === override.bankAccountId
        ) !== -1
    ),
  };
};
