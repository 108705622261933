import * as React from 'react';
import styled from 'styled-components';

import { Icon, Tooltip } from '@column/column-ui-kit';

interface Props {
  content: string;
  offsetY?: number;
}

const RestrictedTooltip = styled(Tooltip)`
  position: absolute;
  display: flex;
  top: 8px;
  right: 8px;
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.danger.background};
  background-color: ${({ theme }) => theme.danger.blendToBackground(800, 200)};
`;

const RestrictedIcon = styled(Icon.AnimationLock)`
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 0 -3px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const RestrictedBadge: React.FC<Props> = ({ content }) => (
  <RestrictedTooltip content={content}>
    <RestrictedIcon />
  </RestrictedTooltip>
);
