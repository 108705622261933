import { gsap } from 'gsap';
import isEqual from 'lodash.isequal';
import React, {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

import { Chip, Dropdown, Fade, formatNumber, Icon, Tooltip } from '@column/column-ui-kit';

import { FilterDropdown, ResetFilter } from '~/app/pages/Transfers/Overview';
import { ButtonGroupDropdown } from '~/components/ButtonGroupDropdown';
import { LogoLoading, RelativeTime } from '~/elements';
import { useBankAccounts, useQueryParams } from '~/hooks';
import { ReportsFilterType, useReporting } from '~/hooks/useReporting';
import {
  DocumentExtensionType,
  DocumentRepository,
  DocumentTypeMap,
  Report,
  ReportingListParams,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { TableBottomFade } from '~/styles';
import { ReportingSettlementReport } from '~/typings/API';
import { downloadFile, formatString } from '~/util';
import { MonthBoundariesType } from '~/util/dateFilterHelpers';
import { getFilenameForStatementReport } from '~/util/reporting';

import { DownloadButton } from './DownloadButton';
import { Pagination } from './Pagination';
import { PopoverFilterPeriod, PopoverFilterWrapper } from './Popover';
import { Table, TableColumn } from './Table';

export interface ReportingTableProps {
  tableId: string;
  bankAccountId?: string;
  className?: string;
  empty?: ReactNode;
  action?: ReactNode;
  onRowClick?: (entry: ReportingSettlementReport) => void;
  filter?: ReportsFilterType;
  visibleFiles?: DocumentExtensionType[];
  hiddenFiles?: DocumentExtensionType[];
  overwriteEntryObject?: (entry: ReportingSettlementReport | Report, object: object) => object;
}

export interface ReportingTableRefProps {
  fetch: (params?: ReportsFilterType) => void;
  reset: () => void;
  setQueryParams: (object?: ReportsFilterType) => void;
}

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  will-change: height;
  transform: translateZ(0);
`;

const StyledTable = styled(Table)`
  tr {
    align-items: center;
  }
`;

const DateRange = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 0;
`;

const Arrow = styled(Icon.ArrowRight)`
  display: inline-block;
  vertical-align: middle;

  --icon-size: 16px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const Entries = styled(Chip)`
  --chip-pale: ${({ theme }) => theme.secondary.blendToBackground(150)};

  svg {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 -4px;
  }
`;

const FilterWrapper = styled.div`
  margin-right: auto;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ActionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & > *:only-child {
    justify-self: start;
  }
`;

export const ReportingTable = forwardRef<ReportingTableRefProps, PropsWithChildren<ReportingTableProps>>(
  (
    {
      bankAccountId,
      filter,
      action,
      overwriteEntryObject,
      visibleFiles = ['csv', 'parquet'],
      hiddenFiles = [],
      ...props
    },
    ref
  ) => {
    const { updateSettings } = useSessionStore();
    const { response: bankAccounts, setQueryParams: searchBankAccounts } = useBankAccounts();
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);

    const defaultFilters = useMemo(() => {
      const filters: ReportsFilterType = { ...filter };

      return filters;
    }, [filter]);

    const { response, queryParams, isLoading, isInitialLoad, setQueryParams } = useReporting({
      onInitialLoad: () => setIsMounted(true),
      initialParams: defaultFilters,
      persistParams: bankAccountId
        ? {
            statementSubjectId: bankAccountId,
          }
        : undefined,
    });

    const { queryParams: urlQueryParams } = useQueryParams<ReportingListParams>();

    const wrapperRef = useRef<HTMLDivElement>(null);
    const fadeRef = useRef<HTMLDivElement>(null);

    const [entriesOpen, setEntriesOpen] = useState<boolean>(false);

    const [filterPeriodData, setFilterPeriodData] = useState<Partial<MonthBoundariesType>>({});
    const isFilterPeriodActive = useMemo(() => {
      if (filterPeriodData?.fromDate || filterPeriodData?.toDate) {
        return true;
      }
      return false;
    }, [filterPeriodData]);

    const [bankAccountIdFilterData, setBankAccountIdFilterData] = useState<string | undefined>(bankAccountId);

    const isFilterActive = useMemo(
      () => (!bankAccountId ? isFilterPeriodActive || bankAccountIdFilterData : isFilterPeriodActive),
      [isFilterPeriodActive, bankAccountIdFilterData, bankAccountId]
    );

    const handleFilterReset = useCallback(() => {
      setFilterPeriodData({});
      setBankAccountIdFilterData(undefined);

      setQueryParams(
        {
          limit: queryParams?.limit,
          page: 1,
          ...defaultFilters,
        },
        true
      );
    }, [queryParams, defaultFilters]);

    useLayoutEffect(() => {
      if (!urlQueryParams) {
        return;
      }

      const filterPeriodDataCompare: Partial<MonthBoundariesType> = {
        toDate: urlQueryParams?.toDate,
        fromDate: urlQueryParams?.fromDate,
      };

      if (!isEqual(filterPeriodData, filterPeriodDataCompare)) {
        setFilterPeriodData(filterPeriodDataCompare);
      }

      if (urlQueryParams?.category === 'statement' && !bankAccountId) {
        setBankAccountIdFilterData(urlQueryParams.statementSubjectId);
      }
    }, []);

    useEffect(() => {
      const newQueryParams: ReportsFilterType = {
        ...queryParams,
        ...filterPeriodData,
      };

      if (!bankAccountId) {
        newQueryParams.statementSubjectId = bankAccountIdFilterData;
      }

      const queryParamsCompare = { ...queryParams };

      if (!isInitialLoad && !isEqual(newQueryParams, queryParamsCompare)) {
        setQueryParams({
          ...newQueryParams,
          startingAfter: undefined,
          endingBefore: undefined,
          page: 1,
        });

        return;
      }

      setQueryParams({ ...newQueryParams, ...defaultFilters });
    }, [filterPeriodData, bankAccountIdFilterData, defaultFilters, bankAccountId]);

    const columns: TableColumn[] = [
      {
        Header: 'Information',
        accessor: 'information',
        width: 'minmax(200px, auto)',
      },
      {
        Header: 'Type',
        Cell: (current) => <Chip type="default">{formatString(current.value)}</Chip>,
        accessor: 'type',
        width: 'minmax(min-content, 100px)',
      },
      {
        Header: 'Status',
        width: 'min-content',
        Cell: (current) => (
          <Chip type={current.value === 'completed' ? 'success' : current.value === 'failed' ? 'danger' : 'default'}>
            {formatString(current.value)}
          </Chip>
        ),
        accessor: 'status',
      },
      {
        Header: 'Created',
        accessor: 'created',
        sortType: 'datetime',
        width: 'min-content',
        Cell: (current) => <RelativeTime timestamp={current.value} />,
      },
      {
        Header: '',
        width: 'min-content',
        accessor: 'action',
      },
    ];

    const handlePrev = useCallback(() => {
      setQueryParams({
        startingAfter: undefined,
        endingBefore: Number(queryParams?.page) - 1 === 1 ? undefined : response?.reports?.at(0)?.id,
        page: Number(queryParams?.page ?? 1) - 1,
        ...defaultFilters,
      });
    }, [queryParams, response?.reports, defaultFilters]);

    const handleNext = useCallback(() => {
      setQueryParams({
        startingAfter: response?.reports && Array.isArray(response?.reports) ? response.reports?.at(-1)?.id : undefined,
        endingBefore: undefined,
        page: Number(queryParams?.page ?? 1) + 1,
        ...defaultFilters,
      });
    }, [queryParams, response?.reports, defaultFilters]);

    useImperativeHandle(
      ref,
      () => ({
        setQueryParams,
        reset: () => {
          setQueryParams(
            {
              limit: queryParams?.limit,
              startingAfter: undefined,
              endingBefore: undefined,
              page: 1,
              ...defaultFilters,
            },
            true
          );
        },
        fetch: (params?: ReportsFilterType) => {
          setQueryParams({
            ...queryParams,
            ...params,
            ...defaultFilters,
          });
        },
      }),
      [queryParams, defaultFilters]
    );

    useEffect(
      () =>
        useSessionStore.subscribe(
          (state) =>
            !state.settings.tableSettings || !state.settings.tableSettings[props.tableId]
              ? 10
              : state.settings.tableSettings[props.tableId].limit,
          (limit) => {
            if (limit !== queryParams?.limit) {
              setQueryParams({
                limit,
                ...defaultFilters,
              });
            }
          },
          {
            fireImmediately: true,
          }
        ),
      [queryParams]
    );

    useEffect(
      () =>
        useSessionStore.subscribe(
          (state) => [state.isSandbox, state.currentUser?.defaultPlatformId],
          () => {
            if (isMounted) {
              return;
            }

            handleFilterReset();
          },
          {
            equalityFn: shallow,
          }
        ),
      [isMounted]
    );

    const handleOnExit = () => {
      gsap.set(wrapperRef.current, {
        height: fadeRef.current?.offsetHeight,
      });
    };

    const handleOnEnter = () => {
      gsap.to(wrapperRef.current, {
        height: fadeRef.current?.offsetHeight,
        duration: 0.4,
        clearProps: 'height',
      });
    };

    const handleDownload = useCallback(
      (fileName: string, docId?: string) => {
        if (!docId) {
          return;
        }

        DocumentRepository.get(docId, { contentDisposition: `attachment; filename="${fileName}"` })
          .then((file) => downloadFile(file.url, fileName))
          .catch((error) =>
            addDangerNotification({
              content: error.message,
            })
          );
      },
      [addDangerNotification]
    );

    const entryObject = useCallback(
      (entries?: ReportingSettlementReport[]) => {
        if (!entries) {
          return [];
        }

        return entries.map((entry: Report) => {
          const object = {
            id: entry.id,
            information: (
              <DateRange>
                {entry.fromDate} <Arrow /> {entry.toDate}
              </DateRange>
            ),
            type: entry.type,
            status: entry.status,
            created: entry.createdAt,
            action: (
              <ButtonGroupDropdown
                type="dots"
                buttons={[...DocumentTypeMap]
                  .filter(
                    ([ext]) =>
                      visibleFiles.includes(ext as DocumentExtensionType) &&
                      !hiddenFiles.includes(ext as DocumentExtensionType)
                  )
                  .map(([ext, { label, fieldId }]) => (
                    <Tooltip
                      key={ext}
                      content={`No .${ext} file found`}
                      isOpen={entry[fieldId as keyof Report] ? false : undefined}
                    >
                      <DownloadButton
                        label={label}
                        fileName={getFilenameForStatementReport(entry, ext)}
                        id={String(entry[fieldId as keyof Report])}
                        isDisabled={!entry[fieldId as keyof Report]}
                      />
                    </Tooltip>
                  ))}
                options={[...DocumentTypeMap]
                  .filter(
                    ([ext]) =>
                      !visibleFiles.includes(ext as DocumentExtensionType) &&
                      !hiddenFiles.includes(ext as DocumentExtensionType)
                  )
                  .map(([ext, { label, fieldId }]) => ({
                    icon: !entry[fieldId as keyof Report] ? <Icon.CircleCross /> : <Icon.AnimationDownload />,
                    label,
                    isDisabled: !entry[fieldId as keyof Report],
                    tooltip: !entry[fieldId as keyof Report] ? { content: `No .${ext} file found` } : undefined,
                    onClick: () =>
                      handleDownload(getFilenameForStatementReport(entry, ext), String(entry[fieldId as keyof Report])),
                  }))}
              />
            ),
          };

          return typeof overwriteEntryObject !== 'undefined' ? overwriteEntryObject(entry, object) : object;
        });
      },
      [overwriteEntryObject, visibleFiles, hiddenFiles, handleDownload]
    );

    return (
      <Container>
        <Fade show={isInitialLoad} base={StyledLoading} />
        <Wrapper ref={wrapperRef}>
          <Fade show={!isInitialLoad} ref={fadeRef} onExit={handleOnExit} onEnter={handleOnEnter}>
            {props.empty && !isInitialLoad && (response?.reports || []).length < 1 && !isMounted ? (
              props.empty
            ) : (
              <>
                {props.children}
                <StyledTable
                  className={props.className}
                  action={
                    <ActionWrapper>
                      <FilterWrapper>
                        {!bankAccountId && defaultFilters?.category === 'statement' && (
                          <FilterDropdown
                            options={
                              bankAccounts?.bankAccounts.map((bankAccount) => ({
                                label: `${bankAccount.displayName || bankAccount.description}`,
                                suffix: `${formatNumber(bankAccount.balances.availableAmount)}`,
                                small: bankAccount.id,
                                value: bankAccount.id,
                              })) ?? []
                            }
                            size="small"
                            active={bankAccountIdFilterData}
                            $isFilterActive={
                              bankAccounts?.bankAccounts.findIndex((b) => b.id === bankAccountIdFilterData) !== -1
                            }
                            label={
                              bankAccounts?.bankAccounts.find((b) => b.id === bankAccountIdFilterData)?.displayName ||
                              bankAccounts?.bankAccounts.find((b) => b.id === bankAccountIdFilterData)?.description ||
                              'Account'
                            }
                            search
                            searchLabel="Search for description"
                            onSearchChange={(description: string) => searchBankAccounts({ description })}
                            onChange={setBankAccountIdFilterData}
                            maxWidth="480px"
                          />
                        )}

                        <PopoverFilterWrapper
                          label="Date"
                          onSubmit={setFilterPeriodData}
                          isActive={isFilterPeriodActive}
                        >
                          <PopoverFilterPeriod data={filterPeriodData ?? {}} />
                        </PopoverFilterWrapper>

                        {isFilterActive && (
                          <ResetFilter
                            size="small"
                            variant="subtle-primary"
                            icon={<Icon.Reset />}
                            iconRight
                            onClick={handleFilterReset}
                          >
                            Reset filters
                          </ResetFilter>
                        )}
                      </FilterWrapper>
                      {action}
                    </ActionWrapper>
                  }
                  columns={columns}
                  data={entryObject(response?.reports) ?? []}
                  isLoading={isLoading && !isInitialLoad}
                  onRowClick={(row) => props.onRowClick && props.onRowClick(row.original)}
                />
              </>
            )}
          </Fade>
        </Wrapper>
        <TableBottomFade show={!isInitialLoad && (response?.reports || []).length >= 1} timeoutEnter={400}>
          <Dropdown
            options={[5, 10, 15, 20, 25, 50].map((entries: number) => ({
              label: entries.toString(),
              value: entries,
            }))}
            size="small"
            variant="muted"
            active={queryParams?.limit ?? 10}
            customLabel={
              <Entries counter={queryParams?.limit ?? 10}>
                {entriesOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
              </Entries>
            }
            onOpenChange={setEntriesOpen}
            onChange={(value: number) => {
              updateSettings({
                tableSettings: {
                  [props.tableId]: {
                    limit: value,
                  },
                },
              }).then(() => {
                setQueryParams(
                  {
                    startingAfter: undefined,
                    endingBefore: undefined,
                    limit: value,
                    page: 1,
                    ...defaultFilters,
                  },
                  true
                );
              });
            }}
          />
          <Pagination
            disablePrev={Number(queryParams?.page ?? 1) === 1}
            disableNext={!response?.hasMore}
            current={Number(queryParams?.page ?? 1)}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        </TableBottomFade>
      </Container>
    );
  }
);
