import React, { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatNumber } from '@column/column-ui-kit';

import { DropdownCell } from '../Dropdown';
import { useBankAccount, useBankAccounts, useDebounce } from '~/hooks';
import { BankAccount } from '~/repositories/BankAccountRepository';
import { TransferTemplateResponse } from '~/repositories/Transfer/TransferTemplateRepository';

interface BankAccountIdDropdownCellProps {
  index: number;
  value?: string;
  array: 'achTransfers' | 'wireTransfers';
}

export const BankAccountIdDropdownCell: FC<BankAccountIdDropdownCellProps> = ({ index, value, array }) => {
  const { createRequest: fetchBankAccountActive, isInitialLoad: isLoadingActive } = useBankAccount();
  const { response: bankAccounts, setQueryParams: searchBankAccounts, isInitialLoad } = useBankAccounts();

  const [bankAccountActive, setBankAccountActive] = useState<BankAccount | undefined>(undefined);

  const { setValue } = useFormContext<TransferTemplateResponse>();

  useEffect(() => {
    const currentBankAccount = bankAccounts?.bankAccounts?.find((bankAccount) => bankAccount.id === value);

    if (value && !currentBankAccount) {
      fetchBankAccountActive({ id: value })
        .then((bankAccount) => {
          if (!bankAccount) {
            return;
          }
          setBankAccountActive(bankAccount);
        })
        .catch(() => {
          setBankAccountActive(undefined);
        });
    } else {
      setBankAccountActive(currentBankAccount ?? undefined);
    }
  }, [value]);

  useEffect(() => {
    if (bankAccountActive) {
      setValue(`${array}.${index}.bankAccountDescription`, bankAccountActive.description?.toString() ?? undefined);

      return;
    }

    setValue(`${array}.${index}.bankAccountDescription`, undefined);
  }, [bankAccountActive, array, index, setValue]);

  const handleSearchBankAccounts = useDebounce(
    async (searchValue?: string) => {
      searchBankAccounts({ description: searchValue ?? '' });
    },
    500,
    [searchBankAccounts]
  );

  const getOption = useCallback(
    (bankAccount: BankAccount) =>
      bankAccount && {
        label: `${
          bankAccount?.displayName && bankAccount?.description
            ? `${bankAccount.displayName} – ${bankAccount.description}`
            : bankAccount?.description || 'Unnamed'
        } (${formatNumber(bankAccount?.balances?.availableAmount)})`,
        small: bankAccount.id,
        value: bankAccount.id,
      },
    []
  );

  return (
    <DropdownCell
      id="bankAccountId"
      array={array}
      index={index}
      active={value}
      options={bankAccounts?.bankAccounts?.map(getOption).filter((option) => option !== undefined) ?? []}
      hiddenOptions={bankAccountActive ? [getOption(bankAccountActive)] : []}
      fullWidth
      search
      searchLabel="Search for description"
      onSearchChange={handleSearchBankAccounts}
      isLoading={isInitialLoad && isLoadingActive}
      onChange={(newValue: string) => setValue(`${array}.${index}.bankAccountId`, newValue)}
    />
  );
};
